import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Center,
  Image,
  Heading,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

function Success(props) {
  const { headerMessage, setSuccess, success, barcodeCount, setBarcodes } = props;
  const { isOpen, onClose } = useDisclosure({ isOpen: success });
  const cancelRef = useRef();
  let customClose = () => {
    setSuccess(false);
    setBarcodes([]);
    onClose();
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={customClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent h="400px" w="500px">
          <AlertDialogCloseButton />
          <AlertDialogBody mt={16}>
            <Heading textAlign="center" size="lg" fontWeight="semibold">
              {headerMessage}
            </Heading>
            <Center>
              <Image src={`${window.location.origin}/check.gif`} w="200px" />
            </Center>
            <Heading textAlign="center" size="lg" fontWeight="semibold">
              Total: {barcodeCount} items
            </Heading>
          </AlertDialogBody>
        </AlertDialogContent>
        <CloseButton position="absolute" right="8px" top="8px" />
      </AlertDialog>
    </>
  );
}

export default Success;
