import { theme } from "@chakra-ui/react";
import { extendTheme, withDefaultSize } from "@chakra-ui/react";
import { makeStyles } from "@material-ui/core/styles";

const customTheme = extendTheme({
  fontSizes: {
    xs: "0.65rem",
    sm: "0.75rem",
    md: "0.85rem",
    lg: "0.9rem",
    xl: "1.1rem",
    "2xl": "1.5rem",
    "3xl": "1.8rem",
    "4xl": "2.1rem",
    "5xl": "2.3rem",
    "6xl": "2.7rem",
    "7xl": "3rem",
    "8xl": "4rem",
    "9xl": "5rem",
  },
  colors: {
    blue: {
      400:"#56CCF2",
    },
    teal: {
      500: "#4BA2F3",
    },
    red: {
      400: "#EB5757",
      200: "#FAD5D5",
      100: "#EC8F8F"
    },
    green: {
      400: "#33BB51",
      500: "#6FCF97"
    },
    gray: {
      200: "#E5E5E5",
      800: "#414141",
    },
    orange: {
      400: "#FFEBCC",
      600: "#F2994A",
      700: "#FFAB2D",
    },
    purple: {
      500: "#6e26c7"
    }
  },
  fonts: {
    heading: 'Cairo',
    body: 'Open Sans'
  }
});

export const paginationStyle = makeStyles((theme) => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    "& .MuiPaginationItem-icon": {
      color: "#6e26c7",
    },
    "& .Mui-selected": {
      color: "white",
      backgroundColor: "#6e26c7",
    },
  },
}));

export default customTheme;
