import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Spacer, Heading } from '@chakra-ui/react';
import LoadContact from './LoadContact';
import AddressContextStore from '../Store/AddressContextStore';
import UserStore from '../Store/UserStore';
import ConsignmentAddressForm from '../Form/ConsignmentAddressForm';
import ConsignmentDetailForm from '../Form/ConsignmentDetailForms';
import Cookies from 'universal-cookie/es6';

function CreateConsignmentByPronto(props) {
  const { fetchData } = props;
  const [prontoRefNo, setProntoRefNo] = useState();
  const [senderAddressSource, setSenderAddressSource] = useState('manual');
  const [receiverAddressSource, setReceiverAddressSource] = useState('manual');
  const [senderSelectedOutlet, setSenderSelectedOutlet] = useState('');
  const [senderSelectedDepartment, setSenderSelectedDepartment] = useState('');
  const [receiverSelectedOutlet, setReceiverSelectedOutlet] = useState('');
  const [receiverSelectedDepartment, setReceiverSelectedDepartment] =
    useState('');
  const [receiverContact, setReceiverContact] = useState({
    name: '',
    contact: '',
  });
  const [isLoadingOutlet, setIsLoadingOutlet] = useState(false);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);

  const { initialiseAddress, savePronto } = AddressContextStore.useStoreActions(
    (actions) => actions
  );

  const userStore = UserStore.useStoreState((state) => state);

  const [senderContact, setSenderContact] = useState({
    name: null,
    contact: userStore.building.phone,
  });

  const cookies = new Cookies();
  const pronto = cookies.get('pronto');

  const loadProntoInfo = () => {
    savePronto(pronto);
    initialiseAddress({ ...userStore, ...pronto });
    setSenderSelectedOutlet(userStore.building.code);
    setSenderSelectedDepartment(userStore.department.code);
    setProntoRefNo(pronto.prontoRef);
    setReceiverSelectedOutlet(pronto.receiverAddressCode);
    setReceiverSelectedDepartment(pronto.receiverDepartmentCode);
    setReceiverContact({ ...receiverContact, name: pronto.receiverName });
    cookies.remove('pronto');
  };

  useEffect(() => {
    loadProntoInfo();
  }, []);

  return (
    <>
      <Box
        ml={props.isMenuClosed ? '20px' : '270px'}
        py={6}
        px={10}
        flex="1"
        textAlign="left"
        minH="816px"
      >
        <Flex>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb={5}>
              Consignment
            </Text>
            <Text color={'#C7C7C7'}>
              Create consignment with Pronto Ref No.
            </Text>
          </Box>
          <Spacer />
        </Flex>
        <Flex p={5}>
          <Box>
            <Text fontSize={19}>
              <strong>PRONTO REFERENCE NO.</strong>{' '}
            </Text>
            <Text fontSize={19}>{prontoRefNo}</Text>
          </Box>
        </Flex>
        <Flex gridGap={10}>
          <Box width={'60%'}>
            <Heading mt="24px" pl={5} pb={3} size="md">
              SENDER DETAILS
            </Heading>
            <LoadContact
              selectedDepartment={senderSelectedDepartment}
              setSelectedDepartment={setSenderSelectedDepartment}
              setSelectedOutlet={setSenderSelectedOutlet}
              selectedOutlet={senderSelectedOutlet}
              setAddressSource={setSenderAddressSource}
              isPronto={true}
              loadId={1}
              isLoadingOutlet={isLoadingOutlet}
              setIsLoadingOutlet={setIsLoadingOutlet}
              isLoadingDepartment={isLoadingDepartment}
              setIsLoadingDepartment={setIsLoadingDepartment}
            />
            <Box p={5}>
              <ConsignmentAddressForm
                setSelectedDepartment={setSenderSelectedDepartment}
                setSelectedOutlet={setSenderSelectedOutlet}
                addressSource={senderAddressSource}
                setAddressSource={setSenderAddressSource}
                setSenderContact={setSenderContact}
                senderContact={senderContact}
                isPronto={true}
                loadId={1}
                isLoadingOutlet={isLoadingOutlet}
                isLoadingDepartment={isLoadingDepartment}
              />
            </Box>
          </Box>

          <Box width={'60%'}>
            <Heading mt="24px" pl={5} pb={3} size="md">
              RECEIVER DETAILS
            </Heading>{' '}
            <LoadContact
              selectedDepartment={receiverSelectedDepartment}
              setSelectedDepartment={setReceiverSelectedDepartment}
              setSelectedOutlet={setReceiverSelectedOutlet}
              selectedOutlet={receiverSelectedOutlet}
              setAddressSource={setReceiverAddressSource}
              isPronto={true}
              loadId={2}
              isLoadingOutlet={isLoadingOutlet}
              setIsLoadingOutlet={setIsLoadingOutlet}
              isLoadingDepartment={isLoadingDepartment}
              setIsLoadingDepartment={setIsLoadingDepartment}
            />
            <Box p={5}>
              <ConsignmentAddressForm
                setSelectedDepartment={setReceiverSelectedDepartment}
                setSelectedOutlet={setReceiverSelectedOutlet}
                addressSource={receiverAddressSource}
                setAddressSource={setReceiverAddressSource}
                setReceiverContact={setReceiverContact}
                receiverContact={receiverContact}
                isPronto={true}
                loadId={2}
                isLoadingOutlet={isLoadingOutlet}
                isLoadingDepartment={isLoadingDepartment}
              />
            </Box>
          </Box>
        </Flex>
        <Flex>
          <Box width={'100%'} pl={5}>
            <Heading mt="24px" pb={5} size="md">
              CONSIGNMENT DETAILS
            </Heading>
            <ConsignmentDetailForm
              performedBy={userStore.name}
              fetchData={fetchData}
              isPronto={true}
              prontoDetails={pronto}
              receiverContact={receiverContact}
              senderContact={senderContact}
              isLoadingOutlet={isLoadingOutlet}
              isLoadingDepartment={isLoadingDepartment}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default CreateConsignmentByPronto;
