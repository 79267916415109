import {
  Button,
  Text,
  Flex,
  Box,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { Formik, Form } from 'formik';
import AddressContextStore from '../Store/AddressContextStore';
import { BiSave } from 'react-icons/bi';
import axios from 'axios';
import DetailForm from './DetailForm';
import { useHistory } from 'react-router-dom';

function ConsignmentDetailForm(props) {
  const {
    isPronto,
    loadId,
    receiverContact,
    senderContact,
    prontoDetails,
    isLoadingOutlet,
    isLoadingDepartment,
  } = props;
  const toast = useToast();
  const history = useHistory();

  const { sender, receiver, details } = AddressContextStore.useStoreState(
    (state) => state
  );

  const { resetState } = AddressContextStore.useStoreActions(
    (actions) => actions
  );

  // let validateLength = (value) => {
  //   let trimmedValue = value.trim();
  //   if (trimmedValue.length > 0 && trimmedValue.length > 8) {
  //     return "Only 8 characters allowed";
  //   }
  // };

  return (
    <>
      <Formik
        initialValues={{
          departmentRef: isPronto
            ? prontoDetails?.departmentRef
            : details?.departmentRef,
          prontoRef: isPronto ? prontoDetails?.prontoRef : details?.prontoRef,
          quantity: isPronto ? prontoDetails?.quantity : details?.quantity,
          notes: isPronto ? prontoDetails?.notes : details?.notes,
        }}
        onSubmit={(values, { setSubmitting }) => {
          const payload = {
            SenderName:
              isPronto && senderContact?.name
                ? senderContact?.name
                : sender.name,
            SenderContact: isPronto
              ? String(senderContact.contact)
              : String(sender.contact) || String(sender.buildingPhone),
            SenderAddressCode: sender.addressCode,
            SenderDepartmentCode: sender.departmentCode,
            SenderDepartmentOrOutlet: sender.departmentOrOutlet,
            SenderStreet: sender.address,
            SenderCity: sender.city,
            SenderState: sender.state,
            SenderPostcode: sender.postcode,
            SenderCountry: sender.country,
            SenderAddressSource: sender.addressSource,
            ReceiverName:
              isPronto && receiverContact.name
                ? receiverContact.name
                : receiver.name,
            ReceiverContact: isPronto
              ? String(receiverContact.contact) ||
                String(receiver.buildingPhone)
              : String(receiver.contact),
            ReceiverAddressCode: receiver.addressCode,
            ReceiverDepartmentCode: receiver.departmentCode,
            ReceiverDepartmentOrOutlet: receiver.departmentOrOutlet,
            ReceiverStreet: receiver.address,
            ReceiverCity: receiver.city,
            ReceiverState: receiver.state,
            ReceiverPostcode: receiver.postcode,
            ReceiverCountry: receiver.country,
            ReceiverAddressSource: receiver.addressSource,
            DepartmentRefNo: values.departmentRef,
            ProntoTransferNo: values.prontoRef || details.prontoRef,
            ParcelCount: Number(values.quantity),
            Notes: values.notes,
          };

          axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/consignment/create`,
              payload
            )
            .then((response) => {
              if (response.status === 200) {
                toast({
                  title: 'Save Sucessful',
                  description: `Consignment ${response.data.data.TrackingNo} was created successfully`,
                  isClosable: true,
                  position: 'bottom-left',
                  status: 'success',
                });

                resetState();

                if (!isPronto) {
                  props.onClose();
                  props.fetchData();
                } else {
                  history.push('/');
                }

                history.push('/consignment');
              } else {
                throw response;
              }
            })
            .catch((error) => {
              console.error(error);

              toast({
                title: 'Failed',
                description: `An error occurred while creating a consignment`,
                duration: null,
                isClosable: true,
                position: 'bottom-left',
                status: 'error',
              });
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {(props) => (
          <Form>
            {isPronto ? (
              <>
                <Box width={'46%'}>
                  <DetailForm isPronto={true} loadId={loadId} />
                </Box>
              </>
            ) : (
              <>
                <ModalBody>
                  <DetailForm loadId={loadId} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    isLoading={props.isSubmitting}
                    type="submit"
                    py={4}
                    px={10}
                    borderRadius={10}
                    backgroundColor="#6e26c7"
                    color="white"
                    disabled={isLoadingOutlet || isLoadingDepartment}
                  >
                    <BiSave size={30} />
                    <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                      SAVE
                    </Text>
                  </Button>
                </ModalFooter>
              </>
            )}

            {isPronto ? (
              <Flex
                alignItems={'flex-end'}
                flexDirection={'column'}
                gridGap={5}
                mb={10}
              >
                <Box
                  width={'50%'}
                  pl={5}
                  backgroundColor={'yellow'}
                  color={'red'}
                  p={5}
                  fontWeight={600}
                  borderRadius={10}
                >
                  Important Message : Please check and make sure all the details
                  are correct before Save the consignment. Thank you.
                </Box>
                <Button
                  isLoading={props.isSubmitting}
                  type="submit"
                  py={4}
                  px={10}
                  borderRadius={10}
                  backgroundColor="#6e26c7"
                  color="white"
                  disabled={isLoadingOutlet || isLoadingDepartment}
                >
                  <BiSave size={30} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    SAVE
                  </Text>
                </Button>
              </Flex>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ConsignmentDetailForm;
