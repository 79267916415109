import { Box, Image, Text, Center, Grid, GridItem } from '@chakra-ui/react';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';

function A6Paper(props) {
  const {
    ReceiverAddressCode,
    ReceiverCity,
    ReceiverContact,
    ReceiverCountry,
    ReceiverDepartmentOrOutlet,
    ReceiverName,
    ReceiverPostcode,
    ReceiverState,
    ReceiverStreet,
    SenderCity,
    SenderContact,
    SenderCountry,
    SenderDepartmentOrOutlet,
    SenderName,
    SenderPostcode,
    SenderState,
    SenderStreet,
    TrackingNo,
    DepartmentRefNo,
    ProntoTransferNo,
    ParcelCount,
  } = props.details;

  let referenceNo = '-';
  if (ProntoTransferNo && ProntoTransferNo.length > 0) {
    referenceNo = ProntoTransferNo;
  } else if (DepartmentRefNo && DepartmentRefNo.length > 0) {
    referenceNo = DepartmentRefNo;
  }

  return (
    <>
      <Box
        border="3px solid black"
        maxW="100mm"
        h="146mm"
        ref={props.componentRef}
        // mt={5}
        ml="auto"
        mr="auto"
      >
        <Box border="3px dashed black" m={2} p={1} height="530px">
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              {ParcelCount > 0 && (
                <Text
                  float="left"
                  py={2}
                  px={3.5}
                  fontSize="lg"
                  fontWeight="bold"
                  border="1px solid black"
                  borderRadius="50%"
                  m={0}
                  d="inline"
                >
                  {ParcelCount}
                </Text>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <Center>
                <Image
                  src={`${window.location.origin}/logo-black.png`}
                  alt="Tomei"
                  mb={1}
                />
              </Center>
            </GridItem>
            <GridItem colSpan={1}>
              {ReceiverAddressCode && (
                <Text
                  float="right"
                  py={1}
                  px={2}
                  fontSize="lg"
                  fontWeight="bold"
                  border="1px solid black"
                  borderRadius="8px"
                  m={0}
                  d="inline"
                >
                  {ReceiverAddressCode}
                </Text>
              )}
            </GridItem>
          </Grid>
          <Center fontSize="lg" fontWeight="bold" m={1}>
            LOGISTIC SERVICE
          </Center>
          <Center>
            <Barcode
              value={TrackingNo}
              margin={5}
              width={1.3}
              height={30}
              fontSize={14}
              display="block"
            />
          </Center>
          <Box height="fit-content" borderBottom="4px solid black">
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(8, 1fr)"
              borderTop="4px solid black"
              mt={2}
              mb={0}
              h="140px"
            >
              <GridItem borderRight="2px solid black" colSpan={2} ml={2}>
                <Text fontSize="18px" mt={2}>
                  Ship From:
                </Text>
              </GridItem>
              <GridItem colSpan={6} p={2} fontSize="11px" maxW="68mm">
                <Text>{SenderName}</Text>
                {SenderDepartmentOrOutlet && (
                  <Text>{SenderDepartmentOrOutlet}</Text>
                )}
                <Text>{SenderStreet}</Text>
                <Text>
                  {SenderPostcode}, {SenderCity}
                </Text>
                <Text>
                  {SenderState}, {SenderCountry}
                </Text>
                <Text>{SenderContact}</Text>
              </GridItem>
            </Grid>
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(8, 1fr)"
              borderTop="2px solid black"
              h="140px"
            >
              <GridItem
                borderRight="2px solid black"
                colSpan={2}
                fontSize="xs"
                ml={2}
              >
                <Text fontSize="18px" mt={2}>
                  Ship To:
                </Text>
              </GridItem>
              <GridItem colSpan={6} p={2} fontSize="11px" maxW="68mm">
                <Text fontWeight="bold" fontSize="13px">
                  {ReceiverName}
                </Text>
                {ReceiverDepartmentOrOutlet && (
                  <Text>{ReceiverDepartmentOrOutlet}</Text>
                )}
                <Text>{ReceiverStreet}</Text>
                <Text fontWeight="bold" fontSize="13px">
                  {ReceiverPostcode}, {ReceiverCity}
                </Text>
                <Text>
                  {ReceiverState}, {ReceiverCountry}
                </Text>
                <Text display="inline">{ReceiverContact}</Text>
              </GridItem>
            </Grid>
          </Box>
          <Grid
            fontSize="11px"
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(5, 1fr)"
          >
            <GridItem colSpan={3} ml={1} mt={1} alignItems={'center'}>
              <Text mb={2} mt={4}>
                Kindly check and make sure the items received are in good
                condition.
              </Text>
              <Text mb={2}>Ref No.: {referenceNo}</Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Center mt={4}>
                <QRCode value={`${TrackingNo}`} size={73} />
              </Center>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default A6Paper;
