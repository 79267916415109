import { action, createContextStore } from 'easy-peasy';

const AddressContextStore = createContextStore({
  // states
  isCreateByProntoRef: false,
  sender: {
    name: '',
    contact: '',
    department: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    country: 'Malaysia',
    addressCode: '',
    departmentCode: '',
    autofilled: false,
    addressSource: 'manual',
  },
  receiver: {
    name: '',
    contact: '',
    department: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    country: 'Malaysia',
    addressCode: '',
    departmentCode: '',
    autofilled: false,
    addressSource: 'manual',
  },
  details: {
    departmentRef: '',
    prontoRef: '',
    quantity: 1,
    notes: '',
  },
  isSenderFilled: false,
  isReceiverFilled: false,
  // actions
  setIsCreateByProntoRef: action((state, payload) => {
    state.isCreateByProntoRef = payload;
  }),
  initialiseAddress: action((state, payload) => {
    const { isCreateByProntoRef } = state;

    state.sender = {
      name: payload.preferred_name,
      contact: payload.contact,
      departmentOrOutlet: payload.department.name,
      address: payload.building.address,
      city: payload.building.city,
      state: payload.building.state.name,
      postcode: payload.building.postcode,
      country: payload.building.country.name,
      addressCode: payload.building.code,
      addressSource: 'department',
      departmentCode: payload.department.code,
      buildingPhone: payload.building.phone,
      autofilled: true,
    };
    state.receiver = {
      name: isCreateByProntoRef ? payload.receiverName : '',
      contact: '',
      departmentOrOutlet: '',
      address: '',
      city: '',
      state: '',
      postcode: '',
      country: 'Malaysia',
      addressCode: '',
      departmentCode: '',
      buildingPhone: '',
    };
  }),
  fillAddress: action((state, payload) => {
    if (payload.loadId) {
      if (payload.loadId === 1) {
        state.sender = {
          name: state.sender.name,
          contact: state.sender.contact,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          postcode: payload.postcode,
          country: payload.country,
          departmentOrOutlet: payload.departmentOrOutlet || '',
          addressCode: payload.addressCode || undefined,
          departmentCode: payload.departmentCode || undefined,
          buildingPhone: payload.phone,
          autofilled: payload.autofilled && true,
          addressSource: 'outlet',
        };
      } else {
        state.receiver = {
          name: state.receiver.name,
          contact: state.receiver.contact,
          department: payload.department ?? state.receiver.department,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          postcode: payload.postcode,
          country: payload.country,
          departmentOrOutlet: payload.departmentOrOutlet || '',
          addressCode: payload.addressCode || undefined,
          departmentCode: payload.departmentCode || undefined,
          buildingPhone: payload.phone,
          autofilled: payload.autofilled && true,
          addressSource: 'outlet',
        };
      }
    } else {
      if (!state.isSenderFilled) {
        state.sender = {
          name: state.sender.name,
          contact: state.sender.contact,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          postcode: payload.postcode,
          country: payload.country,
          departmentOrOutlet: payload.departmentOrOutlet || '',
          addressCode: payload.addressCode || undefined,
          departmentCode: payload.departmentCode || undefined,
          buildingPhone: payload.phone,
          autofilled: payload.autofilled && true,
        };
      } else if (!state.isReceiverFilled) {
        state.receiver = {
          name: state.receiver.name,
          contact: state.receiver.contact,
          department: payload.department ?? state.receiver.department,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          postcode: payload.postcode,
          country: payload.country,
          departmentOrOutlet: payload.departmentOrOutlet || '',
          addressCode: payload.addressCode || undefined,
          departmentCode: payload.departmentCode || undefined,
          buildingPhone: payload.phone,
          autofilled: payload.autofilled && true,
        };
      }
    }
  }),
  saveDetail: action((state, payload) => {
    if (!state.isSenderFilled) {
      state.sender = payload;
      state.isSenderFilled = true;
    } else if (!state.isReceiverFilled) {
      state.receiver = payload;
      state.isReceiverFilled = true;
    } else if (state.isReceiverFilled && state.isSenderFilled) {
      state.details = payload;
    }
  }),
  savePronto: action((state, payload) => {
    state.details = {
      departmentRef: payload.departmentRef,
      prontoRef: payload.prontoRef,
      quantity: payload.quantity,
      notes: payload.notes,
    };
    state.receiver = {
      name: payload.ReceiverName,
    };
  }),
  resetState: action((state, _) => {
    const emptyValue = {
      name: '',
      contact: '',
      department: '',
      address: '',
      city: '',
      state: '',
      postcode: '',
      country: 'Malaysia',
      addressCode: '',
      departmentCode: '',
      autofilled: false,
      addressSource: 'manual',
    };
    state.sender = emptyValue;
    state.receiver = emptyValue;
    state.details = {
      departmentRef: '',
      prontoRef: '',
      quantity: 1,
      notes: '',
    };
    state.isSenderFilled = false;
    state.isReceiverFilled = false;
  }),
});

export default AddressContextStore;
