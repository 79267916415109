import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';

const NetworkDetector = ({ children }) => {
  const [isDisconnected, setIsDisconnected] = useState(false);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';

    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', { mode: 'no-cors' })
          .then(() => {
            setIsDisconnected(false);
            clearInterval(webPing);
          })
          .catch(() => setIsDisconnected(true));
      }, 2000);
      return;
    }

    return setIsDisconnected(true);
  };

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  const toast = useToast();
  const toastIdRef = React.useRef();

  useEffect(() => {
    if (isDisconnected) {
      toastIdRef.current = toast({
        title: 'Internet connection lost.',
        duration: null,
        isClosable: true,
        position: 'bottom-left',
        status: 'error',
      });
    }

    if (!isDisconnected && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        title: 'Network connected.',
        duration: 7000,
        isClosable: true,
        position: 'bottom-left',
        status: 'success',
      });
    }
  }, [isDisconnected]);

  return children;
};

const withNetworkDetector =
  (Component) =>
  ({ ...props }) =>
    (
      <NetworkDetector>
        <Component {...props} />
      </NetworkDetector>
    );

export default withNetworkDetector;
