import axios from 'axios';
import Cookies from "universal-cookie/es6";

const commonFetch = axios.create({
  baseURL: process.env.REACT_APP_COMMON_API_URL,
  timeout: 60000,
});

const apiFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

export const payload = {
  ProjectName: 'logistic-web',
  LogLevel: 'Error',
  Timestamp: new Date(),
  ServiceName: '',
  Status: '500',
  Message: '',
  UserId: '',
  StackTrace: '',
  Details: {},
};

const beautifyErrStack = (stack) => {
  return typeof stack === 'string' ? stack : JSON.stringify(stack)
      .replace(/\\\\/g, '/')
      .replace(/\\/g, '')
      .replace(/"/g, '')
      .replace(/\\n/g, '')
      .replace(/\n/g, '');
}

const errorHandler = (error) => {
  const errorStack = error?.stack + error?.config?.data ?? error?.data?.StackTrace

  return apiFetch({
    url: '/api/logs',
    data: {
      ProjectName: 'common-api',
      LogLevel: 'Error',
      Timestamp: new Date(),
      ServiceName:
        error?.config?.baseURL + error?.config?.url ??
        error?.baseURL + error?.url,
      Status: JSON.parse(error?.config?.data).Status ?? '500',
      Message: JSON.parse(error?.config?.data).Message ?? 'Server Error',
      UserId: new Cookies().get("tomei-logistics-userID"),
      Error: beautifyErrStack(error),
      StackTrace: beautifyErrStack(errorStack),
      Details: {
        baseURL: error?.config?.baseURL ?? error?.baseURL,
        method: error?.config?.method ?? error?.method,
        url: error?.config?.url ?? error?.url,
      },
    },
    method: 'post',
  });
};

commonFetch.interceptors.request.use(
  (request) => request,
  (error) => errorHandler(error)
);

commonFetch.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

const Logger = {};

Logger.createLog = function (data) {
  if (data.Status !== '400') {
    if (data.Details.Payload) {
      delete data.Details.Payload;
    }
  }
  data.UserId = new Cookies().get("tomei-logistics-userID");
  return commonFetch({
    url: '/logs',
    data,
    method: 'post',
  });
};

export default Logger;
