import { Search2Icon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHistory } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import UserStore from '../Store/UserStore';
import './date-picker.css';
import { checkHasRoles } from '../utils';

function TransactionHistoryPopover() {
  let twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 7);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, onStartDateChange] = useState(twoWeeksAgo);
  const [endDate, onEndDateChange] = useState(new Date());
  const [transactionHistory, setTransactionHistory] = useState({
    totalItems: 0,
    dates: [],
    groups: {},
  });
  const roles = UserStore.useStoreState(state => state.roles);

  let getLatestValue = async () => {
    await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/consignment/transaction-history?start_date=${moment(
          startDate,
        ).format('YYYY-MM-DD')}&end_date=${moment(endDate).format(
          'YYYY-MM-DD',
        )}`,
      )
      .then(response => {
        const activities = response.data.data.reverse();
        const totalItems = activities.length;
        const groups = {};
        const dates = [];

        for (const activity of activities) {
          const date = moment
            .utc(activity.ActivityDatetime)
            .local()
            .format('DD/MM/YYYY');

          if (groups[date]) {
            groups[date].push(activity);
          } else {
            dates.push(date);
            groups[date] = [activity];
          }
        }

        setTransactionHistory({
          totalItems,
          groups,
          dates,
        });
      })
      .catch(error => {
        toast({
          title:
            error.response?.data?.message ||
            'Failed to get transaction history',
          duration: null,
          isClosable: true,
          position: 'bottom-left',
          status: 'error',
        });
      });
  };

  useEffect(() => {
    if (isOpen) getLatestValue();
  }, [isOpen]);

  function renderValues() {
    const elements = transactionHistory.dates.map(date => {
      const activities = transactionHistory.groups[date];
      return (
        <React.Fragment key={date}>
          <Heading size="md">DATE: {date}</Heading>
          <Box textAlign="right" mr={5} mb={1}>
            <Text fontSize="md">(Total: {activities.length} items)</Text>
          </Box>
          <Table
            variant="simple"
            background="white"
            borderTopLeftRadius="xl"
            borderTopRightRadius="xl"
            mb={5}>
            <Thead>
              <Tr>
                <Th
                  fontSize="lg"
                  pr={0}
                  pb={3}
                  pl={5}
                  pt={3}
                  textTransform="capitalize"
                  color="black">
                  No.
                </Th>
                <Th
                  fontSize="lg"
                  pb={3}
                  pl={0}
                  pr={0}
                  pt={3}
                  textTransform="capitalize"
                  color="black">
                  Tracking No.
                </Th>
                <Th
                  fontSize="lg"
                  pr={0}
                  pb={3}
                  pl={5}
                  pt={3}
                  textTransform="capitalize"
                  color="black">
                  Performed By
                </Th>
                <Th
                  fontSize="lg"
                  pr={0}
                  pb={3}
                  pl={2}
                  pt={3}
                  textTransform="capitalize"
                  color="black">
                  Time
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="md">
              {activities.flatMap((activity, index) => {
                const {
                  id,
                  TrackingNo,
                  PerformedByPreferredName,
                  ActivityDatetime,
                  PerformedByBuildingCode,
                } = activity;
                const rows = [
                  <Tr key={id}>
                    <Td fontSize="md" p={2} pl={5}>
                      {index + 1}
                    </Td>
                    <Td
                      fontSize="md"
                      p={2}
                      pl={0}
                      pr={0}
                      minWidth="90px"
                      maxWidth="90px">
                      {TrackingNo}
                    </Td>
                    <Td fontSize="md" p={2} pl={5} pr={0}>
                      {PerformedByPreferredName} - {PerformedByBuildingCode}
                    </Td>
                    <Td fontSize="md" py={2} pl={2} pr={0}>
                      {moment.utc(ActivityDatetime).local().format('hh:mm A')}
                    </Td>
                  </Tr>,
                ];

                if (activity.NextActivity) {
                  rows.push(
                    <Tr key={id + '-next-activity'}>
                      <Td fontSize="md" p={2} pl={5}></Td>
                      <Td
                        fontSize="md"
                        p={2}
                        pl={0}
                        pr={0}
                        minWidth="90px"
                        maxWidth="90px">
                        {TrackingNo}
                      </Td>
                      <Td fontSize="md" p={2} pl={5} pr={0}>
                        {activity.NextActivity.PerformedByPreferredName} -{' '}
                        {activity.NextActivity.PerformedByBuildingCode}
                      </Td>
                      <Td fontSize="md" py={2} pl={2} pr={0}>
                        {moment
                          .utc(activity.NextActivity.ActivityDatetime)
                          .local()
                          .format('hh:mm A')}
                      </Td>
                    </Tr>,
                  );
                }

                return rows;
              })}
            </Tbody>
          </Table>
        </React.Fragment>
      );
    });
    return (
      <>
        <Box textAlign="right">
          <Text fontWeight="bold" fontSize="md">
            Item Found: {transactionHistory.totalItems} items
          </Text>
        </Box>
        {elements}
      </>
    );
  }

  return (
    <Popover
      arrowSize={25}
      closeOnBlur={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}>
      <PopoverTrigger>
        <Button
          backgroundColor="#6e26c7"
          leftIcon={<FontAwesomeIcon icon={faHistory} />}
          borderRadius={10}
          colorScheme="purple"
          size="md"
          variant="solid"
          mr={3}
          disabled={!checkHasRoles(roles, ['transaction history'])}>
          TRANSACTION HISTORY
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="#F7F7F7" w="600px">
        <PopoverArrow bg="#F7F7F7" arrowSize={30} />
        <PopoverBody>
          <Box py={10}>
            <Flex>
              <Center ml={10} mr={10}>
                <DatePicker
                  calendarIcon={
                    <FontAwesomeIcon icon={faCalendar} color="purple" />
                  }
                  clearIcon={false}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  onChange={onStartDateChange}
                  value={startDate}
                  fixedHeight
                />
              </Center>
              <Center mt={1} mr={10}>
                <Heading fontSize="2xl">TO</Heading>
              </Center>
              <Center mr={10}>
                <DatePicker
                  calendarIcon={
                    <FontAwesomeIcon icon={faCalendar} color="purple" />
                  }
                  clearIcon={false}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  onChange={onEndDateChange}
                  value={endDate}
                  fixedHeight
                />
              </Center>
              <Button
                backgroundColor="gray.300"
                type="submit"
                onClick={getLatestValue}>
                <Search2Icon />
              </Button>
            </Flex>
          </Box>
          <Box mx={5}>
            {transactionHistory.dates.length > 0 ? renderValues() : null}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default TransactionHistoryPopover;
