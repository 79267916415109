import React, { useEffect } from 'react';
import { Box, Text, Flex, Spacer, useToast } from '@chakra-ui/react';
import UserStore from '../Store/UserStore';
import { useHistory } from 'react-router-dom';
import { checkHasRoles } from '../utils';
import UpdatedHistoryTable from './UpdatedHistoryTable';

function UpdatedHistory(props) {
  document.title = 'Tomei Logistic - Updated History';
  const toast = useToast();
  const roles = UserStore.useStoreState(state => state.roles);
  const history = useHistory();

  useEffect(() => {
    if (!checkHasRoles(roles, ['updated history'])) {
      toast({
        title: 'Failed',
        description:
          "You don't have permission to access updated history page.",
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
      });
      history.push('/');
    }
  }, [roles]);

  return (
    <Box
      ml={props.isMenuClosed ? '20px' : '270px'}
      p={6}
      flex="1"
      textAlign="left"
      minH="816px">
      <Flex>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            Updated History
          </Text>
          <Text>To view the details that have been updated</Text>
        </Box>
        <Spacer />
      </Flex>
      <UpdatedHistoryTable />
    </Box>
  );
}

export default UpdatedHistory;
