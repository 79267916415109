import { ArrowLeftIcon, ArrowRightIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Modal,
  Tooltip,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Grid,
  GridItem,
  Heading,
  Center,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DualListBox from 'react-dual-listbox';
import {
  faChevronLeft,
  faChevronRight,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './react-dual-listbox.css';
import axios from 'axios';
import moment from 'moment';

function AssignRoleModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [user, setUser] = useState({
    building: '',
    company: '',
    department: '',
    email: '',
    id: 1,
    logistics_roles: [],
    preferred_name: '',
    staff_id: '',
    staff_type: '',
    status: '',
    updated_by: '',
    updated_at: '',
  });
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { isButton, id, staffId } = props;

  let customClose = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/users/${id}/role-assignment`,
        {
          role_ids: selectedRoles,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: 'Save Sucessful',
            description: `Roles Assigned for Staff ID: ${staffId} was saved successfully`,
            isClosable: true,
            position: 'bottom-left',
            status: 'success',
          });
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: 'Failed',
          description: `An error occurred while saving Roles Assigned for staff ID: ${staffId}. Please try again or contact admin`,
          duration: null,
          isClosable: true,
          position: 'bottom-left',
          status: 'error',
        });
        onClose();
      });
  };
  const options = [
    {
      value: 1,
      label: 'Admin',
      title: ' Grants the user access to everything in the system',
    },
    {
      label: 'Consignment',
      options: [
        {
          value: 3,
          label: 'Own',
          title:
            'Allows the user to only view consignments belonging to ownself',
        },
        {
          value: 4,
          label: 'Department/Outlet',
          title:
            'Allows the user to view all consignments from his/her department/outlet',
        },
        {
          value: 20,
          label: 'Area',
          title:
            'Allows the user to view all consignments for department/outlet in an area',
        },
        {
          value: 5,
          label: 'All',
          title: 'Allows the user to view all consignment',
        },
      ],
    },
    {
      value: 6,
      label: 'Consignment Notes',
      title: 'Allows the user to edit Notes for consignments',
    },
    {
      value: 7,
      label: 'Activity History',
      title: 'Allows the user to view Activity History for consignments',
    },
    {
      label: 'Scan',
      options: [
        {
          value: 9,
          label: 'Logistic Item Received',
          title:
            'Allows the user to scan items to be flagged as "Arrived at Sorting Center"',
        },
        {
          value: 10,
          label: 'Item Pickup for Delivery',
          title: 'Allows the user to pickup an item for delivery',
        },
        {
          value: 11,
          label: 'Item Collection',
          title:
            'Allows the user to pickup an item from the outlet or branch to be delivered back to the Logistic department',
        },
        {
          value: 12,
          label: 'Item Received',
          title: 'Allows the user to receive a delivered item',
        },
        {
          value: 13,
          label: '3rd Party Item Received',
          title: 'Allows third party to receive a delivered item',
        },
        {
          value: 14,
          label: 'Transaction History',
          title:
            'Allows the user to know what items they had picked up or collected previously',
        },
        {
          value: 15,
          label: 'Parcel with me',
          title:
            'Allows the user to view what parcel he/she had picked up or collected and still with them',
        },
      ],
    },
    {
      label: 'Settings',
      options: [
        {
          value: 17,
          label: 'Address Book',
          title:
            'Allows the user to do Address Book Settings in Logistic Systems',
        },
        {
          value: 18,
          label: 'User Role',
          title: 'Allows the user to do User Role Settings in Logistic System',
        },
        {
          value: 19,
          label: 'Updated History',
          title: 'Allows the user view Update History in the Logistic System',
        },
      ],
    },
  ];

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/users/${id}`)
        .then((response) => {
          setUser(response.data.data);
          setSelectedRoles(
            response.data.data.logistics_roles.map((role) => role.id)
          );
        });
      setTimeout(() => {
        if (
          document.getElementsByClassName('rdl-move rdl-move-all').length === 2
        ) {
          document
            .getElementsByClassName('rdl-move rdl-move-all')[0]
            .setAttribute('style', 'display: none');
          document
            .getElementsByClassName('rdl-move rdl-move-all')[1]
            .setAttribute('style', 'display: none');
        }
      }, 1);
    }
  }, [isOpen]);
  return (
    <>
      {isButton ? (
        <Tooltip
          label="Assign User Role"
          bg="gray.600"
          color="white"
          borderRadius={8}
          fontSize="md"
          placement="top"
          isDisabled={props.disabled}
          hasArrow
        >
          <Button
            onClick={onOpen}
            backgroundColor="orange.400"
            color="orange.700"
            borderRadius="14px"
            size="sm"
            variant="solid"
          >
            <FontAwesomeIcon icon={faUserTag} />
          </Button>
        </Tooltip>
      ) : (
        <Text
          textAlign="left"
          height="100%"
          onClick={onOpen}
          size="lg"
          variant="solid"
          color="purple.500"
          fontWeight="bolder"
          as="u"
          cursor="pointer"
        >
          {staffId}
        </Text>
      )}

      <Modal isOpen={isOpen} onClose={customClose}>
        <ModalOverlay />
        <ModalContent minW="700px" p={10}>
          <ModalHeader textAlign="center" fontSize="3xl">
            ASSIGN ROLE
          </ModalHeader>
          <ModalCloseButton />
          <ModalHeader pb={3} fontWeight="bold" fontSize="2xl">
            USER DETAILS
          </ModalHeader>
          <Grid
            templateRows="repeat(3, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={1}
            borderRadius="13px"
            border="2px solid lightgrey"
            p={5}
            mb={5}
          >
            <GridItem colSpan={1} p={5}>
              <Heading size="sm" textColor="gray.400">
                STAFF ID
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                {user.staff_id}
              </Text>
            </GridItem>
            <GridItem colSpan={1} p={5}>
              <Heading size="sm" textColor="gray.400">
                DEPARTMENT / BUILDING NAME
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                {user.department} / {user.building}
              </Text>
            </GridItem>
            <GridItem colSpan={1} p={5}>
              <Heading size="sm" textColor="gray.400">
                NAME
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                {user.preferred_name}
              </Text>
            </GridItem>
            <GridItem colSpan={1} p={5}>
              <Heading size="sm" textColor="gray.400">
                POSITION
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                {user.job_title}
              </Text>
            </GridItem>
            <GridItem colSpan={1} p={5}>
              <Heading size="sm" textColor="gray.400">
                EMAIL
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                {user.email}
              </Text>
            </GridItem>
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <GridItem colSpan={1}>
              <Box d="inline">
                <Text fontSize="2xl" fontWeight="bold" mr={2} d="inline">
                  ROLES
                </Text>
                <Center d="inline-flex">
                  <Tooltip
                    label="Hover to each role for more details"
                    fontSize="sm"
                    float="left"
                  >
                    <InfoIcon />
                  </Tooltip>
                </Center>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontSize="2xl" fontWeight="bold">
                ROLES ASSIGNED
              </Text>
            </GridItem>
          </Grid>

          <DualListBox
            className=""
            options={options}
            selected={selectedRoles}
            onChange={(assignedRoles) => {
              if (assignedRoles.indexOf(1) !== -1) {
                setSelectedRoles([
                  1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19,
                ]);
              } else if (
                assignedRoles.indexOf(4) !== -1 &&
                assignedRoles.indexOf(3) === -1
              ) {
                const newAssignedRoles = assignedRoles.concat([3]);
                setSelectedRoles(newAssignedRoles.splice(0));
              } else {
                setSelectedRoles(assignedRoles.splice(0));
              }
            }}
            icons={{
              moveLeft: (
                <FontAwesomeIcon icon={faChevronLeft} color="#6e26c7" />
              ),
              moveAllLeft: <ArrowLeftIcon color="#6e26c7" />,
              moveRight: (
                <FontAwesomeIcon icon={faChevronRight} color="#6e26c7" />
              ),
              moveAllRight: <ArrowRightIcon color="#6e26c7" />,
            }}
          />
          <Box fontSize="xs" mt={5}>
            <Text fontWeight="bold" mr={3} d="inline">
              Last Update Date/Time:
            </Text>
            <Text d="inline" mr={3}>
              {moment
                .utc(user.roles_updated_at)
                .local()
                .format('DD/MM/YYYY hh:mm:ss A')}
            </Text>
            <Box>
              <Text fontWeight="bold" mr={3} d="inline">
                Last Updated By:
              </Text>
              <Text d="inline">{user.roles_updated_by}</Text>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AssignRoleModal;
