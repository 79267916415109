import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Select,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import React, { useEffect, useState, useMemo } from 'react';
import {
  getDepartments,
  getBuildings,
} from '../AddressBook/Helper/RetreiveDataHelper';
const SearchFilter = (props) => {
  const { setSearchFilters } = props;
  const [building, setBuilding] = useState('');
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [department, setDepartment] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const buildingData = await getBuildings(true);
      const departmentData = await getDepartments(true);
      setBuildingOptions(buildingData.renderedValues);
      setDepartmentOptions(departmentData.renderedValues);
    };
    getOptions();
  }, []);

  return (
    <Accordion
      allowToggle
      w="100%"
      defaultIndex={0}
      bg="#fafafa"
      p={4}
      m={5}
      borderRadius="25px"
      color="grey"
    >
      <AccordionItem border="none">
        <AccordionButton p={0}>
          <Box flex="1" textAlign="left">
            SEARCH FILTERS
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Formik
            initialValues={{
              staffId: '',
              fullName: '',
              email: '',
              department: department,
              building: building,
            }}
            enableReinitialize
            onSubmit={async (values, actions) => {
              const params = [];
              if (values.staffId.length > 0) {
                params.push(['staff_id', values.staffId]);
              }
              if (values.fullName.length > 0) {
                params.push(['full_name', values.fullName]);
              }
              if (values.email.length > 0) {
                params.push(['email', values.email]);
              }
              if (values.department.length > 0) {
                params.push(['department_id', values.department]);
              }
              if (values.building.length > 0) {
                params.push(['building_id', values.building]);
              }
              actions.setSubmitting(false);
              setSearchFilters(params);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns="repeat(6, 1fr)"
                  gap={3}
                  maxW="fit-content"
                >
                  <Box>
                    <Field name="staffId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.staffId && form.touched.staffId
                          }
                        >
                          <Input
                            {...field}
                            id="staffId"
                            placeholder="Staff ID"
                          />
                          <FormErrorMessage>
                            {form.errors.staffId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="fullName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                        >
                          <Input {...field} id="fullName" placeholder="Name" />
                          <FormErrorMessage>
                            {form.errors.fullName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <Input
                            {...field}
                            id="email"
                            placeholder="Email"
                            type="email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="department">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.department && form.touched.department
                          }
                        >
                          <Select
                            {...field}
                            placeholder="Department"
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            {departmentOptions}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.department}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="building">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.building && form.touched.building
                          }
                        >
                          <Select
                            {...field}
                            placeholder="Building"
                            onChange={(e) => setBuilding(e.target.value)}
                          >
                            {buildingOptions}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.building}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Button
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      <Search2Icon />
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default SearchFilter;
