import {
  Box,
  FormControl,
  Grid,
  GridItem,
  ModalHeader,
} from '@chakra-ui/react';
import AddressContextStore from '../Store/AddressContextStore';
import AddressBook from './AddressBook/AddressBook';
import DepartmentDropdown from './DepartmentDropdown';
import OutletDropdown from './OutletDropdown';

function LoadContact(props) {
  const state = AddressContextStore.useStoreState((state) => state);
  const {
    selectedDepartment,
    setSelectedDepartment,
    selectedOutlet,
    setSelectedOutlet,
    setAddressSource,
    isPronto,
    loadId,
    isLoadingOutlet,
    setIsLoadingOutlet,
    isLoadingDepartment,
    setIsLoadingDepartment,
  } = props;

  const gridCount = state.isSenderFilled && !state.isReceiverFilled ? 3 : 2;

  const setOutlet = (value) => {
    setAddressSource('outlet');
    setSelectedOutlet(value);
  };

  const setDepartment = (value) => {
    setAddressSource('department');
    setSelectedDepartment(value);
  };

  return (
    <>
      <Box
        bg={isPronto ? '#F9F9F9' : '#d4d3d5'}
        p={3}
        m={3}
        borderRadius={isPronto ? '10px' : '25px'}
        color="grey"
      >
        {isPronto ? (
          'LOAD FROM'
        ) : (
          <ModalHeader pt={0} pl={1} pb={2}>
            LOAD FROM
          </ModalHeader>
        )}

        <Grid templateColumns={`repeat(${gridCount}, 1fr)`} gap={5} mt={3}>
          <GridItem colSpan={1}>
            <FormControl
              id={loadId ? `outlet${loadId}` : 'outlet'}
              d="inline-block"
              mb="10px"
            >
              <OutletDropdown
                setSelectedDepartment={setSelectedDepartment}
                setSelectedOutlet={setOutlet}
                selectedOutlet={selectedOutlet}
                loadId={loadId}
                isPronto={isPronto}
                isLoading={isLoadingOutlet}
                setIsLoading={setIsLoadingOutlet}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl
              id={loadId ? `department${loadId}` : 'department'}
              d="inline-block"
              mb="10px"
            >
              <DepartmentDropdown
                setSelectedDepartment={setDepartment}
                setSelectedOutlet={setSelectedOutlet}
                selectedDepartment={selectedDepartment}
                selectedOutlet={selectedOutlet}
                loadId={loadId}
                isLoading={isLoadingDepartment}
                setIsLoading={setIsLoadingDepartment}
              />
            </FormControl>
          </GridItem>

          {state.isSenderFilled && !state.isReceiverFilled && (
            <GridItem colSpan={1}>
              <FormControl w="30%" d="inline-block" mb="20px">
                <AddressBook
                  setSelectedDepartment={setSelectedDepartment}
                  setSelectedOutlet={setSelectedOutlet}
                  setAddressSource={setAddressSource}
                />
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default LoadContact;
