import {
  Button,
  Center,
  Image,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function CancelModal(props) {
  const { fetchData, getConsignment } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const cancelConsignment = async (trackingNo) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/consignment/cancel`, {
        TrackingNo: trackingNo,
      })
      .then((response) => {
        if(response.data.code === 200) {
          toast({
            title: "Cancel Successful",
            description: `Tracking No. ${trackingNo} was cancelled successfully`,
            isClosable: true,
            position: "bottom-left",
            status: "error",
          });
          onClose();
          fetchData();
          if(getConsignment) {
            getConsignment();
          }
        }
        else {
          toast({
            title: "Cancel Unsuccessful",
            description: response.response.data.message,
            duration: null,
            isClosable: true,
            position: "bottom-left",
            status: "error",
          });
        }
      })
      .catch((error) => {
        toast({
          title: "Cancel Unsuccessful",
          description: "An error occurred while cancelling a consignment",
          duration: null,
          isClosable: true,
          position: "bottom-left",
          status: "error",
        });
      });
  };

  return (
    <>
      <Tooltip
        label="Cancel Shipment"
        bg="gray.600"
        color="white"
        borderRadius={8}
        fontSize="md"
        placement="top"
        isDisabled={props.disabled}
        hasArrow
      >
        <Button
          onClick={onOpen}
          disabled={props.disabled}
          borderRadius="14px"
          backgroundColor={props.disabled ? "#9A9A9A" : "red.400"}
          color={props.disabled ? "#E5E5E5" : "red.200"}
          ml={props.showMessage ? 0 : 3}
          size={props.showMessage ? "lg" : "sm"}
          w={props.showMessage && "100%"}
        >
          <FontAwesomeIcon icon={faTimes} />
          {props.showMessage && <Text m={2}>CANCEL SHIPMENT</Text>}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CANCEL CONFIRMATION</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image
                src={`${window.location.origin}/warning.gif`}
                h="75%"
                w="75%"
              />
            </Center>
            <Text>
              {`Are you sure you would like to cancel Tracking No. ${props.trackingNo} from the system?`}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={(values, actions) => cancelConsignment(props.trackingNo)}
            >
              YES
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              NO
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CancelModal;
