import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePagination, useTable, useSortBy } from 'react-table';
import { Box, Text, Flex, Spacer, useToast } from '@chakra-ui/react';
import axios from 'axios';
import SearchFilter from './SearchFilter';
import CreateOrUpdate from './CreateOrUpdate';
import AddressBookTable from './AddressBookTable';
import UserStore from '../Store/UserStore';
import { useHistory } from 'react-router-dom';
import { checkHasRoles } from '../utils';

function AddressBook(props) {
  document.title = 'Tomei Logistic - Address Book';
  const toast = useToast();
  const [addressBookData, setAddressBookData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [params, setParams] = useState({
    type: 'All',
    status: '1',
    nonce: 0,
  });
  const roles = UserStore.useStoreState(state => state.roles);
  const history = useHistory();
  const DATA_PER_PAGE = 10;
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'AddressID',
      },
      {
        Header: 'Code',
        accessor: 'AddressCode',
      },
      {
        Header: 'Type',
        accessor: 'AddressType',
      },
      {
        Header: 'Name',
        accessor: 'AddressName',
      },
      {
        Header: 'Address',
        accessor: 'Street',
      },
      {
        Header: 'Postcode',
        accessor: 'Postcode',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'State',
        accessor: 'State',
      },
      {
        Header: 'Country',
        accessor: 'Country',
      },
      {
        Header: 'Status',
        accessor: 'IsActive',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
      },
      {
        Header: 'ContactNo',
        accessor: 'ContactNo',
      },
    ],
    [],
  );

  const data = useMemo(() => addressBookData, [addressBookData]);

  const { state, gotoPage, headerGroups, page, pageCount, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetPage: true,
        initialState: {
          hiddenColumns: ['AddressID', 'ContactNo'],
          pageSize: DATA_PER_PAGE,
          pageIndex: currentPage,
        },
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName];
            const rowTwoColumn = row2.values[columnName];
            if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
                ? 1
                : -1;
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          },
        },
      },
      useSortBy,
      usePagination,
    );

  if (pageCount - 1 < currentPage && pageCount !== 0) {
    setCurrentPage(0);
  }

  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };

  const getData = useCallback(
    async ({ type, status }) => {
      let url = `${process.env.REACT_APP_API_URL}/api/addresses`;
      const searchFilters = [];

      if (status !== '') {
        searchFilters.push(['IsActive', status]);
      }
      if (type !== 'All') {
        searchFilters.push(['AddressType', type]);
      }
      const params = new URLSearchParams(searchFilters);

      await axios
        .get(url, {
          params,
        })
        .then(async response => {
          setAddressBookData(response.data.data);
        })
        .catch(error => {
          console.error(error);
          toast({
            title:
              error.response?.data?.message ||
              'Failed to get transaction history',
            duration: null,
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
          });
        });
    },
    [toast],
  );

  useEffect(() => {
    if (checkHasRoles(roles, ['address book'])) {
      getData(params);
    } else {
      toast({
        title: 'Failed',
        description: "You don't have permission to access address book page.",
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
      });
      history.push('/');
    }
  }, [getData, params, roles]);

  return (
    <Box
      ml={props.isMenuClosed ? '20px' : '270px'}
      p={6}
      flex="1"
      textAlign="left"
      minH="816px">
      <Flex>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            Address Book
          </Text>
          <Text>To search the address in the system</Text>
        </Box>
        <Spacer />
        <Box>
          <CreateOrUpdate showMessage={true} onClose={() => getData(params)} />
        </Box>
      </Flex>
      <SearchFilter
        onSubmit={params =>
          setParams({
            ...params,
            nonce: Date.now(),
          })
        }
      />
      <AddressBookTable
        data={data}
        dataPerPage={DATA_PER_PAGE}
        state={state}
        gotoPage={gotoPage}
        headerGroups={headerGroups}
        page={page}
        pageCount={pageCount}
        prepareRow={prepareRow}
        refreshList={() => getData(params)}
        handleChange={handleChange}
      />
    </Box>
  );
}

export default AddressBook;
