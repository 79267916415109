import React, { useState, useEffect } from 'react';
import _, { set } from 'lodash';
import moment from 'moment';

import {
  getDepartments,
  getOutlets,
} from '../../../AddressBook/Helper/RetreiveDataHelper';
import { Form, Select, Row, Col, DatePicker, Button } from 'antd';
import { Spinner } from '@chakra-ui/react';
import { SearchOutlined } from '@ant-design/icons';
import { checkHasRoles } from '../../../utils';
import UserStore from '../../../Store/UserStore';

function SearchSection({ fetchData, user, setDateParams, dateParams }) {
  const [searchParams, setSearchParams] = useState({});
  const [restriction, setRestriction] = useState(2);
  const [form] = Form.useForm();

  const { RangePicker } = DatePicker;
  const { Option, OptGroup } = Select;

  const userRoles = UserStore.useStoreState((state) => state.roles);
  const userBuilding = UserStore.useStoreState((state) => state.building);
  const userDepartment = UserStore.useStoreState((state) => state.department);

  const [outletOptions, setOutletOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setOptions = async () => {
    let outletData = await (await getOutlets()).mappedResponse;
    let departmentData = await (
      await getDepartments(false, { status: ['active', 'inactive'] }, true)
    ).renderedValues;

    if (restriction === 2) {
      outletData = outletData.filter(
        (outlet) => outlet.addressCode === userBuilding.code
      );

      departmentData = departmentData.filter(
        (dept) => dept.key === userDepartment.code
      );
    } else if (restriction === 1) {
      outletData = outletData.filter(
        (outlet) => outlet.addressCode === userBuilding.code
      );
    }

    const listWarehouse = outletData.filter(
      (outlet) => outlet.building_type == 'Warehouse'
    );
    const listOutlets = _.xor(outletData, listWarehouse);

    const listing = (list) => {
      const options = _.uniqBy(list, 'state').map((building) => (
        <OptGroup label={building.state}>
          {list
            .filter((outlets) => building.state === outlets.state)
            .map((outlet) => {
              return (
                <Option value={outlet.addressCode}>
                  {outlet.addressCode} - {outlet.departmentOrOutlet}
                </Option>
              );
            })}
        </OptGroup>
      ));
      setIsLoading(false);
      return options;
    };

    setOutletOptions(listing(listOutlets));
    setWarehouseOptions(listing(listWarehouse));

    setDepartmentOptions(departmentData);
  };

  useEffect(() => {
    setIsLoading(true);
    if (checkHasRoles(userRoles, ['all'])) {
      setRestriction(0);
    } else if (checkHasRoles(userRoles, ['department/outlet'])) {
      setRestriction(1);
    }
    if (userRoles) {
      setOptions();
    }
  }, [userRoles, userBuilding, userDepartment]);

  const renderLoading = () => (
    <div>
      <Spinner size="md" />
    </div>
  );

  return (
    <Form
      form={form}
      name="control-ref"
      onSubmitCapture={(e) => {
        e.preventDefault();
        let search = { ...dateParams, ...searchParams };
        if (!search.Outlet) {
          delete search.Outlet;
        }
        if (!search.Department) {
          delete search.Department;
        }

        setDateParams(search);
        fetchData(dateParams);
      }}
    >
      {isLoading ? (
        <Row gutter={20}>
          <Col>{renderLoading()}</Col>
        </Row>
      ) : (
        <Row gutter={20}>
          <Col>
            <Select
              showSearch
              style={{ width: 200, borderRadius: '5px' }}
              placeholder="Department"
              optionFilterProp="children"
              defaultValue={
                userDepartment.code === 'HQ' ? userDepartment.code : ''
              }
              onSelect={(value) =>
                setSearchParams({
                  ...searchParams,
                  Department: value === 'all' ? '' : value,
                })
              }
            >
              {restriction !== 2 ? <Option value="all">All</Option> : null}
              {departmentOptions.map((dept) => (
                <Option
                  key={dept.key}
                  value={dept.key}
                >{`${dept.key} - ${dept.props.children[2]} `}</Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Select
              showSearch
              showArrow
              style={{
                width: 200,
              }}
              placeholder="Outlet/Warehouse"
              defaultValue={
                userDepartment.code === 'HQ' ? '' : userBuilding.code
              }
              onSelect={(value) =>
                setSearchParams({
                  ...searchParams,
                  Outlet: value === 'all' ? '' : value,
                })
              }
            >
              {restriction !== 2 ? <Option value="all">All</Option> : null}
              {restriction === 0 ? (
                <Option disabled style={{ textAlign: 'center' }}>
                  --- Outlets ---
                </Option>
              ) : null}

              {outletOptions}
              {restriction === 0 ? (
                <Option disabled style={{ textAlign: 'center' }}>
                  --- Warehouses ---
                </Option>
              ) : null}

              {warehouseOptions}
            </Select>
          </Col>

          <Col>
            <RangePicker
              style={{ width: 220, borderRadius: '5px' }}
              defaultValue={[moment(), moment()]}
              onChange={(values) => {
                setSearchParams({
                  ...searchParams,
                  ActivityDateStart: moment(values[0]).format('YYYY-MM-DD'),
                  ActivityDateEnd: moment(values[1]).format('YYYY-MM-DD'),
                });
              }}
            />
          </Col>

          <Col xs={4} sm={4} md={1} lg={1}>
            <Button
              htmlType="submit"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
}

export default SearchSection;
