import 'core-js'; // Support missing polyfill for old browser
import 'proxy-polyfill'; // Support missing proxy polyfill for Internet Explorer browser
import { detect } from 'detect-browser';
import {
  ColorModeScript,
  ChakraProvider,
  createStandaloneToast,
} from '@chakra-ui/react';
import 'antd/dist/antd.css';

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import customTheme from './modifiedTheme';
import { BrowserRouter as Router } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
import axios from 'axios';
import Logger, { payload as logPayload } from './Logger';

const payload = { ...logPayload };

const isNotSupported = () => {
  const browser = detect();
  switch (browser && browser.name) {
    case 'firefox': {
      if (parseFloat(browser.version) < 63) {
        return true;
      }
      break;
    }
    case 'chrome': {
      if (parseFloat(browser.version) < 49) {
        return true;
      }
      break;
    }
    case 'edge': {
      if (parseFloat(browser.version) < 20) {
        return true;
      }
      break;
    }
    case 'opera': {
      if (parseFloat(browser.version) < 36) {
        return true;
      }
      break;
    }
    case 'ie':
      return true;
    default:
      return false;
  }
};

axios.interceptors.request.use(
  (req) => {
    payload.ServiceName = req?.baseURL ?? '' + req?.url;
    payload.Details = {
      baseURL: process.env.REACT_APP_API_URL,
      method: req?.method,
      url: req?.url.substring(process.env.REACT_APP_API_URL.length),
      Payload: req?.data,
    };
    const logisticToken = new Cookies().get('tomei-logistics-token');
    req.headers.Authorization = `Bearer ${logisticToken}`;
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

const errorHandler = async (error) => {
  const message = error?.response?.data?.message ?? 'Server Error';
  const status = error?.response?.status?.toString() ?? '500';
  if (status !== '500') {
    payload.Timestamp = new Date();
    payload.Message = message;
    payload.StackTrace = error?.stack
      .replace(/\\\\/g, '/')
      .replace(/"/g, '')
      .replace(/\n/g, '');
    payload.Status = status;
    await Logger.createLog(payload);
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const toast = createStandaloneToast({ theme: customTheme });
    if (
      error.response.status === 401 &&
      error.response.data.message.toLowerCase() === 'bad access token'
    ) {
      const cookies = new Cookies();
      cookies.remove('tomei-sso-token');
      cookies.remove('tomei-logistics-userID');
      window.location.href =
        '/login?redirect_url=' +
        encodeURIComponent(window.location.pathname + window.location.search);
    } else if (error.response.status === 403) {
      toast({
        title: 'Failed',
        description: "You don't have permission to access this resource.",
        status: 'error',
        isClosable: true,
        position: 'bottom-left',
      });
    }
    let error2 = error;
    error2.data = { data: [] };
    errorHandler(error);
    return error2;
  }
);

ReactDOM.render(
  <StrictMode>
    <ChakraProvider theme={customTheme}>
      <ColorModeScript />
      {/* <StoreProvider store={Store}> */}
      <Router>
        {isNotSupported() && (
          <div
            style={{
              alignItems: 'center',
              color: 'white',
              background: '#FA8072',
              zIndex: '2',
              top: 0,
              left: 0,
              right: 0,
              padding: '10px',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            This browser version is not supported. We recommend to use the
            latest modern browser.
          </div>
        )}
        <App />
      </Router>
      {/* </StoreProvider> */}
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
