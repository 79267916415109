import {
  Box,
  Center,
  Text,
  Image,
  Flex,
  Button,
  useToast,
  Heading,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import Cookies from 'universal-cookie/es6';

function Login() {
  document.title = 'Tomei Logistic - Login';
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const { code, redirect_url: redirectUrl } = QueryString.parse(
    location.search
  );

  useEffect(() => {
    if (code) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
          code,
        })
        .then((response) => {
          const cookies = new Cookies();

          cookies.set('tomei-logistics-token', response.data.access_token, {
            path: '/',
            expires: new Date(Date.now() + response.data.duration * 1000),
            sameSite: 'lax',
          });

          cookies.set('tomei-logistics-userID', response.data.user_info.id, {
            path: '/',
            expires: new Date(Date.now() + response.data.duration * 1000),
            sameSite: 'lax',
          });

          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push('/');
          }
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: 'Failed to authenticate user.',
            duration: null,
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
          });
          history.push('/login');
        });
    }
  }, [code, toast, history, redirectUrl]);

  if (code) {
    return null;
  }

  return (
    <Flex
      direction={{
        lg: 'row',
        base: 'column',
      }}
      height={{
        lg: '100vh',
        base: 'auto',
      }}
    >
      <Flex
        direction="column"
        justify="flex-start"
        alignItems="center"
        height={{
          lg: '100%',
          base: 300,
        }}
        margin={{
          base: 4,
          lg: 0,
        }}
        style={{
          flexGrow: 1,
        }}
      >
        <Image
          src={`${window.location.origin}/login.png`}
          pt={{
            base: 0,
            lg: 10,
          }}
          pl={{
            base: 0,
            lg: 10,
          }}
          pb={{
            base: 0,
            lg: 10,
          }}
          maxHeight="100%"
        />
      </Flex>
      <Flex
        mt={10}
        direction="column"
        align="center"
        minWidth={{
          lg: 400,
          xl: 600,
        }}
      >
        <Center>
          <Image
            src={`${window.location.origin}/logo.png`}
            alt="Tomei"
            w="40%"
          />
        </Center>
        <Center>
          <Heading
            size="md"
            color="gray.500"
            fontFamily="cairo"
            letterSpacing="0.18em"
            mt={1}
            fontWeight="600"
          >
            Logistic System
          </Heading>
        </Center>
        <Center mt={20}>
          <Heading size="lg" mb={2} fontWeight="semibold" textAlign="center">
            Hi there, please log in
          </Heading>
        </Center>
        <Box mt={10} mx={20}>
          <Flex align="center" justify="center" direction="column">
            <Button
              backgroundColor="#0b69ff"
              color="white"
              h={8}
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SSO_URL +
                  '?client_id=' +
                  process.env.REACT_APP_SSO_API_KEY +
                  '&redirect_url=' +
                  encodeURIComponent(window.location.href);
              }}
            >
              Continue with Tomei SSO
            </Button>
          </Flex>
        </Box>

        <Box>
          <Center>
            <Text
              color="gray.400"
              fontSize="sm"
              position="initial"
              bottom="20%"
              marginTop="50px"
            >
              ©2021 All Rights Reserved. Tomei Group.
            </Text>
          </Center>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
