import { createContextStore, action } from "easy-peasy";

const UserStore = createContextStore({
  id: 0,
  staffId: "",
  name: "",
  contact: "",
  roles: [],
  building: "",
  department: "",
  fillUserInfo: action((state, payload) => {
    state.id = payload.staff.id;
    state.staffId = payload.staff.staff_id;
    state.name = payload.staff.full_name;
    state.preferred_name = payload.staff.preferred_name;
    state.contact = payload.staff.mobile;
    state.roles = payload.logistics_roles.map((role) =>
      role.Name.toLowerCase()
    );
    state.building = payload.building;
    state.department = payload.department;
  }),
});

export default UserStore;
