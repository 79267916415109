import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Image,
  Input,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from '../Component/DateRange';
import ConsignmentListStore from '../Store/ConsignmentListStore';
import {
  getDepartments,
  getOutlets,
} from '../AddressBook/Helper/RetreiveDataHelper';
import moment from 'moment';
import UserStore from '../Store/UserStore';
import { checkHasRoles } from '../utils';
import { Select as MultiSelect } from 'chakra-react-select';
import _ from 'lodash';
import './index.css';

import {
  Form as AntForm,
  Select as AntSelect,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Input as AntInput,
  Space,
} from 'antd';

const statusOptions = [
  { name: 'Consignment Created', value: 'Created' },
  { name: 'Consignment Cancelled', value: 'Cancelled' },
  { name: 'Shipping Label Printed', value: 'Printed' },
  { name: 'Arrived at Sorting Center', value: 'Arrived' },
  { name: 'Out for Delivery', value: 'Delivery' },
  { name: 'In Transit to Sorting Center', value: 'Transit' },
  { name: 'Item Delivered', value: 'Delivered' },
  { name: 'Item Received', value: 'Received' },
];

const sortOptions = [
  { name: 'Tracking No', value: 'TrackingNo' },
  { name: 'Sender Address Code', value: 'SenderAddressCode' },
  { name: 'Receiver Address Code', value: 'ReceiverAddressCode' },
  { name: 'Created Date', value: 'CreatedDate' },
  { name: 'Sender', value: 'SenderName' },
  { name: 'Recipient', value: 'ReceiverName' },
  { name: 'Performed By', value: 'PerformedByFullName' },
  { name: 'Status', value: 'Status' },
  { name: 'Department Ref No.', value: 'DepartmentRefNo' },
  { name: 'Pronto Ref. No.', value: 'ProntoTransferNo' },
];

const SearchFilter = (props) => {
  const { setSearchParams, focus, setFocus, gotoPage } = props;
  const MIN_BARCODE_LENGTH = 8;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveSearchFilter = ConsignmentListStore.useStoreActions(
    (actions) => actions.saveSearchFilter
  );

  const { roles } = UserStore.useStoreState((state) => state);
  const userBuilding = UserStore.useStoreState((state) => state.building);
  const userDepartment = UserStore.useStoreState((state) => state.department);
  const [isLoading, setIsLoading] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [outlet, setOutlet] = useState('');
  const [outletOptions, setOutletOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [department, setDepartment] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [status, setStatus] = useState('');
  const [activityStatus, setActivityStatus] = useState('');
  const [performedBy, setPerformedBy] = useState('');
  const [inboundConsignment, setInboundConsignment] = useState(true);
  const [outboundConsignment, setOutboundConsignment] = useState(true);
  const [departmentRefNo, setDepartmentRefNo] = useState('');
  const [prontoRefNo, setProntoRefNo] = useState('');
  const [sortOption, setSortOption] = useState('');

  const [antdOutlets, setAntdOutlets] = useState([]);
  const [antdWarehouses, setAntdWarehouses] = useState([]);

  let twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const [createdDateRange, onCreatedDateRange] = useState([
    twoWeeksAgo,
    new Date(),
  ]);
  const [historyDateRange, onHistoryDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    if (
      inputValue.length >= MIN_BARCODE_LENGTH &&
      inputValue.length > barcode.length
    ) {
      setTimeout(() => {
        onClose();
      }, 2);
    }
    setBarcode(inputValue);
  };

  const selectByState = (state) => {
    const selected = outletOptions.find((outlet) => outlet.label === state);
    setOutlet([...outlet, ...selected.options]);
  };

  const formatGroupLabel = (outletOptions) => (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => selectByState(outletOptions.label)}
    >
      <span>{outletOptions?.label}</span>
    </div>
  );

  const setOptions = async () => {
    if (roles) {
      if (checkHasRoles(roles, ['own'])) {
        setIsLoading(true);
        const outletData = await (
          await getOutlets()
        ).mappedResponse.filter(
          (outlet) =>
            outlet.addressCode !== 'HQ' &&
            outlet.addressCode !== 'FS-HQ' &&
            outlet.status?.toLowerCase() !== 'ceased'
        );
        const listWarehouse = outletData.filter(
          (outlet) => outlet.building_type === 'Warehouse'
        );

        const listOutlets = _.xor(outletData, listWarehouse);

        const listing = (list) => {
          return _.uniqBy(list, 'state').map((building) => {
            return {
              label: building.state,
              options: list
                .filter((outlet) => building.state === outlet.state)
                .map((option) => {
                  return {
                    label: `${option.addressCode} - ${option.departmentOrOutlet}`,
                    value: option.addressCode,
                  };
                }),
            };
          });
        };

        const antdList = (list) => {
          return _.uniqBy(list, 'state').map((building) => (
            <OptGroup label={building.state}>
              {list
                .filter((outlet) => building.state === outlet.state)
                .map((option) => (
                  <Option key={option.addressCode} value={option.addressCode}>
                    {`${option.addressCode} - ${option.departmentOrOutlet}`}
                  </Option>
                ))}
            </OptGroup>
          ));
        };

        setAntdOutlets(antdList(listOutlets));
        setAntdWarehouses(antdList(listWarehouse));

        setOutletOptions(listing(listOutlets));
        setWarehouseOptions(listing(listWarehouse));
        setDepartmentOptions(
          await (
            await getDepartments(
              false,
              { status: ['active', 'inactive'] },
              true
            )
          ).renderedValues
        );
        setIsLoading(false);
      } else if (checkHasRoles(roles, ['department/outlet'])) {
        setOutletOptions([
          <option key={userBuilding.code} value={userBuilding.code}>
            {userBuilding.name}
          </option>,
        ]);
        setDepartmentOptions([
          // <option key={userDepartment.code} value={userDepartment.code}>
          //   {userDepartment.name}
          // </option>,
          <Option key={userDepartment.code} value={userDepartment.code}>
            {userDepartment.name}
          </Option>,
        ]);
      } else {
        setOutletOptions([]);
        setDepartmentOptions([]);
      }
    }
  };

  const onReset = () => {
    setBarcode('');
    setDepartment('');
    setOutlet('');
    setStatus('');
    setActivityStatus('');
    setPerformedBy('');
    onCreatedDateRange([twoWeeksAgo, new Date()]);
    onHistoryDateRange([new Date(), new Date()]);
    setDepartmentRefNo('');
    setProntoRefNo('');
    setSortOption('');
    setInboundConsignment(true);
    setOutboundConsignment(true);
  };

  const setInputValues = (values) => {
    setBarcode(values.TrackingNo);
    setPerformedBy(values.performedBy);
    setDepartmentRefNo(values.DepartmentRefNo);
    setProntoRefNo(values.ProntoRefNo);
  };

  const searchData = async (values) => {
    const params = {};
    const searchFilters = {
      createdDateRange: ['', ''],
      department: '',
      outlet: '',
      createdStatus: '',
      historyDateRange: ['', ''],
      historyStatus: '',
      createdtrackingNo: '',
      performedBy: '',
      departmentRefNo: '',
      prontoRefNo: '',
    };

    if (values.TrackingNo?.length > 0) {
      params.TrackingNo = values.TrackingNo;
    }
    if (values.Department?.length > 0 && values.Department !== 'all') {
      params.Department = values.Department;
      const departments = [];
      departmentOptions.forEach((department) => {
        departments[department.props.value] = department.props.children;
      });
      searchFilters.department = departments[values.Department];
    }
    if (values.Outlet?.length > 0) {
      const val = _.values(_.mapValues(values.Outlet, 'value'));

      if (!val.includes('all')) {
        params.Outlet = values.Outlet.toString();
        searchFilters.outlet = values.Outlet.toString();
      }
    }
    if (values.Status?.length > 0 && values.Status !== 'all') {
      params.Status = values.Status;
      searchFilters.status = statusOptions.find(
        ({ value }) => value === values.Status
      )?.name;
    }
    if (values.ActivityStatus?.length > 0 && values.ActivityStatus !== 'all') {
      params.ActivityType = values.ActivityStatus;
      searchFilters.activityStatus = statusOptions.find(
        ({ value }) => value === values.ActivityStatus
      )?.name;
    }
    if (values.performedBy?.length > 0) {
      params.PerformedByFullName = values.performedBy;
    }
    if (values.DepartmentRefNo?.length > 0) {
      params.DepartmentRefNo = values.DepartmentRefNo;
    }
    if (values.ProntoRefNo?.length > 0) {
      params.ProntoTransferNo = values.ProntoRefNo;
    }

    if (values.createdDateRange) {
      params.CreatedDateStart = moment(values.createdDateRange[0]).format(
        'YYYY-MM-DD'
      );
      params.CreatedDateEnd = moment(values.createdDateRange[1]).format(
        'YYYY-MM-DD'
      );
      searchFilters.createdDateRange = [
        moment(values.createdDateRange[0]).format('YYYY-MM-DD'),
        moment(values.createdDateRange[1]).format('YYYY-MM-DD'),
      ];
    }

    if (values.historyDateRange) {
      params.ActivityDateStart = moment(values.historyDateRange[0]).format(
        'YYYY-MM-DD'
      );
      params.ActivityDateEnd = moment(values.historyDateRange[1]).format(
        'YYYY-MM-DD'
      );
      searchFilters.historyDateRange = [
        moment(values.historyDateRange[0]).format('YYYY-MM-DD'),
        moment(values.historyDateRange[1]).format('YYYY-MM-DD'),
      ];
    }

    if (values.InboundConsignment && values.OutboundConsignment) {
      params.Direction = null;
    } else if (values.InboundConsignment) {
      params.Direction = 'Inbound';
    } else if (values.OutboundConsignment) {
      params.Direction = 'Outbound';
    }

    if (values.SortOption?.length > 0 && values.SortOption !== 'all') {
      params.SortOption = values.SortOption;
      searchFilters.sortOption = values.SortOption;
    }

    saveSearchFilter(searchFilters);
    setSearchParams(params);
    gotoPage(0);
  };

  useEffect(() => {
    setOptions();
  }, [userBuilding, userDepartment]);

  // ANTD form
  const [antForm] = AntForm.useForm();
  const { Option, OptGroup } = AntSelect;
  const { RangePicker } = DatePicker;

  return (
    <>
      <Accordion
        allowToggle
        bg="#fafafa"
        my={4}
        borderRadius="10px"
        color="grey"
        defaultIndex={[0]}
      >
        <AccordionItem border="none">
          <AccordionButton p={4}>
            <Box flex="1" textAlign="left">
              SEARCH FILTERS
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {isLoading ? (
              <Box fontSize="lg" flex="1" textAlign="center" marginY={8}>
                <Spinner size="lg" />
              </Box>
            ) : (
              <AntForm
                form={antForm}
                name="control-ref"
                layout="vertical"
                onFinish={(values) => searchData(values)}
                initialValues={{
                  InboundConsignment: true,
                  OutboundConsignment: true,
                  createdDateRange: [moment().subtract(1, 'months'), moment()],
                  historyDateRange: [moment().subtract(1, 'months'), moment()],
                }}
              >
                <Row gutter={9}>
                  <Col>
                    <AntForm.Item name="TrackingNo">
                      <AntInput
                        placeholder="Tracking No."
                        style={{
                          width: 180,
                          paddingBottom: '20',
                        }}
                        suffix={
                          <FontAwesomeIcon icon={faBarcode} color="#6e26c7" />
                        }
                      ></AntInput>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="Department">
                      <AntSelect
                        placeholder="Department"
                        style={{ width: 310 }}
                      >
                        {departmentOptions}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="Outlet">
                      <AntSelect
                        placeholder="Outlet/Warehouse"
                        style={{ width: 180 }}
                        mode="multiple"
                        dropdownMatchSelectWidth={false}
                        showArrow
                      >
                        <Option
                          style={{
                            fontWeight: '600',
                            textAlign: 'center',
                            backgroundColor: '#FAFAFA',
                          }}
                          disabled
                        >
                          OUTLETS
                        </Option>
                        {antdOutlets}
                        <Option
                          style={{
                            fontWeight: '600',
                            textAlign: 'center',
                            backgroundColor: '#FAFAFA',
                          }}
                          disabled
                        >
                          WAREHOUSES
                        </Option>
                        {antdWarehouses}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="Status">
                      <AntSelect placeholder="Status" style={{ width: 200 }}>
                        <Option value="all">All</Option>
                        {statusOptions.map(({ name, value }) => (
                          <Option key={value} value={value}>
                            {name}
                          </Option>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="ActivityStatus">
                      <AntSelect
                        placeholder="Activity Status"
                        style={{ width: 200 }}
                      >
                        <Option value="all">All</Option>
                        {statusOptions.map(({ name, value }) => (
                          <Option key={value} value={value}>
                            {name}
                          </Option>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="performedBy">
                      <AntInput
                        placeholder="Performed By Name"
                        style={{
                          width: 180,
                          paddingBottom: '20',
                        }}
                      ></AntInput>
                    </AntForm.Item>
                  </Col>
                </Row>

                <Row gutter={10}>
                  <Col>
                    <AntForm.Item
                      name="createdDateRange"
                      label="Created Date Range"
                    >
                      <RangePicker style={{ width: 250 }} />
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item
                      name="historyDateRange"
                      label="Historical Date Range"
                    >
                      <RangePicker style={{ width: 250 }} />
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="DepartmentRefNo">
                      <AntInput
                        placeholder="Department Ref No"
                        style={{
                          width: 180,
                          paddingBottom: '20',
                          marginTop: '30px',
                        }}
                      ></AntInput>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="ProntoRefNo">
                      <AntInput
                        placeholder="Pronto Ref No"
                        style={{
                          width: 180,

                          paddingBottom: '20',
                          marginTop: '30px',
                        }}
                      ></AntInput>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <AntForm.Item name="SortOption">
                      <AntSelect
                        placeholder="Sort Option"
                        style={{
                          width: 190,
                          marginTop: '30px',
                        }}
                      >
                        <Option value="all">All</Option>
                        {sortOptions.map(({ name, value }) => (
                          <Option key={value} value={value}>
                            {name}
                          </Option>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>

                  <Col>
                    <Button
                      mr={2}
                      mt={7}
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="reset"
                      height="34px"
                    >
                      <CloseIcon />
                    </Button>
                    <Button
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="submit"
                      mt={7}
                      height="34px"
                    >
                      <Search2Icon />
                    </Button>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col style={{ marginTop: '5px' }}>
                    Show only consignments that are:{' '}
                  </Col>
                  <Col>
                    <AntForm.Item
                      name="InboundConsignment"
                      valuePropName="checked"
                    >
                      <Checkbox>Inbound</Checkbox>
                    </AntForm.Item>
                  </Col>
                  <Col>
                    <AntForm.Item
                      name="OutboundConsignment"
                      valuePropName="checked"
                      defaultValue="true"
                    >
                      <Checkbox checked={true}>Outbound</Checkbox>
                    </AntForm.Item>
                  </Col>
                </Row>
              </AntForm>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* OLD SEARCH FILTER FORM */}
      {/* <Accordion
        allowToggle
        bg="#fafafa"
        my={4}
        borderRadius="10px"
        color="grey"
        defaultIndex={[0]}
      >
        <AccordionItem border="none">
          <AccordionButton p={4}>
            <Box flex="1" textAlign="left">
              SEARCH FILTERS
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {isLoading ? (
              <Box fontSize="lg" flex="1" textAlign="center" marginY={8}>
                <Spinner size="lg" />
              </Box>
            ) : (
              <Formik
                initialValues={{
                  TrackingNo: barcode,
                  Department: department,
                  Outlet: outlet,
                  Status: status,
                  performedBy: performedBy,
                  createdDateRange: createdDateRange,
                  historyDateRange: historyDateRange,
                  ActivityStatus: activityStatus,
                  SortOption: sortOption,
                  DepartmentRefNo: departmentRefNo,
                  ProntoRefNo: prontoRefNo,
                  InboundConsignment: inboundConsignment,
                  OutboundConsignment: outboundConsignment,
                }}
                enableReinitialize
                onReset={(values) => {
                  onReset();
                  searchData(values);
                }}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  setInputValues(values);
                  searchData(values);
                }}
              >
                {(props) => (
                  <Form>
                    <Grid templateColumns="repeat(6, 1fr)" gap={2} mb={2}>
                      <Box>
                        <Field name="TrackingNo">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.TrackingNo &&
                                form.touched.TrackingNo
                              }
                            >
                              <Input
                                {...field}
                                id="TrackingNo"
                                placeholder="Tracking No."
                                d="inline"
                              />
                              <InputRightElement
                                pr={2}
                                children={<FontAwesomeIcon icon={faBarcode} />}
                                h="100%"
                                color="#6e26c7"
                                onClick={() => {
                                  setBarcode('');
                                  onOpen();
                                }}
                              />
                              <FormErrorMessage>
                                {form.errors.trackingNo}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="Department">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.Department &&
                                form.touched.Department
                              }
                            >
                              <Select
                                {...field}
                                onChange={(e) => setDepartment(e.target.value)}
                              >
                                <option value="" disabled hidden>
                                  Department
                                </option>
                                <option value="all">All</option>
                                {departmentOptions}
                              </Select>

                              <FormErrorMessage>
                                {form.errors.Department}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="Outlet">
                          {({ field, form }) => (
                            <FormControl onClick={() => setFocus(true)}>
                              <MultiSelect
                                {...field}
                                isMulti
                                onChange={(value) => {
                                  setFocus(true);
                                  setOutlet(value);
                                }}
                                value={outlet}
                                placeholder="Outlet/Warehouse"
                                menuIsOpen={focus}
                                formatGroupLabel={formatGroupLabel}
                                options={[
                                  { label: 'All', value: 'all' },
                                  {
                                    label: 'Outlets',
                                    value: 'Outlets',
                                    isDisabled: true,
                                  },
                                  ...outletOptions,
                                  {
                                    label: '',
                                    value: '',
                                    isDisabled: true,
                                  },
                                  {
                                    label: 'Warehouses',
                                    value: 'Warehouses',
                                    isDisabled: true,
                                  },

                                  ...warehouseOptions,
                                ]}
                              />
                              <FormErrorMessage>
                                {form.errors.Outlet}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="Status">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.Status && form.touched.Status
                              }
                            >
                              <Select
                                {...field}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="" disabled hidden>
                                  Status
                                </option>
                                <option value="all">All</option>
                                {statusOptions.map(({ name, value }) => (
                                  <option key={value} value={value}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.createdStatus}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="ActivityStatus">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.ActivityStatus &&
                                form.touched.ActivityStatus
                              }
                            >
                              <Select
                                {...field}
                                onChange={(e) =>
                                  setActivityStatus(e.target.value)
                                }
                              >
                                <option value="" disabled hidden>
                                  Activity Status
                                </option>
                                <option value="all">All</option>
                                {statusOptions.map(({ name, value }) => (
                                  <option key={value} value={value}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.ActivityStatus}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="performedBy">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.performedBy &&
                                form.touched.performedBy
                              }
                            >
                              <Input
                                {...field}
                                id="performedBy"
                                placeholder="Performed By Name"
                              />
                              <FormErrorMessage>
                                {form.errors.performedBy}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                    </Grid>
                    <Flex mb={2}>
                      <Box mr={5}>
                        <FormControl>
                          <Box h="100%">
                            <Text display="inline" mr={2}>
                              Created Date Range
                            </Text>

                            <DateRange
                              value={createdDateRange}
                              onChange={onCreatedDateRange}
                              name="createdDateRange"
                            />
                          </Box>
                        </FormControl>
                      </Box>
                      <Box mr={2}>
                        <FormControl>
                          <Box h="100%">
                            <Text display="inline" mr={2}>
                              Historical Date Range
                            </Text>

                            <DateRange
                              value={historyDateRange}
                              onChange={onHistoryDateRange}
                              name="historyDateRange"
                            />
                          </Box>
                        </FormControl>
                      </Box>
                      <Box mr={2}>
                        <Field name="DepartmentRefNo">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.DepartmentRefNo &&
                                form.touched.DepartmentRefNo
                              }
                            >
                              <Input
                                {...field}
                                id="DepartmentRefNo"
                                placeholder="Department Ref No"
                              />
                              <FormErrorMessage>
                                {form.errors.DepartmentRefNo}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>

                      <Box mr={2}>
                        <Field name="ProntoRefNo">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.ProntoRefNo &&
                                form.touched.ProntoRefNo
                              }
                            >
                              <Input
                                {...field}
                                id="ProntoRefNo"
                                placeholder="Pronto Ref No"
                              />
                              <FormErrorMessage>
                                {form.errors.ProntoRefNo}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>

                      <Box mr={2}>
                        <Field name="SortOption">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.SortOption &&
                                form.touched.SortOption
                              }
                            >
                              <Select
                                {...field}
                                onChange={(e) => setSortOption(e.target.value)}
                              >
                                <option value="" disabled hidden>
                                  Sort Option
                                </option>
                                <option value="all">All</option>
                                {sortOptions.map(({ name, value }) => (
                                  <option key={value} value={value}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.SortOption}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>

                      <Spacer />

                      <Button
                        mr={2}
                        colorScheme="gray"
                        isLoading={props.isSubmitting}
                        type="reset"
                      >
                        <CloseIcon />
                      </Button>
                      <Button
                        colorScheme="gray"
                        isLoading={props.isSubmitting}
                        type="submit"
                      >
                        <Search2Icon />
                      </Button>
                    </Flex>
                    <Box>
                      <Text display="inline">
                        Show only consignments that are:
                      </Text>
                      <Stack
                        ml={2}
                        spacing={5}
                        direction="row"
                        display="inline"
                      >
                        <Field name="InboundConsignment">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={inboundConsignment}
                              onChange={(e) => {
                                setInboundConsignment(e.target.checked);
                              }}
                            >
                              Inbound
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="OutboundConsignment">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={outboundConsignment}
                              onChange={(e) => {
                                setOutboundConsignment(e.target.checked);
                              }}
                            >
                              Outbound
                            </Checkbox>
                          )}
                        </Field>
                      </Stack>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalCloseButton />
          <ModalBody fontFamily="Arial">
            <Image src={`${window.location.origin}/scan.gif`} />
            <Center fontSize="xl">Align consignment tracking</Center>
            <Center fontSize="xl">barcode within frame to scan</Center>
            <Input
              autoFocus={true}
              value={barcode}
              onChange={handleInputChange}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchFilter;
