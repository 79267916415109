import React, { useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Table, Tag } from 'antd';
import ViewConsignment from '../../Consignment/ViewConsignment';

function ConsignmentPending({ id, params, fetchData }) {
  const [consignments, setConsignments] = useState([]);

  useEffect(() => {
    (async () => {
      if (id) {
        const consignmentsPending = await fetchData({
          ...params,
          Direction: 'Inbound',
        });

        consignmentsPending.forEach((consignment) => {
          consignment.key = consignment.TrackingNo;
        });
        setConsignments(consignmentsPending);
      }
    })();
  }, [id]);

  const columns = [
    {
      title: 'Tracking No',
      dataIndex: 'TrackingNo',
      key: 'Tracking No',
      render: (trackingNo) => (
        <ViewConsignment
          fetchData={() => fetchData(trackingNo)}
          trackingNo={trackingNo}
        />
      ),
    },
    {
      title: 'Sender Outlet/Department',
      dataIndex: 'SenderAddressCode',
      key: 'SenderDepartmentOrOutlet',
      render: (text, record) => {
        if (record.SenderAddressCode === 'HQ') {
          return record.SenderDepartmentCode;
        } else {
          return record.SenderAddressCode;
        }
      },
    },
    {
      title: 'Pronto Ref. No.',
      dataIndex: 'ProntoTransferNo',
      key: 'ProntoTransferNo',
      render: (text) => {
        return text || 'N/A';
      },
    },
    {
      title: 'Department Ref. No.',
      dataIndex: 'DepartmentRefNo',
      key: 'DepartmentRefNo',
      render: (text) => {
        return text || 'N/A';
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (Status) => {
        let props = {
          Created: {
            bg: '#0000FF',
            color: 'white',
            text: 'Consignment Created',
          },
          Arrived: {
            bg: '#FFD1FF',
            color: '#800080',
            text: 'Arrived at Sorting Center',
          },
          Printed: {
            bg: '#D9DCFF',
            color: '#00085E',
            text: 'Shipping Label Printed',
          },
          Delivery: {
            bg: '#FFF5E1',
            color: '#FFA500',
            text: 'Out For Delivery',
          },
          Transit: {
            bg: '#FFDEEF',
            color: '#E60076',
            text: 'In Transit To Sorting Center',
          },
          Received: {
            bg: '#FFDEEF',
            color: '#E60076',
            text: 'The item has been delivered',
          },
        };

        return (
          <>
            <Tag
              color={props[Status].bg}
              key={Status}
              style={{
                width: '230px',
                height: '28px',
                borderRadius: '5px',
                color: props[Status].color,
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props[Status].text}
            </Tag>
          </>
        );
      },
    },
  ];
  return (
    <Flex w="84vw" flexDirection={'column'} gridGap={5}>
      <Box w="90%" color="black" boxShadow="base" p="5" rounded="md" bg="white">
        <Text fontWeight={600} fontSize="md" mb={5}>
          Consignment Pending Received
        </Text>
        <Table
          dataSource={consignments}
          columns={columns}
          pagination={{
            showTotal: (total, range) =>
              `Displaying records ${range[0]}-${range[1]} of ${total} items`,
            pageSize: 10,
            total: consignments?.length,
            defaultCurrent: 1,
            showSizeChanger: false,
          }}
        />
      </Box>
    </Flex>
  );
}

export default ConsignmentPending;
