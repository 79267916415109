import {
  Button,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  chakra,
  Tooltip,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon, ViewIcon } from '@chakra-ui/icons';
import React from 'react';
import DeleteModal from './DeleteModal';
import ViewAddress from './ViewAddress';
import CreateOrUpdate from './CreateOrUpdate';
import { Pagination } from '../Pagination';

function AddressBookTable(props) {
  const {
    data,
    dataPerPage,
    state,
    headerGroups,
    page,
    handleChange,
    pageCount,
    refreshList,
    prepareRow,
  } = props;

  return (
    <>
      <Pagination
        data={data}
        dataPerPage={dataPerPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        handleChange={handleChange}
      />
      <Table variant="unstyled">
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  fontSize="lg"
                  fontWeight="bold"
                  p={1.5}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}>
                  {column.render('Header')}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody fontSize="md">
          {page.map(row => {
            const { values } = row;
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  if (cell.column.Header === 'Code') {
                    return (
                      <Td p={1.5}>
                        <ViewAddress
                          data={values}
                          refreshList={refreshList}
                          renderComponent={(onOpen) => (
                            <Text
                              textAlign="left"
                              height="100%"
                              onClick={onOpen}
                              size="lg"
                              variant="solid"
                              color="#6e26c7"
                              fontWeight="bolder"
                              as="u"
                              cursor="pointer"
                            >
                              {values.AddressCode}
                            </Text>
                          )}
                        />
                      </Td>
                    );
                  } else if (cell.column.Header === 'Action') {
                    return (
                      <Td {...cell.getCellProps()} p={1.5}>
                        <Stack spacing={1} direction="row">
                          <ViewAddress
                            data={values}
                            refreshList={refreshList}
                            renderComponent={(onOpen) => (
                              <Tooltip
                                label="View Address"
                                bg="gray.600"
                                color="white"
                                borderRadius={8}
                                fontSize="md"
                                placement="top"
                                hasArrow>
                                <Button
                                  backgroundColor="blue.100"
                                  borderRadius="14px"
                                  color="blue.700"
                                  onClick={onOpen}
                                  size="sm"
                                  variant="solid">
                                  <ViewIcon />
                                </Button>
                              </Tooltip>
                            )}
                          />
                          <CreateOrUpdate
                            oldData={values}
                            onClose={refreshList}
                          />
                          <DeleteModal
                            id={values.AddressID}
                            trackingNo={values}
                            code={values.AddressCode}
                            onClose={refreshList}
                          />
                        </Stack>
                      </Td>
                    );
                  } else if (cell.column.Header === 'Status') {
                    const statusStyle = {
                      borderRadius: '15px',
                      borderStyle: 'solid',
                      borderWidth: '3px',
                      borderColor: cell.value === 1 ? '#33BB51' : '#EC8F8F',
                      color: cell.value === 1 ? '#33BB51' : '#EC8F8F',
                    };
                    return (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                        textTransform={'capitalize'}
                        p={1.5}>
                        <Text p={1} style={statusStyle} textAlign="center">
                          {cell.value ? 'Active' : 'Inactive'}
                        </Text>
                      </Td>
                    );
                  } else {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                        p={1.5}>
                        <Text>{cell.render('Cell')}</Text>
                      </Td>
                    );
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

export default AddressBookTable;
