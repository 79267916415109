import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ConsignmentListStore from '../Store/ConsignmentListStore';
import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import UserStore from '../Store/UserStore';
import moment from 'moment';

function PrintListingModal({ source, printData }) {
  const componentRef = useRef();
  const [dataUsed, setDataUsed] = useState([]);

  const { name } = UserStore.useStoreState((state) => state);

  const { searchFilters, consignmentList } = ConsignmentListStore.useStoreState(
    (state) => state
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setDataUsed(renderValues());
  }, [printData, consignmentList.data]);

  function renderValues() {
    let dataUsed = [];
    if (source === 'DISPATCH LIST') {
      dataUsed = printData.map((record) => {
        const data = record.values;
        return consignmentList.data.find(
          (consignment) => consignment.trackingNo === data.trackingNo
        );
      });
    } else {
      dataUsed = consignmentList.data;
    }
    return dataUsed.map((record, index) => {
      const {
        trackingNo,
        createdDate,
        sender,
        recipient,
        performedBy,
        status,
        deptRefNo,
        prontoRefNo,
        quantity,
        notes,
      } = record;
      return (
        <Tr key={trackingNo}>
          <Td p={1.5} className="td">
            {index + 1}
          </Td>
          <Td p={1.5} className="td" id="trackingNumber">
            {trackingNo}
          </Td>
          <Td p={1.5} className="td" id="createdDate">
            {createdDate.toLocaleDateString('en-GB')}
          </Td>
          <Td p={1.5} className="td name">
            {sender}
          </Td>
          <Td p={1.5} className="td name">
            {recipient}
          </Td>
          <Td p={1.5} className="td name">
            {performedBy}
          </Td>
          <Td p={1.5} className="td" textTransform={'capitalize'}>
            {status}
          </Td>
          <Td p={1.5} className="td name" id="deptRefNo">
            {deptRefNo}
          </Td>
          <Td p={1.5} className="td name" id="prontoRefNo">
            {prontoRefNo}
          </Td>
          <Td p={1.5} className="td name" id="quantity">
            {quantity}
          </Td>
          <Td p={1.5} className="td name" id="notes">
            {notes}
          </Td>
        </Tr>
      );
    });
  }

  const pageStyle = `
  @media all {
    
    .pagebreak {
      display : none;
    }
    #printer {
      padding: 0px;
      margin: 0px;
    }

    Thead {
      display:table-header-group;
    }

    .thead2 {
      font-size: 11px !important;
      padding: 0.1rem;
      text-align: left !important;
      
    }

    .th {
      display: flex;
      justifyContent: top;
      alignItems: top;
    }
    
    
    
    .td {
      font-size: 10px;
      padding-bottom: 0.1rem;
    }
    
    
  }

  @media print {
    @page { size: landscape; }

    .pagebreak {
      page-break-before: auto;
    }
    .thead { display: table-header-group; }
  }`;

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={source === 'DISPATCH LIST' ? 'blue.500' : 'blue.400'}
        color="white"
        mt={5}
        ml={2}
        px={5}
        size="sm"
        borderRadius="14px"
      >
        <FontAwesomeIcon icon={faPrint} />
        <Text ml={2}>PRINT {source}</Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={5} minW="1400px">
          <ModalCloseButton />
          <ModalBody ref={componentRef} id="printer">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th rowSpan="6" colSpan="3" className="thead2" p={1}>
                    <Heading
                      fontSize="15px"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Consignment Listing
                    </Heading>
                    <Image
                      src={`${window.location.origin}/logo.png`}
                      //w="25"
                      alt="Tomei"
                      mb={1}
                      width="70%"
                    />
                    <Text fontSize="medium" fontWeight="bold" textAlign="left">
                      LOGISTIC SYSTEM
                    </Text>
                  </Th>

                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Department
                    </Heading>
                    {/* <Text fontSize="sm" fontWeight="semibold" textAlign="left" verticalAlign="top" h="35px">
                    {searchFilters.department}
                    </Text> */}
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Outlet
                    </Heading>
                  </Th>
                </Tr>
                <Tr>
                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Text fontSize="sm" fontWeight="semibold" textAlign="left">
                      {searchFilters.department || 'All'}
                    </Text>
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Text fontSize="sm" fontWeight="semibold" textAlign="left">
                      {searchFilters.outlet || 'All'}
                    </Text>
                  </Th>
                </Tr>
                <Tr>
                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Consignment Status
                    </Heading>
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Created Date Range
                    </Heading>
                  </Th>
                </Tr>
                <Tr>
                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Text fontSize="sm" fontWeight="semibold" textAlign="left">
                      {searchFilters.status || 'All'}
                    </Text>
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Text fontSize="sm" fontWeight="semibold" textAlign="left">
                      {searchFilters.createdDateRange[0] +
                        ' - ' +
                        searchFilters.createdDateRange[1]}
                    </Text>
                  </Th>
                </Tr>
                <Tr>
                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Activity Status
                    </Heading>
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Historical Date Range
                    </Heading>
                  </Th>
                </Tr>
                <Tr>
                  <Th colSpan="2" className="thead2" p={1} verticalAlign="top">
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      textAlign="left"
                      h="25px"
                    >
                      {searchFilters.activityStatus || 'All'}
                    </Text>
                  </Th>
                  <Th colSpan="3" className="thead2" p={1} verticalAlign="top">
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      textAlign="left"
                      h="25px"
                    >
                      {searchFilters.historyDateRange[0] +
                        ' - ' +
                        searchFilters.historyDateRange[1]}
                    </Text>
                  </Th>
                </Tr>
                <Tr>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={0.5}
                    className="thead2"
                    width="3%"
                  >
                    No.
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    //w="160px"
                    id="trackingNumber"
                    width="12%"
                  >
                    Tracking No.
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="createdDate"
                    width="11%"
                  >
                    Created Date
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2 name"
                    width="12%"
                  >
                    Sender Name
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2 name"
                    width="12%"
                  >
                    Recipient Name
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2 name"
                    width="12%"
                  >
                    Performed By
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    width="12%"
                  >
                    Status
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="deptRefNo"
                    width="12%"
                  >
                    Dept Ref No.
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="prontoRefNo"
                    width="12%"
                  >
                    Pronto Ref No.
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="quantity"
                    width="8%"
                  >
                    Quantity
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="notes"
                    width="8%"
                  >
                    Notes
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">{dataUsed}</Tbody>
            </Table>
            <Table>
              <Tbody>
                <Tr>
                  <Td textAlign="right">
                    <Text fontSize="small" fontWeight="bold" mr={3}>
                      Date: {new Date().toLocaleDateString('en-GB')} Time:{' '}
                      {moment().format('hh:mm:ss A')} User: {name}
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => (
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              pageStyle={pageStyle}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrintListingModal;
