import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function ChartSection({ series, isLoading }) {
  const [options] = useState({
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        endingShape: 'rounded',
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: 'Loading...',
    },
    colors: ['#0000FF', '#00085E', '#800080', '#FFA500', '#E60076'],
    legend: {
      position: 'right',
      offsetX: -30,
      offsetY: 12,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        '1 - 7 Days',
        '8 - 14 Days',
        '15 - 21 Days',
        '22 - 28 Days',
        'More than 28 Days',
      ],
    },
    fill: {
      opacity: 1,
      colors: ['#0000FF', '#00085E', '#800080', '#FFA500', '#E60076'],
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} consignments`,
      },
    },
  });

  return (
    <Chart
      options={options}
      series={isLoading ? [] : series}
      height={210}
      type="bar"
    />
  );
}

export default ChartSection;
