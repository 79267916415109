import axios from 'axios';
import React from 'react';
import { Box, Text, Flex, Spacer } from '@chakra-ui/react';
import TotalConsignment from './total-consignment';
import ConsignmentAging from './consignment-aging';
import ConsignmentPending from './consignment-pending';
import './style.css';
import UserStore from '../Store/UserStore';

function Dashboard2() {
  const { id, building, department } = UserStore.useStoreState(
    (state) => state
  );

  let params = {
    Status: 'Delivered',
    Outlet: building.code === 'HQ' ? '' : building.code,
    Department: building.code === 'HQ' ? department.code : '',
  };
  if (!params.Outlet) {
    delete params.Outlet;
  }
  if (!params.Department) {
    delete params.Department;
  }

  const fetchConsignmentPending = async (params) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/consignment/get-pending`,
      {
        params,
      }
    );

    return data.data.data;
  };

  return (
    <Box
      ml={'270px'}
      px={10}
      py={5}
      flex="1"
      textAlign="left"
      minH="816px"
      overflowX={'hidden'}
    >
      <Flex>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            Dashboard
          </Text>
          <Text>Dashboard of Logistic Consignment</Text>
          <Flex color="white" mt={10}>
            <TotalConsignment id={id} params={params} />
            <ConsignmentAging
              id={id}
              params={params}
              fetchData={fetchConsignmentPending}
            />
          </Flex>

          <Flex color="white" mt={10}>
            <ConsignmentPending
              id={id}
              params={params}
              fetchData={fetchConsignmentPending}
            />
          </Flex>
        </Box>
        <Spacer />
      </Flex>
    </Box>
  );
}

export default Dashboard2;
