import { Search2Icon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  InputRightElement,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faCalendar } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { DateRange } from "../../Component/DateRange";

const AddressBookSearch = ({setSearchFilters}) => {
  return (
    <Accordion
    allowToggle
    bg="#fafafa"
    p={4}
    m={5}
    borderRadius="25px"
    color="grey"
    >
      <AccordionItem border="none">
        <AccordionButton p={0}>
          <Box flex="1" textAlign="left">
            SEARCH FILTERS
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Formik
            initialValues={{
              code: "",
              type: "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              const params = [];
              if (values.code.length > 0) {
                params.push(["AddressCode", values.code]);
              }
              if (values.type.length > 0) {
              params.push(["AddressType", values.type]);
              }
              setSearchFilters(params);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid templateColumns="repeat(3, 1fr)" gap={3}  maxW="fit-content">
                  <Box>
                    <Field name="code">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.code &&
                            form.touched.code
                          }
                        >
                          <Input
                            {...field}
                            id="code"
                            placeholder="Code"
                          />
                          <FormErrorMessage>
                            {form.errors.code}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="type">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.type &&
                            form.touched.type
                          }
                        >
                          <Select {...field} placeholder="Type">
                              <option value="hq">HQ</option>
                              <option value="branch">Branch</option>
                              <option value="outlet">Outlet</option>
                              <option value="customer">Customer</option>
                              <option value="mall">Mall</option>
                              <option value="others">Others</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.type}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Button
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      <Search2Icon />
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AddressBookSearch;
