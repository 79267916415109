import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import customTheme from './modifiedTheme';
import _ from 'lodash';
import Cookies from 'universal-cookie/es6';

export function createCustomAxios() {
  const toast = createStandaloneToast({ theme: customTheme });
  const instance = axios.create();
  instance.interceptors.request.use(function (config) {
    const logisticToken = new Cookies().get('tomei-logistics-token');
    config.headers.Authorization = `Bearer ${logisticToken}`;
    return config;
  });
  instance.interceptors.response.use(
    function (response) {
      if (response.status >= 500 && response.status <= 600) {
        toast({
          title: 'Failed',
          description: `An error occurred while creating/updating. Please contact admin.`,
          duration: null,
          isClosable: true,
          position: 'bottom-left',
          status: 'error',
        });
      } else {
        return response;
      }
    },
    function (error) {
      if (error.response.status >= 400 && error.response.status < 500) {
        _.forOwn(error.response.data.data, function (value, _) {
          toast({
            title: error.response.data.message,
            description: value,
            duration: null,
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
          });
        });
        if (_.isNull(error.response.data.data) && error.response.data.message) {
          toast({
            title: 'Failed',
            description: error.response.data.message,
            duration: null,
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
          });
        }
      }
      return error.response;
    }
  );

  return instance;
}

export function createCustomAxiosWithoutErrorHandling() {
  const instance = axios.create();
  instance.interceptors.request.use(function (config) {
    const logisticToken = new Cookies().get('tomei-logistics-token');
    config.headers.Authorization = `Bearer ${logisticToken}`;
    return config;
  });
  return instance;
}
