import {
  Box,
  Button,
  Center,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  PopoverArrow,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserStore from '../Store/UserStore';
import { checkHasRoles } from '../utils';

function ParcelWithMePopover() {
  const toast = useToast();
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const roles = UserStore.useStoreState(state => state.roles);

  useEffect(() => {
    async function getParcelData() {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/consignment/parcel-with-me`)
        .then(response => {
          const data = [
            {
              status: 'LOGISTIC ITEM RECEIVED',
              consignments: [],
            },
            {
              status: 'ITEM PICKUP FOR DELIVERY',
              consignments: [],
            },
            {
              status: 'ITEM COLLECTION',
              consignments: [],
            },
          ];

          const consignments = response.data.data;

          consignments.forEach(consignment => {
            switch (consignment.Status) {
              case 'Arrived': {
                data[0].consignments.push(consignment);
                break;
              }

              case 'Delivery': {
                data[1].consignments.push(consignment);
                break;
              }

              case 'Transit': {
                data[2].consignments.push(consignment);
                break;
              }

              default: {
                break;
              }
            }
          });

          setData(data);
        })
        .catch(error => {
          toast({
            title:
              error.response?.data?.message ||
              'Failed to get transaction history',
            duration: null,
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
          });
        });
    }
    if (isOpen) getParcelData();
  }, [isOpen]);

  function renderValues() {
    let totalItems = 0;
    const elements = data.map((record, index) => {
      totalItems += record.consignments.length;
      const message = record.status;

      return (
        <React.Fragment key={record.status}>
          <Heading
            lineHeight="33px"
            size="md"
            fontFamily="Open Sans"
            fontWeight="600">
            {message}
          </Heading>
          <Box textAlign="right" mr={5} mb={1}>
            <Text fontSize="md">
              (Total: {record.consignments.length} items)
            </Text>
          </Box>
          <Box mx={5}>
            <Table
              variant="simple"
              background="white"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              mb={5}>
              <Thead>
                <Tr>
                  <Th
                    fontSize="lg"
                    pr={0}
                    pb={3}
                    pl={5}
                    pt={3}
                    textTransform="capitalize"
                    color="black"
                    w="65px">
                    No.
                  </Th>
                  <Th
                    fontSize="lg"
                    pb={3}
                    pl={0}
                    pr={0}
                    pt={3}
                    textTransform="capitalize"
                    color="black"
                    w="180px">
                    Tracking No.
                  </Th>
                  <Th
                    fontSize="lg"
                    p={1.5}
                    textTransform="capitalize"
                    color="black">
                    Date & Time
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {record.consignments.map((consignment, index) => {
                  const { TrackingNo, ActivityDatetime } = consignment;
                  return (
                    <Tr key={TrackingNo}>
                      <Td fontSize="md" p={2} pl={5}>
                        {index + 1}
                      </Td>
                      <Td
                        fontSize="md"
                        p={2}
                        pl={0}
                        pr={0}
                        minWidth="180px"
                        maxWidth="180px">
                        {TrackingNo}
                      </Td>
                      <Td fontSize="md" p={1.5}>
                        {moment
                          .utc(ActivityDatetime)
                          .local()
                          .format('DD/MM/YYYY hh:mm:ss A')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </React.Fragment>
      );
    });

    return (
      <>
        <Box p={5}>
          <Center mb={5}>
            <Heading fontSize="26px" fontWeight="600">
              TOTAL ITEMS WITH ME: {totalItems} ITEMS
            </Heading>
          </Center>
        </Box>
        {elements}
      </>
    );
  }

  return (
    <Popover arrowSize={25} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          backgroundColor="blue.400"
          leftIcon={<FontAwesomeIcon icon={faBox} />}
          borderRadius={10}
          colorScheme="blue"
          size="md"
          variant="solid"
          disabled={!checkHasRoles(roles, ['parcel with me'])}>
          PARCEL WITH ME
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="#F7F7F7" w="550px">
        <PopoverArrow bg="#F7F7F7" arrowSize={30} />
        <PopoverBody>
          <Box py={10}>{data.length > 0 ? renderValues() : null}</Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ParcelWithMePopover;
