import {
  Box,
  Button,
  Stack,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useDisclosure,
  chakra,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon, ViewIcon } from '@chakra-ui/icons';
import React, { useState, useEffect, useMemo } from 'react';
import { Pagination } from '../Pagination';
import { usePagination, useTable, useSortBy } from 'react-table';
import axios from 'axios';
import SearchFilters from './SearchFilters';
import moment from 'moment';

const DATA_PER_PAGE = 10;

function UpdatedHistoryTable() {
  const toast = useToast();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState({});
  const columns = useMemo(
    () => [
      {
        Header: 'Module Name',
        accessor: 'ModuleName',
      },
      {
        Header: 'Action Type',
        accessor: 'ActionType',
      },
      {
        Header: 'Amended By',
        accessor: 'UpdatedBy',
      },
      {
        Header: 'Amended Date & Time',
        accessor: 'UpdatedAt',
        Cell: ({ cell: { value } }) =>
          value ? moment(value).format('DD/MM/YYYY hh:mm:ss A') : '',
      },
      {
        Header: 'Amended Details',
        accessor: 'UpdateDetails',
        disableSortBy: true,
        Subheaders: [
          'Seq No.',
          'Amended Field Name',
          'Previous Details',
          'Amended Details',
        ],
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    if (!data.rows) return [];
    const newData = [];
    data.rows.forEach(row => {
      const { UpdateDetails } = row;
      if (UpdateDetails && UpdateDetails.length >= 1) {
        UpdateDetails.forEach((updateDetail, index) => {
          if (index === 0) {
            newData.push({ ...row, UpdateDetails: { ...updateDetail, index } });
          } else {
            newData.push({ UpdateDetails: { ...updateDetail, index } });
          }
        });
      } else {
        newData.push({ ...row, UpdateDetails: {} });
      }
    });
    return newData;
  }, [data]);

  const count = useMemo(() => data.count || 0, [data]);

  const pageCount = Math.round(count / DATA_PER_PAGE);

  const { state, gotoPage, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      autoResetPage: false,
      initialState: {
        pageSize: DATA_PER_PAGE,
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (
            rowOneColumn &&
            isNaN(rowOneColumn) &&
            rowTwoColumn &&
            isNaN(rowOneColumn)
          ) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      },
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
  );

  const fetchData = async () => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/api/updates`;
    const params = {
      ...searchFilters,
      rows: DATA_PER_PAGE,
      page: state.pageIndex || 0,
    };
    await axios
      .get(url, {
        params,
      })
      .then(response => {
        setData(response?.data?.data || {});
      })
      .catch(error => {
        console.error(error);
        toast({
          title:
            error.response?.data?.message || 'Failed to get update history',
          duration: null,
          isClosable: true,
          position: 'bottom-left',
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [searchFilters, state.pageIndex]);

  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };

  const renderLoading = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 25,
        flexDirection: 'column',
      }}>
      <Spinner size="lg" marginBottom={2} />
      <Text>Loading...</Text>
    </div>
  );

  const renderTableBody = () => (
    <Tbody fontSize="md">
      {page.map(row => {
        prepareRow(row);
        return (
          <Tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              switch (cell.column.Header) {
                case 'Amended Details':
                  return (
                    <>
                      <Td {...cell.getCellProps()}>
                        {cell.value.index + 1 || 'N/A'}
                      </Td>
                      <Td {...cell.getCellProps()}>
                        {cell.value.UpdatedField || 'N/A'}
                      </Td>
                      <Td {...cell.getCellProps()} maxWidth={250}>
                        {cell.value.PreviousDetails || 'N/A'}
                      </Td>
                      <Td {...cell.getCellProps()} maxWidth={250}>
                        <Text>
                          {cell.value.UpdatedDetails || 'N/A'}
                        </Text>
                      </Td>
                    </>
                  );
                default:
                  return (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      p={1.5}>
                      <Text>{cell.render('Cell')}</Text>
                    </Td>
                  );
              }
            })}
          </Tr>
        );
      })}
    </Tbody>
  );

  return (
    <>
      <SearchFilters
        setSearchFilters={setSearchFilters}
        currentFilters={searchFilters}
      />
      <Pagination
        data={tableData}
        totalCount={count}
        dataPerPage={DATA_PER_PAGE}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        handleChange={handleChange}
      />
      <Table variant="unstyled">
        <Thead>
          <Th colspan={4}></Th>
          <Th colspan={4} fontSize="lg" fontWeight="bold" p={1.5} textAlign="center">
            Amended Details
          </Th>
        </Thead>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                switch (column.Header) {
                  case 'Amended Details':
                    return column.Subheaders.map(subheader => (
                      <Th fontSize="lg" fontWeight="bold" p={1.5}>
                        {subheader}
                      </Th>
                    ));
                  default:
                    return (
                      <Th
                        rowSpan={2}
                        fontSize="lg"
                        fontWeight="bold"
                        p={1.5}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        isNumeric={column.isNumeric}>
                        {column.render('Header')}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    );
                }
              })}
            </Tr>
          ))}
        </Thead>
        {!isLoading && renderTableBody()}
      </Table>
      {isLoading && renderLoading()}
    </>
  );
}

export default UpdatedHistoryTable;
