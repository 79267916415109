import { Box, Button, Flex, Select, Spacer, Text } from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { usePagination as useMaterialPagination } from '@material-ui/lab/Pagination';
import { paginationStyle } from './modifiedTheme';

export function Pagination({
  data,
  dataPerPage,
  pageCount,
  pageIndex,
  handleChange,
  totalCount,
  setPageSize,
}) {
  const classes = paginationStyle();
  const { items } = useMaterialPagination({
    count: pageCount,
    page: pageIndex + 1,
    showFirstButton: true,
    showLastButton: true,
    onChange: handleChange,
  });

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const getText = () => {
    if (totalCount) {
      return (
        <>
          Showing{' '}
          <strong>
            {pageIndex * dataPerPage + 1} -{' '}
            {pageIndex * dataPerPage + data.length}
          </strong>{' '}
          from <strong>{totalCount}</strong> data
        </>
      );
    } else {
      return (
        <>
          Showing{' '}
          <strong>
            {(data.length > 0 ? 1 : 0) +
              (pageIndex > 0 ? pageIndex : 0) * dataPerPage}{' '}
            -{' '}
            {dataPerPage + pageIndex * dataPerPage >= data.length
              ? data.length
              : dataPerPage + pageIndex * dataPerPage}
          </strong>{' '}
          from <strong>{data.length}</strong> data
        </>
      );
    }
  };

  return (
    <Flex>
      <Box p="4" pl={0} fontSize="md">
        {getText()}
      </Box>
      <Box pt={2}>
        <Select size={'sm'} onChange={handlePageSizeChange} value={dataPerPage}>
          {[20, 40, 60, 80, 100, 200, 300].map((item) => (
            <option key={item} value={item}>
              Show {item} records per page
            </option>
          ))}
        </Select>
      </Box>
      <Spacer />
      <Box pt={2}>
        <div>
          <nav>
            <ul className={classes.ul}>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = (
                    <Text
                      px={0.5}
                      borderColor="#C2C2C2"
                      borderTopWidth="1px"
                      borderBottomWidth="1px"
                      as="button"
                      h="100%"
                      cursor="context-menu"
                    >
                      …
                    </Text>
                  );
                } else if (type === 'page') {
                  children = (
                    <Button
                      px={0}
                      background={'clear'}
                      size="sm"
                      color={selected ? 'white' : 'black'}
                      backgroundColor={selected ? 'purple.500' : 'white'}
                      fontSize="lg"
                      borderRadius="0px"
                      borderColor="#C2C2C2"
                      borderTopWidth="1px"
                      borderRightWidth={
                        items[index + 1].type === 'next' ? '1px' : '0px'
                      }
                      borderTopRightRadius={
                        items[index + 1].type === 'next' ? '10px' : '0px'
                      }
                      borderBottomRightRadius={
                        items[index + 1].type === 'next' ? '10px' : '0px'
                      }
                      borderBottomWidth="1px"
                      borderLeftWidth={
                        items[index - 1].type === 'previous' ? '1px' : '0px'
                      }
                      borderTopLeftRadius={
                        items[index - 1].type === 'previous' ? '10px' : '0px'
                      }
                      borderBottomLeftRadius={
                        items[index - 1].type === 'previous' ? '10px' : '0px'
                      }
                      {...item}
                      _focus={{ boxShadow: 'unset' }}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  let element = null;
                  switch (type) {
                    case 'first':
                      element = <ArrowLeftIcon color="purple.500" />;
                      break;
                    case 'previous':
                      element = (
                        <ChevronLeftIcon w={5} h={5} color="purple.500" />
                      );
                      break;
                    case 'next':
                      element = (
                        <ChevronRightIcon w={5} h={5} color="purple.500" />
                      );
                      break;
                    case 'last':
                      element = <ArrowRightIcon color="purple.500" />;
                      break;
                  }
                  children = (
                    <Button
                      border="1px solid #C2C2C2"
                      px={0}
                      mx={0.5}
                      background={'clear'}
                      size="sm"
                      {...item}
                      borderRadius="14px"
                      _focus={{ boxShadow: 'unset' }}
                    >
                      {element}
                    </Button>
                  );
                }

                return <li key={index}>{children}</li>;
              })}
            </ul>
          </nav>
        </div>
      </Box>
    </Flex>
  );
}
