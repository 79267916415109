import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Spinner } from '@chakra-ui/react';

function TotalConsignment({ id, params }) {
  const todayParams = {
    ActivityDateStart: moment().format('YYYY-MM-DD'),
    ActivityDateEnd: moment().format('YYYY-MM-DD'),
  };

  const [isLoadingSent, setIsLoadingSent] = useState(false);
  const [isLoadingReceived, setIsLoadingReceived] = useState(false);

  const [totalSent, setTotalSent] = useState(0);
  const [todaySent, setTodaySent] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [todayReceived, setTodayReceived] = useState(0);

  const fetchConsignmentsData = async (params) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/consignment/get-all`,
      {
        params,
      }
    );

    return data.data;
  };

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoadingSent(true);
        setIsLoadingReceived(true);

        const totalSent = await fetchConsignmentsData({
          ...params,
          Direction: 'Outbound',
        });
        const totalReceived = await fetchConsignmentsData({
          ...params,
          Direction: 'Inbound',
        });

        setTotalSent(totalSent.count);
        setIsLoadingSent(false);

        setTotalReceived(totalReceived.count);
        setIsLoadingReceived(false);

        const todaysSent = await fetchConsignmentsData({
          ...params,
          ...todayParams,
          Direction: 'Outbound',
        });

        const todaysReceived = await fetchConsignmentsData({
          ...params,
          ...todayParams,
          Direction: 'Inbound',
        });

        setTodaySent(todaysSent.count);
        setTodayReceived(todaysReceived.count);
      }
    })();
  }, [id]);

  const renderLoading = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Spinner size="md" />
    </div>
  );

  return (
    <>
      <Flex w="17vw" flexDirection={'column'} gridGap={5}>
        <Box
          w="90%"
          p={4}
          color="black"
          boxShadow="base"
          // p="5"
          rounded="md"
          bg="white"
        >
          <Text fontWeight={600} fontSize="md">
            Total Consignment Sent
          </Text>
          <Box
            fontWeight={600}
            fontSize={23}
            color="blue.500"
            pt={5}
            pb={4}
            borderBottom="1px"
            borderColor="gray.200"
            height={70}
          >
            {isLoadingSent ? renderLoading() : totalSent ? totalSent : 0}
          </Box>
          <Text fontWeight={300} mt={4} fontSize="sm" color={'gray.600'}>
            Today's Consignment Sent ({todaySent ? todaySent : 0})
          </Text>
        </Box>

        <Box
          w="90%"
          p={4}
          color="black"
          boxShadow="base"
          // p="5"
          rounded="md"
          bg="white"
        >
          <Text fontWeight={600} fontSize="md">
            Total Consignment Received
          </Text>
          <Box
            fontWeight={600}
            fontSize={23}
            color="green.600"
            pt={5}
            pb={4}
            borderBottom="1px"
            borderColor="gray.200"
            height={70}
          >
            {isLoadingReceived
              ? renderLoading()
              : totalReceived
              ? totalReceived
              : 0}
          </Box>
          <Text fontWeight={300} mt={4} fontSize="sm" color={'gray.600'}>
            Today's Consignment Received ({todayReceived ? todayReceived : 0})
          </Text>
        </Box>
      </Flex>
    </>
  );
}

export default TotalConsignment;
