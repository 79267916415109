import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import AddressContextStore from '../Store/AddressContextStore';
import _ from 'lodash';
import AddressForm from './AddressForm';

const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Kuala Lumpur',
  'Labuan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Putrajaya',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terrenganu',
  'Others',
];

function ConsignmentAddressForm(props) {
  const {
    setSelectedDepartment,
    setSelectedOutlet,
    addressSource,
    setAddressSource,
    setReceiverContact,
    receiverContact,
    setSenderContact,
    senderContact,
    isPronto,
    loadId,
    isLoadingOutlet,
    isLoadingDepartment,
  } = props;

  const addressStore = AddressContextStore.useStoreState((state) => state);
  const addressIndex =
    addressStore.isSenderFilled || loadId === 2 ? 'receiver' : 'sender';
  const { saveDetail } = AddressContextStore.useStoreActions(
    (actions) => actions
  );

  const getState = (state) => {
    if (state !== '') {
      if (STATES.includes(state)) {
        return state;
      } else {
        return 'Others';
      }
    }
    return state;
  };

  const getOtherState = (state) => {
    if (state !== '') {
      if (STATES.includes(state)) {
        return '';
      } else {
        return state;
      }
    }
    return '';
  };

  const name = useMemo(
    () =>
      addressIndex === 'receiver' && isPronto
        ? receiverContact.name
        : addressIndex
        ? _.get(addressStore, `${addressIndex}.name`)
        : '',
    [addressIndex, isPronto, addressStore]
  );

  return (
    <>
      <Formik
        initialValues={{
          name: name,
          contact:
            _.get(addressStore, `${addressIndex}.contact`) ||
            _.get(addressStore, `${addressIndex}.buildingPhone`),
          addressCode: _.get(addressStore, `${addressIndex}.addressCode`),
          departmentCode: _.get(addressStore, `${addressIndex}.departmentCode`),
          departmentOrOutlet: _.get(
            addressStore,
            `${addressIndex}.departmentOrOutlet`
          ),
          address: _.get(addressStore, `${addressIndex}.address`),
          city: _.get(addressStore, `${addressIndex}.city`),
          state: getState(_.get(addressStore, `${addressIndex}.state`)),
          otherState: getOtherState(
            _.get(addressStore, `${addressIndex}.state`)
          ),
          postcode: _.get(addressStore, `${addressIndex}.postcode`),
          country: _.get(addressStore, `${addressIndex}.country`),
          hasBuildingCode: false,
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.state === 'Others') {
            values.state = values.otherState;
          }
          delete values.otherState;
          values.addressSource = addressSource;
          saveDetail(values);
          setSubmitting(false);
          resetForm();
          setAddressSource('manual');
          setSelectedOutlet('');
          setSelectedDepartment('');
        }}
      >
        {(props) => (
          <Form>
            {isPronto ? (
              <>
                <AddressForm
                  loadId={loadId}
                  setReceiverContact={setReceiverContact}
                  receiverContact={receiverContact}
                  setSenderContact={setSenderContact}
                  senderContact={senderContact}
                  isPronto={isPronto}
                />
              </>
            ) : (
              <>
                <ModalBody pb={6}>
                  <AddressForm props={props} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    type="submit"
                    py={4}
                    px={10}
                    borderRadius={10}
                    disabled={isLoadingOutlet || isLoadingDepartment}
                  >
                    NEXT
                    <ArrowForwardIcon />
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ConsignmentAddressForm;
