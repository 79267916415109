import axios from 'axios';
import { createCustomAxiosWithoutErrorHandling } from '../../axiosInterceptor';

export const getOutlets = async (idKey = false, queries = []) => {
  const params = new URLSearchParams(queries);
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/api/buildings`, { params })
    .then((response) => {
      const mappedResponse = response.data.data
        .map((outlet) => {
          return {
            id: outlet.id,
            addressCode: outlet.code,
            departmentOrOutlet: outlet.name,
            address: outlet.address,
            city: outlet.city,
            state: outlet.state,
            postcode: outlet.postcode,
            country: outlet.country,
            building_type: outlet.building_type,
            phone: outlet.phone,
            autofilled: true,
            status: outlet.status,
          };
        })
        .sort((a, b) =>
          a.addressCode.toLowerCase() > b.addressCode.toLowerCase() ? 1 : -1
        );
      const renderedValues = [];
      for (let outlet of mappedResponse) {
        const key = idKey ? outlet.id : outlet.addressCode;
        renderedValues.push(
          <option key={key} value={key} buildingtype={outlet.building_type}>
            {outlet.addressCode} - {outlet.departmentOrOutlet}
          </option>
        );
      }
      return {
        renderedValues: renderedValues,
        mappedResponse: mappedResponse,
      };
    });
};

export const getBuildings = async (idKey = false, queries = []) => {
  const params = new URLSearchParams(queries);
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/api/buildings`, { params })
    .then((response) => {
      const mappedResponse = response.data.data
        .map((building) => {
          return {
            id: building.id,
            addressCode: building.code,
            departmentOrOutlet: building.name,
            address: building.address,
            city: building.city,
            state: building.state,
            postcode: building.postcode,
            country: building.country,
            phone: building.phone,
            autofilled: true,
          };
        })
        .sort((a, b) =>
          a.addressCode.toLowerCase() > b.addressCode.toLowerCase() ? 1 : -1
        );
      const renderedValues = [];
      for (let building of mappedResponse) {
        const key = idKey ? building.id : building.addressCode;
        renderedValues.push(
          <option key={key} value={key}>
            {building.addressCode} - {building.departmentOrOutlet}
          </option>
        );
      }
      return {
        renderedValues: renderedValues,
        mappedResponse: mappedResponse,
      };
    });
};

export const getDepartments = async (idKey = false, params = {}) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/api/departments`, { params })
    .then((response) => {
      const mappedResponse = response.data.data
        .filter((department) => department.status === 'active')
        .map((department) => {
          if (!department.Building) {
            return {
              departmentCode: '',
              departmentOrOutlet: '',
            };
          } else {
            return {
              id: department.id,
              addressCode: department.Building.code,
              departmentCode: department.code,
              departmentOrOutlet: department.name,
              address: department.Building.address,
              city: department.Building.city,
              state:
                department.Building?.State?.name ?? department.Building?.state,
              postcode: department.Building.postcode,
              country: department.Building.Country.name,
              phone: department.Building.phone,
              autofilled: true,
            };
          }
        })
        .sort((a, b) =>
          a.departmentCode.toLowerCase() > b.departmentCode.toLowerCase()
            ? 1
            : -1
        );
      const renderedValues = [];
      for (let department of mappedResponse) {
        const key = idKey ? department.id : department.departmentCode;
        renderedValues.push(
          <option key={key} value={key}>
            {department.departmentCode} - {department.departmentOrOutlet}
          </option>
        );
      }
      return {
        renderedValues: renderedValues,
        mappedResponse: mappedResponse,
      };
    });
};

export const getUserFullNames = async (hideError, queries = []) => {
  const params = new URLSearchParams(queries);
  if (hideError) {
    const instance = createCustomAxiosWithoutErrorHandling();
    return await instance
      .get(`${process.env.REACT_APP_API_URL}/api/users/names`, { params })
      .then((response) => {
        const renderedValues = [];
        for (let user of response.data.data) {
          renderedValues.push(
            <option key={user.id} value={user.full_name}>
              {user.full_name}
            </option>
          );
        }
        return {
          renderedValues: renderedValues,
        };
      });
  }
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/names`)
    .then((response) => {
      const renderedValues = [];
      for (let user of response.data.data) {
        renderedValues.push(
          <option key={user.id} value={user.full_name}>
            {user.full_name}
          </option>
        );
      }
      return {
        renderedValues: renderedValues,
      };
    });
};
