export let getBorder = (status) => {
  switch (status) {
    case "consignment created":
      return "#0000FF";
    case "consignment cancelled":
      return "#FF0000";
    case "shipping label printed":
      return "#00085E";
    case "arrived at sorting center":
      return "#800080";
    case "out for delivery":
      return "#FFA500";
    case "in transit to sorting center":
      return "#E60076";
    case "item delivered":
      return "#008000";
    case "item received by 3rd party":
      return "#0F4400";
    default:
      return "white";
  }
};
