import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ConsignmentListStore from "../Store/ConsignmentListStore";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import UserStore from "../Store/UserStore";
import moment from "moment";

function PrintActivityHistoryModal(props) {
  const { activityHistory } = props;
  const componentRef = useRef();

  const { name } = UserStore.useStoreState((state) => state);

  const { isOpen, onOpen, onClose } = useDisclosure();

  function renderValues() {
    return activityHistory.map((record, index) => {
      const {
        ActivityDatetime,
        ActivityDescription,
        PerformedByBuildingCode,
        PerformedByDeptCode,
        PerformedByFullName,
      } = record;
      return (
        <Tr key={index}>
          <Td p={1.5} className="td">
            {moment(ActivityDatetime).format("YYYY-MM-DD hh:mm:ss a")}
          </Td>
          <Td p={1.5} className="td" id="trackingNumber">
            {ActivityDescription}
          </Td>
          <Td p={1.5} className="td" id="createdDate">
            {PerformedByFullName}
          </Td>
          <Td p={1.5} className="td name">
            {`${PerformedByDeptCode}/${PerformedByBuildingCode}`}
          </Td>
        </Tr>
      );
    });
  }

  const pageStyle = `
    @media all {
      
      .pagebreak {
        display : none;
      }
      #printer {
        padding: 0px;
        margin: 0px;
      }
  
      Thead {
        display:table-header-group;
      }
  
      .thead2 {
        font-size: 11px !important;
        padding: 0.1rem;
        text-align: left !important;
        
      }
  
      .th {
        display: flex;
        justifyContent: top;
        alignItems: top;
      }
      
      .td {
        font-size: 10px;
        padding-bottom: 0.1rem;
      }
      
    }
  
    @media print {
      .pagebreak {
        page-break-before: auto;
      }
      .thead { display: table-header-group; }
    }`;

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={"blue.400"}
        color="white"
        mt={1}
        ml={2}
        px={5}
        size="sm"
        borderRadius="14px"
      >
        <FontAwesomeIcon icon={faPrint} />
        <Text ml={2}>PRINT</Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="1100px">
          <ModalCloseButton />

          <ModalBody ref={componentRef} id="printer">
            <Table mb={10} mt={7}>
              <Tr>
                <Td textAlign="center" borderColor={"transparent"} colspan="2">
                  <Text fontSize="2rem" fontWeight="bold" mr={3}>
                    Activity History
                  </Text>
                </Td>
              </Tr>
            </Table>

            <Flex justifyContent={"center"} pl={10}>
              <Table width={600}>
                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Tracking No.
                    </Heading>
                  </Th>
                  <Th
                    p={1}
                    verticalAlign="top"
                    borderColor={"transparent"}
                    width={"24%"}
                  >
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Pronto Ref. No.
                    </Heading>
                  </Th>
                </Tr>

                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Text fontSize="sm" fontWeight="bold" textAlign="left">
                      {props.details.TrackingNo}
                    </Text>
                  </Th>
                  <Th
                    p={1}
                    verticalAlign="top"
                    borderColor={"transparent"}
                    width={"24%"}
                  >
                    <Text fontSize="sm" fontWeight="bold" textAlign="left">
                      {props.details.ProntoTransferNo}
                    </Text>
                  </Th>
                </Tr>

                <Tr height={"2rem"}></Tr>

                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Department Ref. No.
                    </Heading>
                  </Th>
                  <Th
                    p={1}
                    verticalAlign="top"
                    borderColor={"transparent"}
                    width={"29%"}
                  >
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Quantity In Parcel
                    </Heading>
                  </Th>
                </Tr>

                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Text fontSize="sm" fontWeight="bold" textAlign="left">
                      {props.details.DepartmentRefNo}
                    </Text>
                  </Th>
                  <Th
                    p={1}
                    verticalAlign="top"
                    borderColor={"transparent"}
                    width={"29%"}
                  >
                    <Text fontSize="sm" fontWeight="bold" textAlign="left">
                      {props.details.ParcelCount}
                    </Text>
                  </Th>
                </Tr>
              </Table>
            </Flex>

            <Table variant="simple">
              <Thead>
                <Tr height={"2rem"}></Tr>
                <Tr height={"2rem"}></Tr>
                <Tr>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={0.5}
                    className="thead2"
                    width="10%"
                  >
                    Date
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    //w="160px"
                    id="trackingNumber"
                    width="12%"
                  >
                    Activity
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    id="createdDate"
                    width="11%"
                  >
                    Performed By
                  </Th>
                  <Th
                    fontSize="lg"
                    fontWeight="bold"
                    p={1.5}
                    className="thead2"
                    width="12%"
                  >
                    Department / Building Code
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">{renderValues()}</Tbody>
            </Table>

            <Flex justifyContent={"left"} mt={20}>
              <Table width={500}>
                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Heading
                      size="sm"
                      color="gray.400"
                      mb={0}
                      fontWeight="bold"
                      textAlign="left"
                    >
                      Notes
                    </Heading>
                  </Th>
                </Tr>

                <Tr>
                  <Th p={1} verticalAlign="top" borderColor={"transparent"}>
                    <Text fontSize="sm" fontWeight="semibold" textAlign="left">
                      {props.details.Notes === "" ? "-" : props.details.Notes}
                    </Text>
                  </Th>
                </Tr>
              </Table>
            </Flex>

            <Table>
              <Tr>
                <Td textAlign="right">
                  <Text fontSize="small" fontWeight="bold" mr={3}>
                    Date: {new Date().toLocaleDateString("en-GB")} Time:{" "}
                    {moment().format("hh:mm:ss A")} User: {name}
                  </Text>
                </Td>
              </Tr>
            </Table>
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => (
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: "inline" }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              pageStyle={pageStyle}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrintActivityHistoryModal;
