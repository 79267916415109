import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Center,
  Image,
  FormLabel,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
} from '@chakra-ui/react';
import Cookies from 'universal-cookie/es6';
import axios from 'axios';

const LOGISTIC_PRONTO_API_URL = process.env.REACT_APP_LOGISTIC_PRONTO_API_URL;

function ScanPronto() {
  const [refNo, setRefNo] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const history = useHistory();

  const onEnter = async () => {
    setErrorMsg(false);

    try {
      const result = await axios.get(
          `${LOGISTIC_PRONTO_API_URL}/consignments/${refNo}`
      );

      if (result?.status === 200) {
        const pronto = {
          departmentRef: result.data.DepartmentRefNo,
          quantity: result.data.ParcelCount,
          receiverAddressCode: result.data.ReceiverAddressCode,
          receiverName: result.data.ReceiverName,
          prontoRef: result.data.ProntoTransferNo,
          receiverDepartmentCode: result.data.ReceiverDepartmentCode,
        };

        const cookies = new Cookies();
        cookies.set('pronto', pronto);
        history.push('/consignment/new');
      } else {
        let errMessage =
            result?.response?.data?.message ?? 'Undefined error occurred.';

        setErrorMsg(errMessage);
      }
    } catch (err) {
      let errMessage =
          err?.response?.data?.message ?? 'Undefined error occurred.';
      setErrorMsg(errMessage);
    }
  };

  return (
      <>
        <ModalContent minW="400px" p="50px">
          <ModalCloseButton />
          <ModalBody fontFamily="Arial" marginTop={'-30px'}>
            <Image src={`${window.location.origin}/scan.gif`} />
            <Center fontSize={20} fontWeight={100} marginTop={'-30px'}>
              Align Pronto Reference barcode
            </Center>
            <Center fontSize={20} marginBottom={10}>
              within frame to scan
            </Center>
            <FormControl>
              <FormLabel color={'#C7C7C7'}>PRONTO REFERENCE NO.</FormLabel>
              <InputGroup>
                <Input
                    autoFocus={true}
                    value={refNo}
                    marginBottom={19}
                    onChangeCapture={(e) => setRefNo(e.target.value)}
                    max={8}
                    id="prontoInput"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onEnter();
                      }
                    }}
                />
                <InputRightElement>
                  <Button
                      h="2.5rem"
                      size="xl"
                      backgroundColor="#6e26c7"
                      colorScheme="purple"
                      padding={2}
                      marginRight={5}
                      onClick={onEnter}
                      disabled={
                        refNo.length < 1 ? true : refNo.length > 8
                      }
                  >
                    ENTER
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent={'center'} mt={'-0.7rem'}>
              <span
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    display: refNo.length > 8 ? 'block' : 'none',
                  }}
              >
                Pronto Ref No. max 8 characters
              </span>
                <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      display: errorMsg ? 'block' : 'none',
                    }}
                >
                {errorMsg}
              </span>
              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </>
  );
}

export default ScanPronto;
