import SignaturePad from "react-signature-canvas";

const Signature = (props) => (
    <SignaturePad
      penColor="black"
      {...props}
      style={{ border: "5px solid black" }}
      ref={(ref) => { props.setSignature(ref) }} 
    />
  );

export default Signature;