import React from 'react';
import { useField, useFormikContext } from 'formik';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import './date-picker.css';

export const DateRange = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DateRangePicker
      {...field}
      {...props}
      calendarIcon={null}
      format="dd/MM/y"
      maxDate={new Date()}
      onChange={val => {
        if (props?.onChange) props.onChange(val);
        setFieldValue(field.name, val);
      }}
      value={props.value}
    />
  );
};
