import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Field } from "formik";

function DetailForm(props) {
  const { isPronto } = props;
  let validateLength = (value) => {
    let trimmedValue = value.trim();
    if (trimmedValue.length > 0 && trimmedValue.length > 10) {
      return "Only 10 characters allowed";
    }
  };
  return (
    <>
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(3, 1fr)"
        gap={3}
      >
        <GridItem colSpan={1}>
          <Field name="departmentRef" validate={validateLength}>
            {({ field, form }) => (
              <FormControl
                isInvalid={
                  form.errors.departmentRef && form.touched.departmentRef
                }
              >
                <FormLabel htmlFor="departmentRef">
                  DEPARTMENT REF NO.
                </FormLabel>
                <Input
                  {...field}
                  id="departmentRef"
                  type="text"
                  maxLength={10}
                />
                <FormErrorMessage>{form.errors.departmentRef}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </GridItem>
        {!isPronto ? (
          <GridItem colSpan={1}>
            <Field name="prontoRef" validate={validateLength}>
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.prontoRef && form.touched.prontoRef}
                >
                  <FormLabel htmlFor="prontoRef">
                    PRONTO TRANSFER REF NO.
                  </FormLabel>
                  <Input {...field} id="prontoRef" type="text" maxLength={8} />
                  <FormErrorMessage>{form.errors.prontoRef}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
        ) : null}

        <GridItem colSpan={1}>
          <Field name="quantity">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.quantity && form.touched.quantity}
                isRequired
                type="number"
              >
                <FormLabel htmlFor="quantity">QUANTITY IN PARCEL</FormLabel>
                <Input
                  {...field}
                  id="quantity"
                  type="number"
                  min={0}
                  max={2147483647}
                />
                <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </GridItem>
        <GridItem colSpan={isPronto ? 2 : 1} rowSpan={2}>
          <Field name="notes">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.notes && form.touched.notes}
                d="inline-block"
              >
                <FormLabel htmlFor="notes">NOTES</FormLabel>
                <Textarea {...field} type="text" maxLength={1000} />
                <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
}

export default DetailForm;
