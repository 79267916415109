import {
  Button,
  Center,
  Image,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function DeleteModal(props) {
  const { id, code, showMessage } = props;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function customClose() {
    if (props.onClose) {
      props.onClose();
    }
    onClose();
  }

  async function deleteAddress() {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/addresses/${id}`)
      .then((response) => {
        toast({
          title: "Delete Successful",
          description: `Address ${code} was deleted successfully`,
          isClosable: true,
          position: "bottom-left",
          status: "success",
        });
        customClose();
      })
      .catch((error) => {
        toast({
          title: "Delete Unsuccessful",
          description: `An error occurred while deleting ${code}`,
          duration: null,
          isClosable: true,
          position: "bottom-left",
          status: "error",
        });
      });
  }

  return (
    <>
      <Tooltip
        label="Delete Address"
        bg="gray.600"
        color="white"
        borderRadius={8}
        fontSize="md"
        placement="top"
        isDisabled={props.disabled}
        hasArrow
      >
        <Button
          onClick={onOpen}
          backgroundColor="red.400"
          color="red.200"
          ml={3}
          borderRadius="14px"
          size="sm"
          variant="solid"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
          {showMessage && <Text m={2}>DELETE</Text>}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CANCEL CONFIRMATION</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image
                src={`${window.location.origin}/warning.gif`}
                h="75%"
                w="75%"
              />
            </Center>
            <Text>
              Are you sure you would like to delete address code {code} from the
              system?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={deleteAddress}>
              YES
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              NO
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteModal;
