import {
  Box,
  Button,
  Circle,
  Grid,
  GridItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import ConsignmentAddressForm from '../Form/ConsignmentAddressForm';
import AddressContextStore from '../Store/AddressContextStore';
import LoadContact from './LoadContact';
import ConsignmentDetailForm from '../Form/ConsignmentDetailForms';
import UserStore from '../Store/UserStore';
import ScanPronto from './ScanPronto';

function CreateConsignment(props) {
  const { fetchData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addressSource, setAddressSource] = useState('manual');
  const [selectedOutlet, setSelectedOutlet] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [isLoadingOutlet, setIsLoadingOutlet] = useState(false);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const { isSenderFilled, isReceiverFilled, isCreateByProntoRef } =
    AddressContextStore.useStoreState((state) => state);
  const { initialiseAddress, resetState, setIsCreateByProntoRef } =
    AddressContextStore.useStoreActions((actions) => actions);

  let customOnClose = () => {
    resetState();
    setSelectedDepartment('');
    setSelectedOutlet('');
    onClose();
  };

  const userStore = UserStore.useStoreState((state) => state);

  let headerTitle = 'SENDER';
  if (isSenderFilled && isReceiverFilled) {
    headerTitle = 'CONSIGNMENT';
  } else if (isSenderFilled) {
    headerTitle = 'RECEIVER';
  }

  useEffect(() => {
    if (isOpen) {
      initialiseAddress(userStore);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedOutlet === '' && selectedDepartment === '') {
      setAddressSource('manual');
    }
  }, [selectedOutlet, selectedDepartment]);

  return (
    <>
      <Button
        backgroundColor="#6e26c7"
        borderRadius={10}
        colorScheme="purple"
        leftIcon={<AddIcon />}
        onClick={() => {
          setIsCreateByProntoRef(true);
          onOpen();
        }}
        size="lg"
        variant="solid"
        mr={5}
      >
        <span>
          CREATE NEW
          <br />
          <span style={{ fontSize: '80%' }}>(Pronto Ref No.)</span>
        </span>
      </Button>

      <Button
        backgroundColor="#6e26c7"
        borderRadius={10}
        colorScheme="purple"
        leftIcon={<AddIcon />}
        onClick={() => {
          setIsCreateByProntoRef(false);
          onOpen();
        }}
        size="lg"
        variant="solid"
      >
        CREATE NEW
      </Button>

      <Modal isOpen={isOpen} onClose={customOnClose}>
        <ModalOverlay />
        {!isCreateByProntoRef ? (
          <>
            <ModalContent minW="700px" p="50px">
              <ModalHeader textAlign="center" fontSize="4xl">
                ADD NEW
              </ModalHeader>
              <ModalHeader textAlign="center">
                <Grid templateColumns="repeat(3, 1fr)">
                  <GridItem colSpan={1}>
                    <Box>
                      <Circle
                        size="40px"
                        bg="#6e26c7"
                        color="white"
                        margin="auto"
                      >
                        1
                      </Circle>
                      Step 1: Sender Details
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box textAlign="center">
                      <Circle
                        size="40px"
                        bg={isSenderFilled ? '#6e26c7' : 'grey'}
                        color="white"
                        margin="auto"
                      >
                        2
                      </Circle>
                      Step 2: Receiver Details
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box>
                      <Circle
                        size="40px"
                        bg={isReceiverFilled ? '#6e26c7' : 'grey'}
                        color="white"
                        margin="auto"
                      >
                        3
                      </Circle>
                      Step 3: Consignment Details
                    </Box>
                  </GridItem>
                </Grid>
              </ModalHeader>
              <ModalCloseButton />
              <ModalHeader>{headerTitle} DETAILS</ModalHeader>
              {!isSenderFilled || !isReceiverFilled ? (
                <LoadContact
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                  setSelectedOutlet={setSelectedOutlet}
                  selectedOutlet={selectedOutlet}
                  setAddressSource={setAddressSource}
                  isLoadingOutlet={isLoadingOutlet}
                  setIsLoadingOutlet={setIsLoadingOutlet}
                  isLoadingDepartment={isLoadingDepartment}
                  setIsLoadingDepartment={setIsLoadingDepartment}
                />
              ) : null}
              {isSenderFilled && isReceiverFilled ? (
                <ConsignmentDetailForm
                  onClose={customOnClose}
                  performedBy={userStore.name}
                  fetchData={fetchData}
                  isLoadingOutlet={isLoadingOutlet}
                  isLoadingDepartment={isLoadingDepartment}
                />
              ) : (
                <ConsignmentAddressForm
                  setSelectedDepartment={setSelectedDepartment}
                  setSelectedOutlet={setSelectedOutlet}
                  addressSource={addressSource}
                  setAddressSource={setAddressSource}
                  isLoadingOutlet={isLoadingOutlet}
                  isLoadingDepartment={isLoadingDepartment}
                />
              )}
            </ModalContent>
          </>
        ) : (
          <AddressContextStore.Provider>
            <ScanPronto />
          </AddressContextStore.Provider>
        )}
      </Modal>
    </>
  );
}

export default CreateConsignment;
