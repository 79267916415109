import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Skeleton,
  SkeletonText,
  Slide,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  WrapItem,
} from '@chakra-ui/react';
import {
  FaCog,
  FaPowerOff,
  FaTachometerAlt,
  FaTruck,
  FaBarcode,
} from 'react-icons/fa';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie/es6';
import UserStore from './Store/UserStore';
import { checkHasRoles } from './utils';

const ProfileLoader = () => (
  <WrapItem pl={10}>
    <Box>
      <Skeleton>
        <Avatar alt={'profile image'} size="sm" borderRadius={0} />
      </Skeleton>
    </Box>
    <Box pl={6}>
      <Box width={100}>
        <SkeletonText noOfLines={1} pb={2} />
      </Box>
      <Box width={100}>
        <SkeletonText noOfLines={2} />
      </Box>
    </Box>
  </WrapItem>
);

const SideMenu = (props) => {
  const [isConfigOpened, setIsConfigOpened] = useState(false);
  // const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
    onClose: () => {
      props.setMenuClosed(true);
    },
    onOpen: () => {
      props.setMenuClosed(false);
    },
  });

  const sideMenuHighlight = isOpen
    ? {
        fontWeight: 'bold',
        color: '#6e26c7',
      }
    : null;

  const fillUserInfo = UserStore.useStoreActions(
    (actions) => actions.fillUserInfo
  );

  const [userProfile, setUserProfile] = useState({
    name: '',
    department: '',
    building: '',
    image: '',
    roles: [],
  });

  async function setProfile() {
    const logisticToken = new Cookies().get('tomei-logistics-token');

    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/profile`, {
        headers: {
          Authorization: `Bearer ${logisticToken}`,
        },
      })
      .then((response) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (
          params.redirectedFromSso === 'true' &&
          Number(response.data.data.staff.id) !==
            Number(params.redirectedUserId)
        ) {
          const cookies = new Cookies();
          cookies.remove('tomei-logistics-token');
          window.location.href = '/login';
        } else {
          setUserProfile({
            name: response.data.data.staff.preferred_name,
            department: response.data.data.department.name,
            building: response.data.data.building.name,
            image: response.data.data.staff.image,
            roles: response.data.data.logistics_roles.map((role) =>
              role.Name.toLowerCase()
            ),
          });
          fillUserInfo(response.data.data);
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  React.useEffect(() => {
    if (window.location.pathname.includes('config')) {
      setIsConfigOpened(true);
    } else if (!window.location.pathname.includes('config')) {
      setIsConfigOpened(false);
    }
    if (userProfile.name === '') {
      setProfile();
    }
  }, []);

  React.useEffect(() => {
    if (window.location.pathname.includes('config')) {
      setIsConfigOpened(true);
    } else if (!window.location.pathname.includes('config')) {
      setIsConfigOpened(false);
    }
  }, [location]);

  const highlightBorder = (
    <Box
      border="3px solid #6e26c7"
      borderRadius="25px"
      backgroundColor="#6e26c7"
      mr={1}
    />
  );

  const profileLoading = Boolean(
    !userProfile.name || !userProfile.building || !userProfile.department
  );

  return (
    <Slide
      direction="left"
      style={{
        width: '280px',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: isOpen ? 0 : 30,
        zIndex: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: '#F6F6F6',
        color: '#8F8F8F',
      }}
      h="inherit"
      id="sideMenu"
      in={isOpen}
    >
      <Box p={5}>
        <Flex>
          <Image
            src={`${window.location.origin}/logo.png`}
            alt="Tomei"
            w="115px"
            ml={6}
          />
          <Spacer />
          <IconButton
            mr={isOpen ? 0 : '-25px'}
            aria-label="Close Menu"
            icon={<HamburgerIcon />}
            color="black"
            bg="#F6F6F6"
            fontSize="20px"
            onClick={onToggle}
          />
        </Flex>
      </Box>
      {isOpen && (
        <>
          <Center pl={5} mb={7} fontWeight="bold" fontSize="xl">
            Logistic System
          </Center>

          {profileLoading ? (
            <ProfileLoader />
          ) : (
            <WrapItem pl={10}>
              <Avatar src={userProfile.image} size="sm" borderRadius={0} />
              <Box as="span" fontSize="md" pl={6} fontWeight="bold">
                {userProfile.name}
                <Box fontSize="sm" fontWeight="normal" mt={1}>
                  <span>
                    {userProfile.building} / {userProfile.department}
                  </span>
                </Box>
              </Box>
            </WrapItem>
          )}

          <Stack align="left" direction="column" spacing={5} pl={10}>
            <Box mt={4}>
              <Text fontSize="xl" fontWeight="bold">
                MAIN MENU
              </Text>
            </Box>
            <NavLink
              to="/"
              exact={true}
              activeStyle={sideMenuHighlight}
              // onClick={() => setIsDashboard(true)}
            >
              <Flex>
                <Box>
                  <FaTachometerAlt size={25} style={{ display: 'inline' }} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    Dashboard
                  </Text>
                </Box>
                <Spacer />
                {location.pathname === '/' ? highlightBorder : null}
              </Flex>
            </NavLink>
            <NavLink
              to="/consignment"
              exact={true}
              activeStyle={sideMenuHighlight}
            >
              <Flex>
                <Box>
                  <FaTruck size={25} style={{ display: 'inline' }} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    Consignment
                  </Text>
                </Box>
                <Spacer />
                {location.pathname === '/consignment' ? highlightBorder : null}
              </Flex>
            </NavLink>
            <NavLink to="/scan" activeStyle={sideMenuHighlight}>
              <Flex>
                <Box>
                  <FaBarcode size={25} style={{ display: 'inline' }} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    Scan
                  </Text>
                </Box>
                <Spacer />
                {location.pathname === '/scan' ? highlightBorder : null}
              </Flex>
            </NavLink>
            {checkHasRoles(
              userProfile.roles,
              ['user role', 'address book', 'updated history'],
              false
            ) && (
              <Accordion allowToggle>
                <AccordionItem border="none" mr={'4px'}>
                  <Flex>
                    <AccordionButton
                      p={0}
                      style={
                        isConfigOpened
                          ? {
                              fontWeight: 'bold',
                              color: '#6e26c7',
                            }
                          : {
                              fontWeight: 'normal',
                              color: '#8F8F8F',
                              borderRight: '0px',
                            }
                      }
                      flex="1"
                    >
                      <Box>
                        <FaCog size={25} style={{ display: 'inline' }} />
                        <Text fontSize="lg" d="inline" ml={3}>
                          Settings
                        </Text>
                      </Box>
                      <Spacer />
                      <Box>{isOpen ? <AccordionIcon /> : null}</Box>
                    </AccordionButton>
                    <Box
                      border={
                        location.pathname.includes('config')
                          ? '3px solid #6e26c7'
                          : '3px solid #F6F6F6'
                      }
                      borderRadius="25px"
                      backgroundColor={
                        location.pathname.includes('config')
                          ? '#6e26c7'
                          : '#F6F6F6'
                      }
                    />
                  </Flex>
                  <AccordionPanel>
                    {checkHasRoles(userProfile.roles, ['address book']) && (
                      <NavLink
                        to="/config/address"
                        activeStyle={{ color: '#6e26c7', fontWeight: 'bold' }}
                      >
                        <Box mb={3} ml={4}>
                          <Text fontSize="md">Address Book</Text>
                        </Box>
                      </NavLink>
                    )}
                    {checkHasRoles(userProfile.roles, ['updated history']) && (
                      <NavLink
                        to="/config/updated-history"
                        activeStyle={{ color: '#6e26c7', fontWeight: 'bold' }}
                      >
                        <Box mb={3} ml={4}>
                          <Text fontSize="md">Updated History</Text>
                        </Box>
                      </NavLink>
                    )}
                    {checkHasRoles(userProfile.roles, ['user role']) && (
                      <NavLink
                        to="/config/role"
                        activeStyle={{ color: '#6e26c7', fontWeight: 'bold' }}
                      >
                        <Box ml={4}>
                          <Text fontSize="md">User Role</Text>
                        </Box>
                      </NavLink>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            <NavLink
              to="/login"
              onClick={() => {
                const cookies = new Cookies();
                cookies.remove('tomei-logistics-token', {
                  path: '/',
                });
                cookies.remove('tomei-logistics-userID', {
                  path: '/',
                });
              }}
            >
              <Box>
                <FaPowerOff size={25} style={{ display: 'inline' }} />
                <Text fontSize="lg" d="inline" ml={3}>
                  Logout
                </Text>
              </Box>
            </NavLink>
            <Box position="absolute" bottom={10}>
              <Text mb={1} fontWeight="bold">
                TOMEI Logistics System
              </Text>
              <Text fontSize="md" fontStyle="Open Sans" fontWeight="light">
                Made by TOMEI ITD v1.2.1
              </Text>
            </Box>
          </Stack>
        </>
      )}
    </Slide>
  );
};

export default SideMenu;
