import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import ChartSection from './chart-section';
// import SearchSection from './search-section';

function ConsignmentAging({ id, params, fetchData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [series, setSeries] = useState([]);

  const assignValues = (newSeries, status, i) => {
    let serie = newSeries.find((serie) => serie.status === status);
    if (serie?.data) {
      const newData = [...serie.data];
      newData[i] = newData[i] + 1;
      serie = { ...serie, data: [...newData] };

      newSeries = [
        ...newSeries.filter((serie) => serie.status !== status),
        serie,
      ];
    }

    newSeries.sort((a, b) => a.order - b.order);
    return newSeries;
  };

  const initialSeries = [
    {
      name: 'Consignment Created',
      data: [0, 0, 0, 0, 0],
      status: 'Created',
      order: 1,
    },
    {
      name: 'Shipping Label Printed',
      data: [0, 0, 0, 0, 0],
      status: 'Printed',
      order: 2,
    },
    {
      name: 'Arrived at Sorting Center',
      data: [0, 0, 0, 0, 0],
      status: 'Arrived',
      order: 3,
    },
    {
      name: 'Out For Delivery',
      data: [0, 0, 0, 0, 0],
      status: 'Delivery',
      order: 4,
    },
    {
      name: 'In Transit To Sorting Center',
      data: [0, 0, 0, 0, 0],
      status: 'Transit',
      order: 5,
    },
  ];

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        const consignmentsPending = await fetchData({
          ...params,
          Direction: 'Inbound',
        });
        let newSeries = initialSeries;
        if (consignmentsPending.length > 0) {
          consignmentsPending.map((cons) => {
            var a = moment(Date.now());
            var b = moment(cons.CreatedDate);
            const dateRange = a.diff(b, 'days') + 1;
            if (dateRange < 8) {
              newSeries = assignValues(newSeries, cons.Status, 0);
            } else if (dateRange >= 8 && dateRange <= 14) {
              newSeries = assignValues(newSeries, cons.Status, 1);
            } else if (dateRange >= 15 && dateRange <= 21) {
              newSeries = assignValues(newSeries, cons.Status, 2);
            } else if (dateRange >= 22 && dateRange <= 28) {
              newSeries = assignValues(newSeries, cons.Status, 3);
            } else {
              newSeries = assignValues(newSeries, cons.Status, 4);
            }
          });
        }

        setSeries(newSeries);
        setIsLoading(false);
      }
    })();
  }, [id]);

  return (
    <Flex w="65vw" flexDirection={'column'} gridGap={5}>
      <Flex w="90%" color="black" flexDirection={'row-reverse'}>
        {/* <SearchSection
          fetchData={fetchData}
          user={id}
          setSeries={setSeries}
          dateParams={dateParams}
          setDateParams={setDateParams}
        /> */}
      </Flex>

      <Box
        w="90%"
        color="black"
        boxShadow="base"
        p="5"
        rounded="md"
        bg="white"
        height={295}
      >
        <Text fontWeight={600} fontSize="md">
          Consignment Aging Status
        </Text>

        <ChartSection series={series} isLoading={isLoading} />
      </Box>
    </Flex>
  );
}

export default ConsignmentAging;
