import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { CheckCircleIcon, TriangleDownIcon } from "@chakra-ui/icons";
import ParcelWithMePopover from "./ParcelWithMePopover";
import TransactionHistoryPopover from "./TransactionHistoryPopover";
import { useState } from "react";
import ScanModal from "./ScanModal";
import Signature from "../Component/Signature";
import { FaEraser } from "react-icons/fa";
import Success from "./Success";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Failure from "./Failure";
import UserStore from "../Store/UserStore";
import _ from "lodash";
import { checkHasRoles } from "../utils";

const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

function Consignment(props) {
  document.title = "Tomei Logistic - Scan";
  const FAILED_STATUS = "Failed";
  const [barcodes, setBarcodes] = useState([]);
  const [purpose, setPurpose] = useState(0);
  const [signature, setSignature] = useState({});
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const roles = UserStore.useStoreState((state) => state.roles);
  const toast = useToast();
  const failedItemsCount = barcodes.filter(
    (barcode) => barcode.status === FAILED_STATUS
  ).length;

  let validate = (value) => {
    let error;
    if (!value) {
      error = "This info is required";
    }
    return error;
  };

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setPurpose(inputValue);
  };

  let deleteListing = (trackingNo) => {
    const filteredBarcode = barcodes.filter((barcode) => {
      return barcode.value !== trackingNo;
    });
    setBarcodes(filteredBarcode);
  };

  let renderValues = () => {
    return barcodes.map((barcode, index) => {
      const { value, status } = barcode;
      return (
        <Tr key={index}>
          <Td fontSize="md" p={1.5}>
            {index + 1}
          </Td>
          <Td fontSize="md" minW="200px" p={1.5}>
            <Text d="inline">{value}</Text>
            {status === FAILED_STATUS ? (
              <Box color="blue.400" d="inline" ml={3}>
                <Tooltip label={barcode.error} fontSize="lg">
                  <span>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}
          </Td>
          <Td fontSize="md" width="100px" p={1.5}>
            {status && (
              <Text
                d="inline"
                border={
                  status === FAILED_STATUS ? "1px solid red" : "1px solid green"
                }
                borderRadius="20px"
                color={status === FAILED_STATUS ? "red" : "green.400"}
                py={1}
                px={3}
                fontWeight="bold"
                textAlign="center"
              >
                {status}
              </Text>
            )}
            {status === FAILED_STATUS ? (
              <>
                <Box
                  background="red.400"
                  textColor="white"
                  d="inline"
                  py={1}
                  px={3}
                  borderRadius="10px"
                  ml={3}
                  onClick={() => deleteListing(value)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Box>
              </>
            ) : (
              ""
            )}
          </Td>
        </Tr>
      );
    });
  };

  let clear = () => {
    signature.clear();
  };

  let sendScannedConsignments = (data) => {
    let apiPath = "";

    switch (purpose) {
      case "1": {
        apiPath = "logistic-item-received";
        break;
      }
      case "2": {
        apiPath = "pickup-deliver";
        break;
      }
      case "3": {
        apiPath = "item-collection";
        break;
      }
      case "4": {
        apiPath = "item-received";
        break;
      }
      case "5": {
        apiPath = "third-party-item-received";
        break;
      }
      default: {
        break;
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/consignment/update/${apiPath}`,
        data
      )
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        } else {
          throw response;
        }
      })
      .catch((error) => {
        setFailure(true);
        setBarcodes(
          barcodes.map((b) => ({
            ...b,
            status: error.response.data.data.error[b.value]
              ? "Failed"
              : b.status,
            error: error.response.data.data.error[b.value],
          }))
        );
      });
  };

  let getMessages = (status) => {
    switch (status) {
      case "1":
        return {
          title: "-Logistic- Item Received Listing",
          notes:
            "I, hereby confirm that I have received and verified that all parcels/documents are in good condition",
          button: "Confirm Receive of Items",
          success: "Item Received Successfully",
        };
      case "2":
        return {
          title: "Item for Delivery Listing",
          notes:
            "I, hereby confirm that I have picked up and verified that all parcels/documents are in good condition",
          button: "Confirm Pickup of Items",
          success: "Item Picked Up Successfully",
        };
      case "3":
        return {
          title: "Item Collection Listing",
          notes:
            "I, hereby confirm that I have picked up and verified that all parcels/documents are in good condition",
          button: "Confirm Pickup of Items",
          success: "Item Picked Up Successfully",
        };
      case "4":
        return {
          title: "Item Received Listing",
          notes:
            "I, hereby confirm that I have received and verified that all parcels/documents are in good condition",
          button: "Confirm Receive of Items",
          success: "Item Received Successfully",
        };
      case "5":
        return {
          title: "-3rd Party- Item Received Listing",
          notes:
            "I, hereby confirm that I have received and verified that all parcels/documents are in good condition",
          button: "Confirm Receive of Items",
          success: "Item Received Successfully",
        };

      default:
        return {
          title: "",
          notes: "",
          button: "Confirm of Items",
          success: "",
        };
    }
  };

  const uploadSignature = async (blob) => {
    const formData = new FormData();
    formData.append("file", blob, "signature.png");

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/images`,
      formData
    );

    return response.data.data.url;
  };

  const message = getMessages(purpose);
  return (
    <Box
      ml={props.isMenuClosed ? "20px" : "270px"}
      p={6}
      flex="1"
      textAlign="left"
      minH="816px"
    >
      <Flex>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            Scan
          </Text>
          <Text>To scan the parcel or items</Text>
        </Box>
        <Spacer />
        <Box>
          <TransactionHistoryPopover />
          <ParcelWithMePopover />
        </Box>
      </Flex>
      <Flex mt={10} mb={3}>
        <Center>
          <Heading fontSize="2xl" color="gray.500" mr={3}>
            SCAN PURPOSE
          </Heading>
          <Select
            w="fit-content"
            icon={<TriangleDownIcon />}
            onChange={handleInputChange}
            placeholder="- Select -"
            value={purpose}
          >
            {checkHasRoles(roles, ["logistic item received"]) && (
              <option value="1">Logistic Item Received</option>
            )}
            {checkHasRoles(roles, ["item pickup for delivery"]) && (
              <option value="2">Item Pickup for Delivery</option>
            )}
            {checkHasRoles(roles, ["item collection"]) && (
              <option value="3">Item Collection</option>
            )}
            {checkHasRoles(roles, ["item received"]) && (
              <option value="4">Item Received</option>
            )}
            {checkHasRoles(roles, ["3rd party item received"]) && (
              <option value="5">3rd Party Item Received</option>
            )}
          </Select>
        </Center>
      </Flex>
      <ScanModal
        barcodes={barcodes}
        disabled={purpose == ""}
        purpose={purpose}
        setBarcodes={(newBarcodes) => {
          let activityType = "";

          switch (purpose) {
            case "1": {
              activityType = "Arrived";
              break;
            }
            case "2": {
              activityType = "Delivery";
              break;
            }
            case "3": {
              activityType = "Transit";
              break;
            }
            case "4": {
              activityType = "Delivered";
              break;
            }
            case "5": {
              activityType = "Received";
              break;
            }
            default: {
              break;
            }
          }
          const data = {
            TrackingNoList: newBarcodes.map((barcode) => barcode.value),
            ActivityType: activityType,
          };
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/consignment/check`,
              data
            )
            .then((response) => {
              if (response.status === 200) {
                if (!_.isEmpty(response.data.data.error)) {
                  const errorBarcodesWithMessage = _.toPairs(
                    response.data.data.error
                  );
                  errorBarcodesWithMessage.map((barcode) => {
                    toast({
                      title: "Failed",
                      description: barcode[1],
                      status: "error",
                      isClosable: true,
                      position: "bottom-left",
                    });
                  });
                  const errorBarcodes = errorBarcodesWithMessage.map(
                    (barcode) => barcode[0]
                  );
                  setBarcodes([
                    ...barcodes,
                    ...newBarcodes
                      .filter(
                        (b) => !barcodes.some((eb) => eb.value === b.value)
                      )
                      .filter((b) => errorBarcodes.indexOf(b.value) < 0),
                  ]);
                } else {
                  setBarcodes([
                    ...barcodes,
                    ...newBarcodes.filter(
                      (b) => !barcodes.some((eb) => eb.value === b.value)
                    ),
                  ]);
                }
              } else {
                throw response;
              }
            });
        }}
        mt={3}
      />
      {barcodes.length > 0 && (
        <>
          <Box maxW={"700px"}>
            <Text fontSize="lg" textAlign="right">
              (Total: {barcodes.length} items)
            </Text>
          </Box>
          <Heading fontSize="2xl" mt={5}>
            {message.title}
          </Heading>
          <Table maxW="50vw" size="lg" mb={5}>
            <Thead>
              <Tr>
                <Th p={1.5} w="1vw" fontSize="lg">
                  No.
                </Th>
                <Th p={1.5} w="1vw" fontSize="lg">
                  Tracking no.
                </Th>
                <Th p={1.5} w="1vw" fontSize="lg">
                  Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>{renderValues()}</Tbody>
          </Table>
        </>
      )}
      <Formik
        initialValues={{
          fullName: "",
          companyName: "",
          nric: "",
          contactNo: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (signature && Object.keys(signature).length > 0) {
            const blob = dataURItoBlob(signature.toDataURL());
            const postData = {
              ReceiverNRIC: values.nric,
              ReceiverName: values.fullName,
              ReceiverContact: values.contactNo,
              ReceiverCompany: values.companyName,
              ReceiverSignature: await uploadSignature(blob),
              TrackingNoList: barcodes
                .filter((b) => b.status === "Checked")
                .map((b) => b.value),
            };
            await sendScannedConsignments(postData);
          } else {
            const postData = {
              TrackingNoList: barcodes
                .filter((b) => b.status === "Checked")
                .map((b) => b.value),
            };
            await sendScannedConsignments(postData);
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            {purpose == 5 && (
              <Box
                backgroundColor="gray.100"
                p={3}
                w="50vw"
                borderRadius="25px"
              >
                <Heading size="lg" mb={5}>
                  3rd Party Receiver Information
                </Heading>
                <Grid
                  templateRows="repeat(3, 1fr)"
                  templateColumns="repeat(2, 1fr)"
                  gap={4}
                >
                  <GridItem colSpan={1}>
                    <Field name="fullName" validate={validate}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                          d="inline-block"
                        >
                          <FormLabel htmlFor="fullName">
                            RECEIVER FULL NAME
                          </FormLabel>
                          <Input {...field} id="fullName" borderColor="black" />
                          <FormErrorMessage>
                            {form.errors.fullName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Field name="companyName" validate={validate}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.companyName && form.touched.companyName
                          }
                          d="inline-block"
                        >
                          <FormLabel htmlFor="companyName">
                            RECEIVER COMPANY NAME
                          </FormLabel>
                          <Input
                            {...field}
                            id="companyName"
                            borderColor="black"
                          />
                          <FormErrorMessage>
                            {form.errors.companyName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Field name="nric" validate={validate}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.nric && form.touched.nric}
                          d="inline-block"
                          float="left"
                        >
                          <FormLabel htmlFor="nric">RECEIVER NRIC</FormLabel>
                          <Input {...field} id="nric" borderColor="black" />
                          <FormErrorMessage>
                            {form.errors.nric}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem rowSpan={2} colSpan={1}>
                    <Field name="signature">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="signature">
                            RECEIVER SIGNATURE
                            <Button
                              onClick={clear}
                              backgroundColor="red.400"
                              size="xs"
                              color="lightgrey"
                              borderRadius="30px"
                              ml={3}
                            >
                              <FaEraser />
                              <Text ml={3}>RESET</Text>
                            </Button>
                          </FormLabel>

                          <Box p={0} m={0} border="1px solid black">
                            <Signature
                              {...props}
                              canvasProps={{
                                width: 460,
                                height: 200,
                                className: "sigCanvas",
                              }}
                              setSignature={setSignature}
                            />
                          </Box>
                          <FormErrorMessage>
                            {form.errors.fullName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Field name="contactNo" validate={validate}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactNo && form.touched.contactNo
                          }
                          d="inline-block"
                        >
                          <FormLabel htmlFor="contactNo">
                            RECEIVER CONTACT / EXT NO.
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactNo"
                            borderColor="black"
                            type="text"
                            pattern="^[0-9]+"
                          />
                          <FormErrorMessage>
                            {form.errors.contactNo}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
              </Box>
            )}
            <Box mt={5}>
              <Text mb={3}>{message.notes}</Text>
              <Button
                isLoading={props.isSubmitting}
                type="submit"
                borderRadius={10}
                backgroundColor="green.400"
                size="md"
                color="white"
                disabled={
                  barcodes.length === 0 ||
                  purpose === 0 ||
                  barcodes.filter((b) => b.status === "Checked").length === 0 ||
                  failedItemsCount > 0
                }
              >
                <CheckCircleIcon />
                <Text fontSize="lg" style={{ display: "inline" }} ml={3}>
                  {message.button}
                </Text>
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {success && (
        <Success
          headerMessage={message.success}
          setBarcodes={setBarcodes}
          setSuccess={setSuccess}
          success={success}
          barcodeCount={barcodes.length}
        />
      )}
      {failure && (
        <Failure
          headerMessage="ITEM RECEIVED UNSUCCESSFUL"
          setFailure={setFailure}
          failure={failure}
          barcodeCount={failedItemsCount}
        />
      )}
    </Box>
  );
}

export default Consignment;
