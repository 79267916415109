import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Select,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import React from 'react';
import { DateRange } from '../Component/DateRange';

const UpdatedHistorySearchFilters = ({ currentFilters, setSearchFilters }) => {
  return (
    <Accordion
      allowToggle
      bg="#fafafa"
      p={4}
      m={5}
      borderRadius="25px"
      color="grey">
      <AccordionItem border="none">
        <AccordionButton p={0}>
          <Box flex="1" textAlign="left">
            SEARCH FILTERS
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Formik
            initialValues={{
              ModuleName: currentFilters.ModuleName || '',
              ActionType: currentFilters.ActionType || '',
              UpdatedBy: currentFilters.UpdatedBy || '',
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              const searchFilters = {};
              if (values.ModuleName !== '') {
                searchFilters.ModuleName = values.ModuleName;
              }
              if (values.ActionType !== '') {
                searchFilters.ActionType = values.ActionType;
              }
              if (values.UpdatedBy !== '') {
                searchFilters.UpdatedBy = values.UpdatedBy;
              }
              if (
                values.AmendedDateRange &&
                values.AmendedDateRange.length === 2
              ) {
                searchFilters.DateRangeStart = values.AmendedDateRange[0];
                searchFilters.DateRangeEnd = values.AmendedDateRange[1];
              }
              setSearchFilters(searchFilters);
              actions.setSubmitting(false);
            }}>
            {props => (
              <Form>
                <Grid
                  templateColumns="repeat(5, 1fr)"
                  gap={3}
                  maxW="fit-content">
                  <Box>
                    <Field name="ModuleName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.ModuleName && form.touched.ModuleName
                          }>
                          <Select {...field} defaultValue="">
                            <option value="">All</option>
                            <option value="Address Book">Address Book</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.ModuleName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="ActionType">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.ActionType && form.touched.ActionType
                          }>
                          <Select {...field} defaultValue="">
                            <option value="">All</option>
                            <option value="Add">Add</option>
                            <option value="Update">Update</option>
                            <option value="Delete">Delete</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.ActionType}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="UpdatedBy">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.UpdatedBy && form.touched.UpdatedBy
                          }>
                          <Input
                            {...field}
                            id="UpdatedBy"
                            placeholder="Amended By"
                            d="inline"
                          />
                          <FormErrorMessage>
                            {form.errors.UpdatedBy}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="AmendedDateRange">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.AmendedDateRange &&
                            form.touched.AmendedDateRange
                          }>
                          <DateRange {...field} onChange={null} />
                          <FormErrorMessage>
                            {form.errors.AmendedDateRange}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Button
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="submit">
                      <Search2Icon />
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UpdatedHistorySearchFilters;
