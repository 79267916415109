import { Box, Select, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getDepartments } from '../AddressBook/Helper/RetreiveDataHelper';
import AddressContextStore from '../Store/AddressContextStore';
import UserStore from '../Store/UserStore';

function DepartmentDropdown(props) {
  const userBuilding = UserStore.useStoreState((state) => state.building);

  const userDepartment = UserStore.useStoreState((state) => state.department);
  const [departments, setDepartments] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const {
    selectedOutlet,
    selectedDepartment,
    setSelectedDepartment,
    setSelectedOutlet,
    loadId,
    isLoading,
    setIsLoading,
  } = props;

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setSelectedDepartment(
        userBuilding.code === 'HQ' ? userDepartment.code : ''
      );

      const { renderedValues, mappedResponse } = await getDepartments(false, {
        status: 'active',
      });

      setDepartmentOptions(renderedValues);
      setDepartments(mappedResponse);
      setIsLoading(false);
    }
    fetchData();

    return () => {
      setDepartmentOptions([]);
      setDepartments([]);
    };
  }, []);

  function retrieveSelectedDepartment(code) {
    return departments.find((department) => department.departmentCode === code);
  }

  const fillAddress = AddressContextStore.useStoreActions(
    (actions) => actions.fillAddress
  );

  useEffect(() => {
    if ((selectedOutlet === 'HQ' || selectedOutlet === '') && loadId === 2) {
      const department = retrieveSelectedDepartment(selectedDepartment);
      fillAddress({ ...department, loadId });
    }
  }, [selectedOutlet, departments]);

  return isLoading ? (
    <Box fontSize="lg" flex="1" textAlign="center">
      <Spinner size="lg" />
    </Box>
  ) : (
    <Select
      placeholder="Department"
      onChange={(e) => {
        if (e.target.value.length > 0) {
          setSelectedDepartment(e.target.value);
          const department = retrieveSelectedDepartment(e.target.value);
          fillAddress({ ...department, loadId });
        } else {
          setSelectedDepartment('');
          fillAddress({
            address: '',
            city: '',
            state: '',
            postcode: '',
            country: 'Malaysia',
            autofilled: false,
          });
        }
        setSelectedOutlet('');
      }}
      value={selectedDepartment}
    >
      {departmentOptions}
    </Select>
  );
}

export default DepartmentDropdown;
