import { Button, Center, Text } from "@chakra-ui/react";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { useRef, useState, useEffect } from "react";
import A6Paper from "./A6Paper";
import A8Paper from "./A8Paper";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Cookies from "universal-cookie/es6";

function PreviewShippingLabels() {
  document.title = "Tomei Logistic - Preview Shipping Labels";
  const componentRef = useRef();
  const componentRefTwo = useRef();
  const [details, setDetails] = useState({
    TrackingNo: "",
    CreatedDate: "",
    Status: "",
    SenderName: "",
    SenderContact: "",
    SenderStreet: "",
    SenderCity: "",
    SenderState: "",
    SenderPostcode: "",
    SenderCountry: "",
    ReceiverName: "",
    ReceiverContact: "",
    ReceiverDepartment_Code: "",
    ReceiverDepartment: "",
    ReceiverBuilding_Code: "",
    ReceiverStreet: "",
    ReceiverCity: "",
    ReceiverState: "",
    ReceiverPostcode: "",
    ReceiverCountry: "",
    PerformedBy: "",
    Notes: "",
  });
  const { trackingNo } = useParams();
  const params = new URLSearchParams([["TrackingNo", trackingNo]]);

  useEffect(() => {
    const cookies = new Cookies();
    cookies.remove("tomei-logistics-token", {
      path: "/",
    });
    cookies.remove("tomei-logistics-userID", {
      path: "/",
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/shipping-labels/find-one`, {
        params,
      })
      .then((response) => {
        setDetails(response.data.data);
      });
  }, []);

  const isA6Paper = window.location.pathname.includes("a6") ? true : false;

  return (
    <>
      {isA6Paper ? (
        <>
          <Center>
            <A6Paper componentRef={componentRef} details={details} />
          </Center>
          <Center my={5}>
            <ReactToPrint
              trigger={() => (
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: "inline" }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Center>
        </>
      ) : (
        <>
          <Center>
            <A8Paper componentRef={componentRefTwo} details={details} />
          </Center>
          <Center my={5}>
            <ReactToPrint
              trigger={() => (
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: "inline" }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              content={() => componentRefTwo.current}
            />
          </Center>
        </>
      )}
    </>
  );
}

export default PreviewShippingLabels;
