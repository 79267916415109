import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import A8Paper from './A8Paper';
import A6Paper from './A6Paper';
import axios from 'axios';

function PrintShippingLabelModal(props) {
  const toast = useToast();
  const componentRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    fetchData,
    isLargePrint,
    record,
    showMessage,
    disabled,
    isRequiredToChangeStatus,
  } = props;
  const [details, setDetails] = useState({
    TrackingNo: '',
    CreatedDate: '',
    Status: '',
    SenderName: '',
    SenderContact: '',
    SenderStreet: '',
    SenderCity: '',
    SenderState: '',
    SenderPostcode: '',
    SenderCountry: '',
    ReceiverName: '',
    ReceiverContact: '',
    ReceiverDepartment_Code: '',
    ReceiverDepartment: '',
    ReceiverBuilding_Code: '',
    ReceiverStreet: '',
    ReceiverCity: '',
    ReceiverState: '',
    ReceiverPostcode: '',
    ReceiverCountry: '',
    PerformedBy: '',
    Notes: '',
  });

  let message = null;
  if (showMessage) {
    if (isLargePrint) {
      message = 'PRINT A6 SHIPPING LABEL';
    } else {
      message = 'PRINT A8 SHIPPING LABEL';
    }
  }
  let customOnOpen = async () => {
    const params = new URLSearchParams([['TrackingNo', record.trackingNo]]);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/consignment/find-one`, {
        params,
      })
      .then((response) => {
        setDetails(response.data.data);
        onOpen();
      });
  };
  return (
    <>
      <Tooltip
        label={`Print A${isLargePrint ? '6' : '8'} Shipping Label`}
        bg="gray.600"
        color="white"
        borderRadius={8}
        fontSize="md"
        placement="top"
        hasArrow
      >
        <Button
          onClick={customOnOpen}
          ml={message ? 0 : 3}
          backgroundColor={isLargePrint ? 'orange.400' : 'orange.600'}
          color={isLargePrint ? 'orange.600' : 'orange.400'}
          borderRadius="14px"
          size={props.showMessage ? 'lg' : 'sm'}
          w={props.showMessage && '100%'}
        >
          <FontAwesomeIcon icon={faPrint} />
          {message && <Text m={2}>{message}</Text>}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalCloseButton />
          <ModalBody fontFamily="Arial">
            {isLargePrint ? (
              <A6Paper componentRef={componentRef} details={details} />
            ) : (
              <A8Paper componentRef={componentRef} details={details} />
            )}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <ReactToPrint
              onAfterPrint={async () => {
                if (isRequiredToChangeStatus) {
                  try {
                    const printResponse = await axios.post(
                      `${process.env.REACT_APP_API_URL}/api/consignment/update/printed`,
                      {
                        TrackingNoList: [details.TrackingNo],
                      }
                    );

                    if (printResponse.status === 200) {
                      fetchData();
                    } else {
                      throw new Error(printResponse);
                    }
                  } catch (err) {
                    toast({
                      title: 'Failed to Print Label.',
                      duration: 3000, // 3s
                      isClosable: true,
                      position: 'top-left',
                      status: 'error',
                    });
                  }
                }
              }}
              trigger={() => (
                <Button colorScheme="orange" disabled={disabled}>
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrintShippingLabelModal;
