import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  chakra,
} from '@chakra-ui/react';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import { useState, useEffect, useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import AddressBookSearch from './AddressBookSearch';
import AddressContextStore from '../../Store/AddressContextStore';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Pagination } from '../../Pagination';

function AddressBook(props) {
  const { setSelectedDepartment, setSelectedOutlet, setAddressSource } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [addresses, setAddresses] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const selectedAddressCode = AddressContextStore.useStoreState(
    state => state.receiver.addressCode,
  );

  const fillAddress = AddressContextStore.useStoreActions(
    actions => actions.fillAddress,
  );
  function shorten(address) {
    return address.substring(0, 20) + ' ...';
  }

  const DATA_PER_PAGE = 10;
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'AddressID',
      },
      {
        Header: 'Code',
        accessor: 'AddressCode',
      },
      {
        Header: 'Type',
        accessor: 'AddressType',
      },
      {
        Header: 'Name',
        accessor: 'AddressName',
      },
      {
        Header: 'Address',
        accessor: 'Street',
      },
      {
        Header: 'Postcode',
        accessor: 'Postcode',
      },
      {
        Header: 'City',
        accessor: 'City',
      },
      {
        Header: 'State',
        accessor: 'State',
      },
      {
        Header: 'Country',
        accessor: 'Country',
      },
      {
        Header: 'Status',
        accessor: 'IsActive',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
      },
    ],
    [],
  );

  const data = useMemo(() => addresses, [addresses]);
  const { state, gotoPage, headerGroups, page, pageCount, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetPage: true,
        initialState: {
          hiddenColumns: ['AddressID'],
          pageSize: DATA_PER_PAGE,
          pageIndex: currentPage,
        },
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName];
            const rowTwoColumn = row2.values[columnName];
            if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
                ? 1
                : -1;
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          },
        },
      },
      useSortBy,
      usePagination,
    );

  useEffect(() => {
    async function getAddresses() {
      searchFilters.push(['IsActive', 1]);
      const params = new URLSearchParams(searchFilters);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/addresses`, {
          params,
        })
        .then(response => {
          setAddresses(response.data.data);
        });
    }
    if (isOpen) {
      getAddresses();
    }
  }, [isOpen, searchFilters]);

  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={'#FF7A00'}
        borderRadius={'14px'}
        color={'#FFECCF'}>
        <FontAwesomeIcon icon={faAddressBook} />
        <Text style={{ display: 'inline' }} ml={3}>
          ADDRESS BOOK
        </Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="1200px">
          <ModalHeader
            mt={10}
            textAlign="center"
            fontSize="2xl"
            fontWeight="bold">
            ADDRESS BOOK
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody my={6}>
            <AddressBookSearch setSearchFilters={setSearchFilters} />
            <Pagination
              data={data}
              dataPerPage={DATA_PER_PAGE}
              pageCount={pageCount}
              pageIndex={state.pageIndex}
              handleChange={handleChange}
            />
            <Table>
              <Thead>
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <Th
                        fontSize="lg"
                        fontWeight="bold"
                        p={1.5}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        isNumeric={column.isNumeric}>
                        {column.render('Header')}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody fontSize="md">
                {page.map(row => {
                  const { original: values } = row;
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        if (cell.column.Header === 'Action') {
                          return (
                            <Td {...cell.getCellProps()} p={1.5}>
                              <Button
                                borderRadius="50px"
                                backgroundColor={'blue.500'}
                                color="white"
                                size="sm"
                                isDisabled={
                                  selectedAddressCode === values.AddressCode ||
                                  !values.AddressCode
                                }
                                iconSpacing={1}
                                leftIcon={<CheckIcon />}
                                onClick={() => {
                                  const formattedRecord = {
                                    departmentOrOutlet: values.AddressName,
                                    phone: values.ContactNo,
                                    addressCode: values.AddressCode,
                                    address: values.Street,
                                    department: values.AddressType,
                                    city: values.City,
                                    state: values.State,
                                    postcode: values.Postcode,
                                    country: values.Country,
                                    autofilled: true,
                                  };
                                  fillAddress(formattedRecord);
                                  setAddressSource('addressBook');
                                  setSelectedOutlet('');
                                  setSelectedDepartment('');
                                  setCurrentPage(0);
                                  setSearchFilters([]);
                                  setAddresses([]);
                                  onClose();
                                }}>
                                <Text ml={2} fontSize="sm">
                                  Select
                                </Text>
                              </Button>
                            </Td>
                          );
                        } else if (cell.column.Header === 'Status') {
                          const statusStyle = {
                            borderRadius: '15px',
                            borderStyle: 'solid',
                            borderWidth: '3px',
                            borderColor:
                              cell.value === 1 ? '#33BB51' : '#EC8F8F',
                            color: cell.value === 1 ? '#33BB51' : '#EC8F8F',
                          };
                          return (
                            <Td
                              {...cell.getCellProps()}
                              isNumeric={cell.column.isNumeric}
                              textTransform={'capitalize'}
                              p={1.5}>
                              <Text
                                p={1}
                                style={statusStyle}
                                textAlign="center">
                                {cell.value ? 'Active' : 'Inactive'}
                              </Text>
                            </Td>
                          );
                        } else {
                          return (
                            <Td
                              {...cell.getCellProps()}
                              isNumeric={cell.column.isNumeric}
                              p={1.5}>
                              <Text>
                                {cell.column.Header === 'Address'
                                  ? shorten(cell.value)
                                  : cell.render('Cell')}
                              </Text>
                            </Td>
                          );
                        }
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddressBook;
