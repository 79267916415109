export function onInput(e) {
  e.target.setCustomValidity("");
}

export function onInvalid(e) {
  e.target.setCustomValidity("This info is required!");
}

export function onInvalidCode(e) {
  e.target.setCustomValidity("Code must not be empty and have no space!");
}

export function onInvalidContact(e) {
  if(e.target.required && e.target.value.length === 0) {
    let fieldName = e.target.id.charAt(0).toUpperCase() + e.target.id.slice(1);
  e.target.setCustomValidity(`${fieldName} must not be empty!`);
  } else {
    let fieldName = e.target.id.charAt(0).toUpperCase() + e.target.id.slice(1);
    e.target.setCustomValidity(`${fieldName} must number only!`);  
  }
}