import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import AddressBook from './AddressBook/AddressBook';
import Consignment from './Consignment/Consignment';
import ConsignmentListStore from './Store/ConsignmentListStore';
import Login from './Login';
import MobileViewConsignment from './Consignment/MobileViewConsignment';
import PreviewShippingLabels from './Consignment/PreviewShippingLabels';
import React, { useEffect, useState } from 'react';
import Scan from './Scan/Scan';
import SideMenu from './SideMenu';
import UserRole from './UserRole/UserRole';
import UserStore from './Store/UserStore';
import UpdatedHistory from './UpdatedHistory';
import CreateConsignmentByPronto from './Consignment/CreateConsignmentByPronto';
import AddressContextStore from './Store/AddressContextStore';
import Dashboard from './Dashboard';
import Dashboard2 from './Dashboard-2';
import QueryString from 'query-string';
import axios from 'axios';
import Cookies from 'universal-cookie/es6';
import { useToast } from '@chakra-ui/react';
import withNetworkDetector from './Hoc/NetworkDetector';

function ProfileFetchLoader({ children }) {
  const logisticToken = new Cookies().get('tomei-logistics-token');

  return logisticToken ? children : 'Authenticating...';
}

function App() {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const logisticToken = new Cookies().get('tomei-logistics-token');

  const { code } = QueryString.parse(location.search);

  useEffect(() => {
    if (code) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
          code,
        })
        .then((response) => {
          const cookies = new Cookies();

          cookies.set('tomei-logistics-token', response.data.access_token, {
            path: '/',
            expires: new Date(Date.now() + response.data.duration * 1000),
            sameSite: 'lax',
          });

          cookies.set('tomei-logistics-userID', response.data.user_info.id, {
            path: '/',
            expires: new Date(Date.now() + response.data.duration * 1000),
            sameSite: 'lax',
          });

          history.push('/');
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: 'Failed to authenticate user.',
            duration: 5000, // 5s
            isClosable: true,
            position: 'bottom-left',
            status: 'error',
            onCloseComplete: () => {
              history.push('/login');
            },
          });
        });
    } else {
      if (!logisticToken) {
        history.push('/login');
      }
    }
  }, [logisticToken]);

  const [isMenuClosed, setMenuClosed] = useState(false);

  return (
    <>
      <UserStore.Provider>
        <Switch>
          <Route exact path="/">
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <Dashboard2 />
            </ProfileFetchLoader>
          </Route>

          <Route exact path="/consignment">
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <ConsignmentListStore.Provider>
                <Consignment
                  isMenuClosed={isMenuClosed}
                  setMenuClosed={setMenuClosed}
                />
              </ConsignmentListStore.Provider>
            </ProfileFetchLoader>
          </Route>

          <Route path={`/dashboard`}>
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <Dashboard2 />
            </ProfileFetchLoader>
          </Route>

          <Route path={`/consignment/shippinglabel/:trackingNo/a6`}>
            <PreviewShippingLabels />
          </Route>
          <Route path={`/consignment/shippinglabel/:trackingNo/a8`}>
            <PreviewShippingLabels />
          </Route>
          <Route path={`/consignment/new`}>
            <ProfileFetchLoader>
              <AddressContextStore.Provider>
                <SideMenu
                  isMenuClosed={isMenuClosed}
                  setMenuClosed={setMenuClosed}
                />
                <CreateConsignmentByPronto
                  isMenuClosed={isMenuClosed}
                  setMenuClosed={setMenuClosed}
                />
              </AddressContextStore.Provider>
            </ProfileFetchLoader>
          </Route>
          <Route path={`/consignment/:trackingNo`}>
            <MobileViewConsignment />
          </Route>

          <Route path={`/scan`}>
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <Scan isMenuClosed={isMenuClosed} setMenuClosed={setMenuClosed} />
            </ProfileFetchLoader>
          </Route>
          <Route path={`/config/address`}>
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <AddressBook
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
            </ProfileFetchLoader>
          </Route>
          <Route path={`/config/updated-history`}>
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <UpdatedHistory
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
            </ProfileFetchLoader>
          </Route>
          <Route path={`/config/role`}>
            <ProfileFetchLoader>
              <SideMenu
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
              <UserRole
                isMenuClosed={isMenuClosed}
                setMenuClosed={setMenuClosed}
              />
            </ProfileFetchLoader>
          </Route>
          <Route path={'/login'}>
            <Login />
          </Route>
        </Switch>
      </UserStore.Provider>
    </>
  );
}

export default withNetworkDetector(App);
