import {
  Container,
  Stack,
  Table,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Spinner,
} from '@chakra-ui/react';
import { getBorder } from './Helper';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import CancelModal from './CancelModal';
import PrintListingModal from './PrintListingModal';
import PrintMultipleModal from './PrintMultipleModal';
import PrintShippingLabelModal from './PrintShippingLabelModal';
import ViewConsignment from './ViewConsignment';
import { Pagination } from '../Pagination';
import UserStore from '../Store/UserStore';
import ExportCSV from './ExportCSV';

function ConsignmentTable(props) {
  const {
    data,
    fetchData,
    state,
    gotoPage,
    setPageSize,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    isLoading,
    totalCount,
    selectedFlatRows,
  } = props;

  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };

  const { roles, staffId } = UserStore.useStoreState((state) => state);

  const renderLoading = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 25,
        flexDirection: 'column',
      }}
    >
      <Spinner size="lg" marginBottom={2} />
      <Text>Loading...</Text>
    </div>
  );

  const renderTableBody = () => (
    <Tbody fontSize="md">
      {page.map((row, index) => {
        const { values } = row;
        prepareRow(row);
        return (
          <Tr {...row.getRowProps()} key={index}>
            {row.cells.map((cell, key) => {
              if (cell.column.Header === 'Tracking No.') {
                return (
                  <Td p={1.5} key={key}>
                    <ViewConsignment
                      fetchData={fetchData}
                      trackingNo={values.trackingNo}
                    />
                  </Td>
                );
              } else if (cell.column.Header === 'Action') {
                let disabled = true;
                if (
                  values.status === 'consignment created' ||
                  values.status === 'shipping label printed'
                ) {
                  if (roles.includes('admin')) {
                    disabled = false;
                  } else if (staffId === values.staffId) {
                    disabled = false;
                  }
                }
                return (
                  <Td {...cell.getCellProps()} p={1.5}>
                    <Stack spacing={1} direction="row">
                      <PrintShippingLabelModal
                        fetchData={fetchData}
                        record={values}
                        disabled={
                          values.status === 'consignment cancelled' ||
                          values.status === 'item received by 3rd party' ||
                          values.status === 'item delivered'
                        }
                        isRequiredToChangeStatus={
                          values.status === 'consignment created'
                        }
                      />
                      <PrintShippingLabelModal
                        fetchData={fetchData}
                        isLargePrint={true}
                        record={values}
                        disabled={
                          values.status === 'consignment cancelled' ||
                          values.status === 'item received by 3rd party' ||
                          values.status === 'item delivered'
                        }
                        isRequiredToChangeStatus={
                          values.status === 'consignment created'
                        }
                      />
                      <CancelModal
                        trackingNo={values.trackingNo}
                        fetchData={fetchData}
                        disabled={disabled}
                      />
                    </Stack>
                  </Td>
                );
              } else if (cell.column.Header === 'Status') {
                const statusStyle = {
                  borderRadius: '15px',
                  borderStyle: 'solid',
                  borderWidth: '3px',
                  borderColor: getBorder(cell.value),
                  color: getBorder(cell.value),
                };
                return (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                    textTransform={'capitalize'}
                    p={1.5}
                  >
                    <Text p={1} style={statusStyle} textAlign="center">
                      {cell.render('Cell')}
                    </Text>
                  </Td>
                );
              } else {
                return (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                    p={1.5}
                  >
                    <Container maxW="3xs" m={0} p={0}>
                      <Text> {cell.render('Cell')}</Text>
                    </Container>
                  </Td>
                );
              }
            })}
          </Tr>
        );
      })}
    </Tbody>
  );

  return (
    <>
      <Pagination
        data={data}
        dataPerPage={state.pageSize}
        totalCount={totalCount}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        handleChange={handleChange}
        setPageSize={setPageSize}
      />
      <Table>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, key) => (
                <Th
                  key={key}
                  fontSize="lg"
                  fontWeight="bold"
                  p={1.5}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render('Header')}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {!isLoading && renderTableBody()}
      </Table>
      {isLoading && renderLoading()}
      {!isLoading && (
        <PrintListingModal
          trackingData={data}
          paginationState={state}
          source={'LISTING'}
        />
      )}
      {!isLoading && selectedFlatRows.length > 0 && (
        <PrintMultipleModal
          printData={selectedFlatRows}
          type="A8"
          fetchData={fetchData}
        />
      )}
      {!isLoading && selectedFlatRows.length > 0 && (
        <PrintMultipleModal
          printData={selectedFlatRows}
          type="A6"
          fetchData={fetchData}
        />
      )}
      {!isLoading && selectedFlatRows.length > 0 && (
        <PrintListingModal
          trackingData={data}
          paginationState={state}
          source={'DISPATCH LIST'}
          printData={selectedFlatRows}
        />
      )}
      {!isLoading && <ExportCSV data={data} />}
    </>
  );
}

export default ConsignmentTable;
