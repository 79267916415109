import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import DeleteModal from "./DeleteModal";
import CreateOrUpdate from "./CreateOrUpdate";

function ViewAddress(props) {
  const { data, refreshList, renderComponent } = props;
  const {
    AddressID,
    AddressCode,
    AddressType,
    AddressName,
    Street,
    Postcode,
    City,
    State,
    Country,
    IsActive,
    ContactNo,
  } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const activeStyling = {
    borderRadius: "15px",
    borderStyle: "solid",
    borderWidth: "3px",
    borderColor: IsActive === 1 ? "green" : "red",
    color: IsActive === 1 ? "green" : "red",
  };

  let customClose = () => {
    refreshList();
    onClose();
  };

  return (
    <>
      {renderComponent(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="700px" p={10}>
          <ModalHeader textAlign="center" fontSize="3xl">
            <Text>VIEW DETAILS</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} mt={3}>
            <Grid
              templateRows="repeat(3, 1fr)"
              templateColumns="repeat(2, 1fr)"
              p={5}
              mb={5}
              gap={5}
            >
              <GridItem colSpan={1} p={0}>
                <Heading size="sm">ADDRESS CODE</Heading>
                <Text fontSize="lg">{AddressCode}</Text>
              </GridItem>
              <GridItem colSpan={1} p={0}>
                <Heading size="sm">ADDRESS TYPE</Heading>
                <Text fontSize="lg">{AddressType}</Text>
              </GridItem>
              <GridItem colSpan={1} p={0}>
                <Heading size="sm">CONTACT / EXT NO.</Heading>
                <Text fontSize="lg">{ContactNo}</Text>
              </GridItem>
              <GridItem colSpan={1} p={0}>
                <Heading size="sm" pb={2}>
                  STATUS
                </Heading>
                <Text
                  fontSize="lg"
                  textTransform="capitalize"
                  w="fit-content"
                  px={4}
                  style={activeStyling}
                >
                  {IsActive === 1 ? "Active" : "Inactive"}
                </Text>
              </GridItem>
              <GridItem colSpan={1} p={0}>
                <Heading size="sm">ADDRESS NAME</Heading>
                <Text fontSize="lg">{AddressName}</Text>
              </GridItem>
              <GridItem colSpan={1} p={0}>
                <Heading size="sm">ADDRESS</Heading>
                <Text fontSize="lg">{Street}</Text>
                <Text fontSize="lg">
                  {City}, {State}
                </Text>
                <Text fontSize="lg">
                  {Postcode}, {Country}
                </Text>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" color="white">
            <CreateOrUpdate
              oldData={data}
              onClose={customClose}
              showMessage={true}
            />
            <DeleteModal
              id={AddressID}
              code={AddressCode}
              onClose={customClose}
              showMessage={true}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewAddress;
