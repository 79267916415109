import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form } from "formik";
import { BiSave } from "react-icons/bi";
import { AddIcon } from "@chakra-ui/icons";
import { createCustomAxios } from "../axiosInterceptor";
import {
  onInvalid,
  onInput,
  onInvalidCode,
  onInvalidContact,
} from "../Helper/FormValidatorHelper";

const STATES = [
  "Johor",
  "Kedah",
  "Kelantan",
  "Kuala Lumpur",
  "Labuan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Perak",
  "Perlis",
  "Pulau Pinang",
  "Putrajaya",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terrenganu",
  "Others",
];

function CreateOrUpdate(props) {
  const { oldData, showMessage } = props;
  const axios = createCustomAxios();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  let customClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    onClose();
  };

  let createAddress = async (payload) => {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/api/addresses`, payload)
      .then(async (response) => {
        if (response.data.code === 200) {
          toast({
            title: "Save Sucessful",
            description: `Address ${payload.AddressCode} created successfully`,
            isClosable: true,
            position: "bottom-left",
            status: "success",
          });
          return true;
        } else {
          throw response;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  let updateAddress = async (payload) => {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/addresses/${oldData.AddressID}`,
        payload
      )
      .then((response) => {
        if (response.data.code === 200) {
          toast({
            title: "Save Sucessful",
            description: `Address ${payload.AddressCode} updated successfully`,
            isClosable: true,
            position: "bottom-left",
            status: "success",
          });
          return true;
        } else {
          throw response;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  return (
    <>
      <Tooltip
        label={oldData ? "Edit Address" : "Create New Address"}
        bg="gray.600"
        color="white"
        borderRadius={8}
        fontSize="md"
        placement="top"
        isDisabled={props.disabled}
        hasArrow
      >
        <Button
          backgroundColor={oldData ? "orange.400" : "#6e26c7"}
          borderRadius="14px"
          color={oldData ? "orange.700" : "white"}
          leftIcon={!oldData && <AddIcon />}
          onClick={onOpen}
          size={oldData ? "sm" : "md"}
          variant="solid"
        >
          {oldData ? (
            <>
              <FontAwesomeIcon icon={faPencilAlt} />
              {showMessage && <Text ml={2}>UPDATE</Text>}
            </>
          ) : (
            <>
              <Text>CREATE NEW</Text>
            </>
          )}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="700px" p={10}>
          <ModalHeader>
            <Center fontSize="3xl">{oldData ? "UPDATE" : "CREATE NEW"}</Center>
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              active: oldData?.IsActive === 0 ? "inactive" : "active",
              street: oldData?.Street || "",
              city: oldData?.City || "",
              code: oldData?.AddressCode || "",
              contact: oldData?.ContactNo || "",
              country: oldData?.Country || "Malaysia",
              name: oldData?.AddressName || "",
              postCode: oldData?.Postcode || "",
              state: oldData?.State
                ? STATES.includes(oldData.State)
                  ? oldData.State
                  : "Others"
                : "",
              type: oldData?.AddressType || "Customer",
              otherState: oldData?.State
                ? STATES.includes(oldData.State)
                  ? ""
                  : oldData.State
                : "",
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              const payload = {
                IsActive: values.active === "active" ? 1 : 0,
                Street: values.street,
                City: values.city,
                AddressCode: values.code,
                ContactNo: values.contact,
                Country: values.country,
                AddressName: values.name,
                Postcode: values.postCode,
                State:
                  values.state === "Others" ? values.otherState : values.state,
                AddressType: values.type,
              };
              let isSuccess = false;
              if (props.oldData != null) {
                isSuccess = await updateAddress(payload);
              } else {
                isSuccess = await createAddress(payload);
              }

              setSubmitting(false);
              if (isSuccess) {
                customClose();
              }
            }}
          >
            {(props) => (
              <Form>
                <ModalBody pb={6}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={50} h="100%">
                    <GridItem colSpan={1}>
                      <Field name="code">
                        {({ field, form }) => (
                          <>
                            <FormControl isRequired>
                              <FormLabel htmlFor="code">ADDRESS CODE</FormLabel>
                              <Input
                                {...field}
                                id="code"
                                onInput={onInput}
                                onInvalid={onInvalidCode}
                                pattern="^\S+$"
                                disabled={!!oldData?.AddressCode}
                              />
                              <FormErrorMessage>
                                {form.errors.code}
                              </FormErrorMessage>
                            </FormControl>
                          </>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Field name="type">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="type">ADDRESS TYPE</FormLabel>
                            <Select {...field} placeholder="- Select -">
                              <option value="HQ">HQ</option>
                              <option value="Branch">Branch</option>
                              <option value="Outlet">Outlet</option>
                              <option value="Customer">Customer</option>
                              <option value="Mall">Mall</option>
                              <option value="Others">Others</option>
                            </Select>
                            <FormErrorMessage>
                              {form.errors.type}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl isRequired mb="20px" mt="20px">
                        <FormLabel htmlFor="name">ADDRESS NAME</FormLabel>
                        <Input {...field} id="name" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="contact">
                    {({ field, form }) => (
                      <FormControl isRequired mb="20px">
                        <FormLabel htmlFor="contact">
                          CONTACT / EXT NO.{" "}
                        </FormLabel>
                        <Input
                          {...field}
                          id="contact"
                          type="text"
                          pattern="^[0-9]+"
                          onInput={onInput}
                          onInvalid={onInvalidContact}
                        />
                        <FormErrorMessage>
                          {form.errors.contact}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="street">
                    {({ field, form }) => (
                      <FormControl isRequired mb={6}>
                        <FormLabel htmlFor="street">ADDRESS</FormLabel>
                        <Textarea {...field} borderRadius="13px" />
                        <FormErrorMessage>
                          {form.errors.street}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Grid templateColumns="repeat(2, 1fr)" gap={50} mb={6}>
                    <GridItem colSpan={1}>
                      <Field name="postCode">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="postCode">POSTCODE</FormLabel>
                            <Input {...field} id="postCode" />
                            <FormErrorMessage>
                              {form.errors.postCode}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Field name="city">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="city">CITY</FormLabel>
                            <Input {...field} id="city" />
                            <FormErrorMessage>
                              {form.errors.city}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={50}
                    rowGap={5}
                    mb={6}
                  >
                    <GridItem colSpan={1}>
                      <Field name="state">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="state">STATE</FormLabel>
                            <Select {...field} placeholder="- Select -">
                              {STATES.map((value) => (
                                <option value={value}>{value}</option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.state}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Field name="country">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="state">COUNTRY</FormLabel>
                            <Select {...field} placeholder="- Select -">
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Aland Islands">
                                Aland Islands
                              </option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bonaire, Sint Eustatius and Saba">
                                Bonaire, Sint Eustatius and Saba
                              </option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos (Keeling) Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo, Democratic Republic of the Congo">
                                Congo, Democratic Republic of the Congo
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote D'Ivoire">
                                Cote D'Ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Curacao">Curacao</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and Mcdonald Islands">
                                Heard Island and Mcdonald Islands
                              </option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran, Islamic Republic of">
                                Iran, Islamic Republic of
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea, Democratic People's Republic of">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea, Republic of">
                                Korea, Republic of
                              </option>
                              <option value="Kosovo">Kosovo</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia, the Former Yugoslav Republic of">
                                Macedonia, the Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova, Republic of">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory, Occupied">
                                Palestinian Territory, Occupied
                              </option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">
                                Russian Federation
                              </option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Barthelemy">
                                Saint Barthelemy
                              </option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Martin">Saint Martin</option>
                              <option value="Saint Pierre and Miquelon">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="Saint Vincent and the Grenadines">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Serbia and Montenegro">
                                Serbia and Montenegro
                              </option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Sint Maarten">Sint Maarten</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia and the South Sandwich Islands">
                                South Georgia and the South Sandwich Islands
                              </option>
                              <option value="South Sudan">South Sudan</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen">
                                Svalbard and Jan Mayen
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania, United Republic of">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-Leste">Timor-Leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, British">
                                Virgin Islands, British
                              </option>
                              <option value="Virgin Islands, U.s.">
                                Virgin Islands, U.s.
                              </option>
                              <option value="Wallis and Futuna">
                                Wallis and Futuna
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </Select>
                            <FormErrorMessage>
                              {form.errors.country}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    {props?.values?.state === "Others" && (
                      <GridItem colSpan={2}>
                        <Field name="otherState">
                          {({ field, form }) => (
                            <FormControl isRequired>
                              <FormLabel htmlFor="otherState">
                                SPECIFY STATE
                              </FormLabel>
                              <Input {...field} id="otherState" />
                              <FormErrorMessage>
                                {form.errors.otherState}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    )}
                    <GridItem colSpan={1}>
                      <Field name="active">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.active && form.touched.active
                            }
                          >
                            <FormLabel htmlFor="active" fontSize="md">
                              STATUS
                            </FormLabel>
                            <Select {...field} placeholder="- Select -">
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                            </Select>
                            <FormErrorMessage>
                              {form.errors.active}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                  <ModalFooter>
                    <Button
                      isLoading={props.isSubmitting}
                      type="submit"
                      py={4}
                      px={10}
                      borderRadius={10}
                      backgroundColor="#6e26c7"
                      color="white"
                    >
                      <BiSave size={30} />
                      <Text fontSize="lg" style={{ display: "inline" }} ml={3}>
                        SAVE
                      </Text>
                    </Button>
                  </ModalFooter>
                </ModalBody>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateOrUpdate;
