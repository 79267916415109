import {
  Text,
  Container,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import React from "react";
import AssignRoleModal from "./AssignRoleModal";
import { Pagination } from "../Pagination";

function UserRoleTable(props) {
  const {
    data,
    dataPerPage,
    state,
    headerGroups,
    page,
    handleChange,
    pageCount,
    prepareRow,
  } = props;

  return (
    <>
      <Pagination
        data={data}
        dataPerPage={dataPerPage}
        pageCount={pageCount}
        pageIndex={state.pageIndex}
        handleChange={handleChange}
      />
      <Table variant="unstyled">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  fontSize="lg"
                  fontWeight="bold"
                  p={1.5}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody fontSize="md">
          {page.map((row) => {
            const { values } = row;
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Staff Id") {
                    return (
                      <Td p={1.5}>
                        <AssignRoleModal
                          id={values.id}
                          staffId={values.staff_id}
                        />
                      </Td>
                    );
                  } else if (cell.column.Header === "Action") {
                    return (
                      <Td {...cell.getCellProps()} p={1.5}>
                        <Stack spacing={1} direction="row">
                          <AssignRoleModal
                            isButton={true}
                            id={values.id}
                            staffId={values.staff_id}
                          />
                        </Stack>
                      </Td>
                    );
                  } else {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                        p={1.5}
                      >
                        <Container maxW="3xs" m={0} p={0}>
                          <Text> {cell.render("Cell")}</Text>
                        </Container>
                      </Td>
                    );
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

export default UserRoleTable;
