import React from 'react';
import { Button, Text } from '@chakra-ui/react';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

function ExportCSV({ data }) {
  const exportToCSV = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, filename);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleClick = () => {
    let formatedData = data.map((item, i) => {
      let newItem = {
        'NO.': i + 1,
        'TRACKING NO.': item.trackingNo,
        'CREATED DATE': item.createdDate,
        'SENDER NAME': item.sender,
        'RECIEPIENT NAME': item.recipient,
        'PERFORMED BY': item.performedBy,
        STATUS: item.status,
        'DEPT REF NO.': item.deptRefNo,
        'PRONTO REF NO.': item.refNo,
        QUANTITY: item.quantity,
        NOTES: item.notes,
      };
      return newItem;
    });
    exportToCSV(formatedData, 'consignment-listing.xlsx');
  };

  return (
    <Button
      onClick={handleClick}
      backgroundColor={'blue.400'}
      color="white"
      mt={5}
      ml={2}
      px={5}
      size="sm"
      borderRadius="14px"
    >
      <FontAwesomeIcon icon={faPrint} />
      <Text ml={2}>EXPORT TO EXCEL</Text>
    </Button>
  );
}

export default ExportCSV;
