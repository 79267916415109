import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  Grid,
  GridItem,
  Heading,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { Formik, Field, Form } from 'formik';
import PrintShippingLabelModal from './PrintShippingLabelModal';
import CancelModal from './CancelModal';
import { BiSave } from 'react-icons/bi';
import Barcode from 'react-barcode';
import ConsignmentActivityHistory from './ConsignmentActivityHistory';
import { getBorder } from './Helper';
import moment from 'moment';
import UserStore from '../Store/UserStore';
import { checkHasRoles } from '../utils';
import { createCustomAxiosWithoutErrorHandling } from '../axiosInterceptor';

function ViewConsignment(props) {
  const { fetchData, history, isInTrackingPage, trackingNo } = props;
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !!isInTrackingPage,
  });
  const toast = useToast();
  const [notesDisabled, setNotesDisabled] = useState(true);
  const [activityHistory, setActivityHistory] = useState([]);
  const [details, setDetails] = useState({
    ConsignmentID: '',
    TrackingNo: '',
    CreatedDate: '',
    Status: '',
    StatusDescription: '',
    SenderName: '',
    SenderContact: '',
    SenderAddressCode: '',
    SenderDepartmentCode: '',
    SenderDepartmentOrOutlet: '',
    SenderStreet: '',
    SenderCity: '',
    SenderState: '',
    SenderPostcode: '',
    SenderCountry: '',
    ReceiverName: '',
    ReceiverContact: '',
    ReceiverAddressCode: '',
    ReceiverDepartmentCode: '',
    ReceiverDepartmentOrOutlet: '',
    ReceiverStreet: '',
    ReceiverCity: '',
    ReceiverState: '',
    ReceiverPostcode: '',
    ReceiverCountry: '',
    ReceiverNRIC: '',
    ReceiverCompany: '',
    ReceiverSignature: '',
    PerformedByFullName: '',
    DepartmentRefNo: '',
    ProntoTransferNo: '',
    ParcelCount: 1,
    Notes: '',
    IsCancelled: false,
    SenderID: '',
    PerformByStaffID: '',
    ActivityDatetime: '',
  });
  details.StatusDescription = details?.StatusDescription?.toLocaleLowerCase();
  const userRoles = UserStore.useStoreState((state) => state.roles);

  let getStatusMessage = (status) => {
    let result;
    if (activityHistory?.length) {
      result = activityHistory.filter(
        (activity) => activity.ActivityType.toLowerCase() === status
      )[0];
    }
    if (result) {
      if (status === 'created') {
        return `Consignment created by ${result.PerformedByFullName} and pending to print shipping label`;
      } else if (status === 'cancelled') {
        return `Consignment cancelled on a ${moment(
          result.ActivityDatetime
        ).format('YYYY-MM-DD hh:mm:ss A')} by ${result.PerformedByFullName}`;
      } else if (status === 'printed') {
        return `Consignment shipping label printed by ${result.PerformedByFullName} and pending for delivery`;
      } else if (status === 'arrived') {
        return `The item is currently being sorted by the logistic team at the sorting center`;
      } else if (status === 'delivery') {
        return 'The item has been picked up and out for delivery';
      } else if (status === 'transit') {
        return 'The item has been collected from sender and will transfer to Sorting Center';
      } else if (status === 'delivered') {
        // return `The item has been delivered and received by ${result.PerformedByFullName} from ${details?.ReceiverDepartmentOrOutlet} / ${details?.ReceiverAddressCode}.`;
        if (result.PerformedByBuildingCode === 'HQ') {
          return `The item has been delivered and received by ${result.PerformedByFullName} from ${result.PerformedByDeptName} / ${result.PerformedByBuildingCode}.`;
        } else {
          return `The item has been delivered and received by ${result.PerformedByFullName} from ${result.PerformedByBuildingName} / ${result.PerformedByBuildingCode}.`;
        }
      } else if (status === 'received') {
        return `The item has been delivered by ${result.PerformedByFullName} and received by ${result.ReceiverName} from ${result.ReceiverCompany}.`;
      }
    }
  };

  const axios = createCustomAxiosWithoutErrorHandling();

  async function saveNotes(notes) {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/consignments/${details?.TrackingNo}`,
        {
          Notes: notes,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: 'Update Sucessful',
            description: `Notes is updated`,
            isClosable: true,
            position: 'bottom-left',
            status: 'success',
          });
        } else {
          throw response;
        }
      });
  }
  async function getConsignment() {
    const params = new URLSearchParams([['TrackingNo', trackingNo]]);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/consignment/find-one`, {
        params,
      })
      .then(async (response) => {
        setDetails(response.data.data);
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/consignment/history/find-one`,
            {
              TrackingNo: trackingNo,
            }
          )
          .then((response) => {
            setActivityHistory(response.data.data);
          })
          .catch((error) => {
            console.log('/history/find-one error');
            console.error(error?.response);
          });
      })
      .catch((error) => {
        console.log('/consignment/find-one error');
        console.error(error?.response);

        toast({
          title: 'Failed',
          description: `An error occurred while getting the consingment detail.`,
          duration: null,
          isClosable: true,
          position: 'bottom-left',
          status: 'error',
        });
        onClose();
      });
  }

  useEffect(() => {
    if (isOpen) {
      getConsignment();
    }
  }, [isOpen]);

  let disabled = true;
  if (
    details?.Status.toLowerCase() === 'created' &&
    details?.Status.toLowerCase() === 'printed'
  ) {
    if (userRoles.roles.includes('admin')) {
      disabled = false;
    } else if (userRoles.staffId === details?.PerformByStaffID) {
      disabled = false;
    }
  }

  return (
    <>
      {!isInTrackingPage && (
        <Text
          textAlign="left"
          height="100%"
          onClick={onOpen}
          size="lg"
          variant="solid"
          color="#6e26c7"
          fontWeight="bolder"
          as="u"
          cursor="pointer"
        >
          {props.trackingNo}
        </Text>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          if (history) {
            history.push('/');
          }
        }}
      >
        <ModalOverlay />
        <ModalContent minW="950px" p={30}>
          <ModalHeader textAlign="center" fontSize="3xl">
            VIEW
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid
              h="100px"
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(5, 1fr)"
              gap={5}
              pt={5}
              mb={10}
            >
              <GridItem rowSpan={2} colSpan={2} pl={2}>
                <Heading size="md">TRACKING NUMBER</Heading>
                <Barcode value={details?.TrackingNo} width={1.5} />
              </GridItem>
              <GridItem colSpan={2}>
                <Heading size="md" mb={2}>
                  STATUS
                </Heading>
                <Text
                  borderColor={getBorder(details?.StatusDescription)}
                  borderRadius="25px"
                  borderStyle="solid"
                  borderWidth="3px"
                  color={getBorder(details?.StatusDescription)}
                  fontWeight="bold"
                  textAlign="center"
                  textTransform="capitalize"
                >
                  {details?.StatusDescription}
                </Text>
              </GridItem>
              <GridItem colSpan={1} textAlign="right">
                <Heading size="md" mb={2}>
                  CREATED DATE
                </Heading>
                <Text>
                  {new Date(details?.CreatedDate).toLocaleDateString('en-GB')}
                </Text>
              </GridItem>
              <GridItem
                colSpan={3}
                pl={5}
                background={'lightgrey'}
                textAlign="center"
              >
                <Text>{getStatusMessage(details?.Status.toLowerCase())}</Text>
              </GridItem>
            </Grid>
            <Box textAlign="right">
              <ConsignmentActivityHistory
                trackingNo={trackingNo}
                details={details}
              />
            </Box>
            <ModalHeader pl={0} pb={3} fontWeight="bold" fontSize="3xl">
              Sender Details
            </ModalHeader>
            <Grid
              minH="fit-content"
              templateRows="repeat(4, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap={1}
              borderRadius="13px"
              border="2px solid lightgrey"
              pl={5}
              pt={5}
              mb={5}
            >
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">NAME</Heading>
                <Text fontSize="xl">{details?.SenderName}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CONTACT / EXT NO. </Heading>
                <Text fontSize="xl">{details?.SenderContact}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CODE / DEPARTMENT / OUTLET</Heading>
                <Text fontSize="xl">
                  {`${details?.SenderAddressCode ?? '-'} / ${
                    details?.SenderDepartmentCode ?? '-'
                  } / ${details?.SenderDepartmentOrOutlet ?? '-'}`}
                </Text>
              </GridItem>
              <GridItem rowSpan={2} colSpan={2} pl={5} pt={5}>
                <Heading size="md">ADDRESS</Heading>
                <Text fontSize="xl">{details?.SenderStreet}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CITY</Heading>
                <Text fontSize="xl">{details?.SenderCity}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">STATE / COUNTRY</Heading>
                <Text fontSize="xl">{`${details?.SenderState} / ${details?.SenderCountry}`}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5}>
                <Heading size="md">POSTCODE</Heading>
                <Text>{details?.SenderPostcode}</Text>
              </GridItem>
            </Grid>
            <ModalHeader pl={0} pb={3} fontWeight="bold" fontSize="3xl">
              Receiver Details
            </ModalHeader>
            <Grid
              minH="fit-content"
              templateRows="repeat(5, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap={1}
              borderRadius="13px"
              border="2px solid lightgrey"
              pl={5}
              pt={5}
              mb={5}
            >
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">NAME</Heading>
                <Text fontSize="xl">{details?.ReceiverName}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CONTACT / EXT NO. </Heading>
                <Text fontSize="xl">{details?.ReceiverContact}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CODE / DEPARTMENT / OUTLET</Heading>
                <Text fontSize="xl">
                  {`${details?.ReceiverAddressCode ?? '-'} / ${
                    details?.ReceiverDepartmentCode ?? '-'
                  } / ${details?.ReceiverDepartmentOrOutlet ?? '-'}`}
                </Text>
              </GridItem>
              <GridItem rowSpan={2} colSpan={2} pl={5} pt={5}>
                <Heading size="md">ADDRESS</Heading>
                <Text fontSize="xl">{details?.ReceiverStreet}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">CITY</Heading>
                <Text fontSize="xl">{details?.ReceiverCity}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5} pt={5}>
                <Heading size="md">STATE / COUNTRY</Heading>
                <Text fontSize="xl">{`${details?.ReceiverState} / ${details?.ReceiverCountry}`}</Text>
              </GridItem>
              <GridItem colSpan={1} pl={5}>
                <Heading size="md">POSTCODE</Heading>
                <Text>{details?.ReceiverPostcode}</Text>
              </GridItem>
            </Grid>

            <Grid mb={6} mt={6}>
              <GridItem rowStart={5} colSpan={1} pl={5}>
                <Heading size="md">DEPARTMENT REF NO.</Heading>
                <Text>{details?.DepartmentRefNo || 'N/A'}</Text>
              </GridItem>
              <GridItem rowStart={5} colSpan={1} pl={5}>
                <Heading size="md">PRONTO REF NO.</Heading>
                <Text>{details?.ProntoTransferNo || 'N/A'}</Text>
              </GridItem>
            </Grid>

            <Formik
              initialValues={{
                Notes: details?.Notes,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                await saveNotes(values.Notes);
                setSubmitting(false);
              }}
              enableReinitialize
            >
              {() => (
                <Form>
                  <Field name="Notes">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.Notes && form.touched.Notes}
                        d="inline-block"
                        mb="20px"
                      >
                        <FormLabel htmlFor="Notes">
                          <Flex>
                            <Box>
                              <Text
                                display="inline"
                                fontSize="2xl"
                                fontWeight="bold"
                              >
                                NOTES
                              </Text>
                            </Box>
                            <Spacer />
                            <Box textColor="#6e26c7">
                              <Button
                                backgroundColor="white"
                                p={0}
                                disabled={
                                  !checkHasRoles(userRoles, [
                                    'consignment notes',
                                  ])
                                }
                                onClick={() => setNotesDisabled(!notesDisabled)}
                              >
                                <HiPencilAlt
                                  size={33}
                                  style={{ display: 'inline' }}
                                />
                              </Button>
                              <Button
                                type="submit"
                                backgroundColor="white"
                                disabled={
                                  !checkHasRoles(userRoles, [
                                    'consignment notes',
                                  ])
                                }
                                p={0}
                              >
                                <BiSave size={30} />
                              </Button>
                            </Box>
                          </Flex>
                          <Spacer />
                        </FormLabel>

                        <Textarea
                          {...field}
                          borderRadius="13px"
                          disabled={notesDisabled}
                          border={notesDisabled && '5px solid black'}
                        />
                        <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={3} px={6} height="50px">
            <Box h="inherit">
              <PrintShippingLabelModal
                fetchData={fetchData}
                record={{
                  trackingNo: details?.TrackingNo,
                }}
                showMessage
                disabled={
                  details?.StatusDescription.toLowerCase() ===
                    'consignment cancelled' ||
                  details?.StatusDescription.toLowerCase() ===
                    'item received by 3rd party' ||
                  details?.StatusDescription.toLowerCase() === 'item delivered'
                }
                isRequiredToChangeStatus={
                  details?.Status.toLowerCase() === 'created'
                }
              />
            </Box>
            <Box h="inherit">
              <PrintShippingLabelModal
                fetchData={fetchData}
                isLargePrint
                record={{
                  trackingNo: details?.TrackingNo,
                }}
                showMessage
                disabled={
                  details?.StatusDescription.toLowerCase() ===
                    'consignment cancelled' ||
                  details?.StatusDescription.toLowerCase() ===
                    'item received by 3rd party' ||
                  details?.StatusDescription.toLowerCase() === 'item delivered'
                }
                isRequiredToChangeStatus={
                  details?.Status.toLowerCase() === 'created'
                }
              />
            </Box>
            <Box h="inherit">
              <CancelModal
                trackingNo={trackingNo}
                showMessage={true}
                getConsignment={getConsignment}
                fetchData={fetchData}
                disabled={disabled}
              />
            </Box>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewConsignment;
