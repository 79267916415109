import React from 'react';
import { Box, Text, Flex, Spacer, useToast } from '@chakra-ui/react';
import AddressContextStore from '../Store/AddressContextStore';
import CreateConsignment from './CreateConsignment';
import SearchFilter from './SearchFilter';
import ConsignmentTable from './ConsignmentTable';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { usePagination, useTable, useSortBy, useRowSelect } from 'react-table';
import ConsignmentListStore from '../Store/ConsignmentListStore';

function Consignment(props) {
  document.title = 'Tomei Logistic';

  const [data, setData] = useState([]);
  const [pageTotal, setPageTotal] = useState([]);
  const [noOfRows, setNoOfRows] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const { saveConsignmentList, setSearchParams } =
    ConsignmentListStore.useStoreActions((actions) => actions);

  const searchParams = ConsignmentListStore.useStoreState(
    (state) => state.searchParams
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, checked, onChange, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate;
        }
      }, [resolvedRef, indeterminate]);

      const handleDivClick = () => {
        if (resolvedRef.current) {
          resolvedRef.current.click();
        }
      };

      return (
        <div
          style={{
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '2.5rem',
            height: '2.5rem',
            cursor: 'pointer',
          }}
          onClick={handleDivClick}
        >
          <input type="checkbox" ref={resolvedRef} checked={checked}
            onChange={onChange} onClick={(e) => e.stopPropagation()} {...rest} />
        </div>
      );
    }
  );


  const [focus, setFocus] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: 'Tracking No.',
        accessor: 'trackingNo',
        minWidth: 10,
        maxWidth: 20,
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        sortType: 'datetime',
        Cell: ({ cell: { value } }) => value.toLocaleDateString('en-GB'),
      },
      {
        Header: 'Sender',
        accessor: 'sender',
      },
      {
        Header: 'Recipient',
        accessor: 'recipient',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Staff Id',
        accessor: 'staffId',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    state,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    setPageSize,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        hiddenColumns: ['id', 'staffId'],
        pageSize: noOfRows,
        pageIndex: 0,
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      },
      manualPagination: true,
      pageCount: Math.ceil(pageTotal / noOfRows),
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          maxWidth: 0,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div >
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {row.values.status === 'consignment created' ||
                row.values.status === 'shipping label printed' ? (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '2.5rem',
                    height: '2.5rem',
                    cursor: 'pointer',
                  }}>
                  <input type="checkbox" disabled />
                </div>
              )}
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  async function fetchData(pageIndex = 0) {
    setIsLoading(true);
    const params = {
      ...searchParams,
      rows: state.pageSize,
      page: pageIndex,
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/consignment/get-all`, {
        params,
      })
      .then(async (response) => {
        const responseData = response?.data?.data?.data.map((consignment) => {
          const performedByCode = consignment.PerformedByBuildingCode !== 'HQ' ? consignment.PerformedByBuildingCode : consignment.PerformedByDeptCode

          return {
            trackingNo: consignment.TrackingNo,
            createdDate: new Date(consignment.CreatedDate),
            sender: consignment.sender.SenderName,
            recipient: consignment.receiver.ReceiverName,
            performedBy: `${performedByCode} - ${consignment.PerformedByName}`,
            staffId: consignment.PerformByStaffID,
            status: consignment.StatusDescription.toLowerCase(),
            refNo: consignment.RefNo,
            deptRefNo: consignment.DepartmentRefNo,
            prontoRefNo: consignment.ProntoTransferNo,
            quantity: consignment.ParcelCount,
            notes: consignment.Notes,
          };
        });
        setPageTotal(response?.data?.data?.count || 0);
        saveConsignmentList({
          data: responseData,
        });
        setData(responseData);
      })
      .catch((error) => {
        console.error(error);

        toast({
          title: 'Error',
          description: 'Failed to fetch table data.',
          status: 'error',
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setNoOfRows(state.pageSize);
  }, [state.pageSize]);

  useEffect(() => {
    fetchData(state.pageIndex);
  }, [searchParams, state.pageIndex, state.pageSize]);

  // useEffect(() => {
  //   fetchData(state.pageIndex);
  // }, [state.pageIndex, state.pageSize]);

  return (
    <Box
      ml={props.isMenuClosed ? '20px' : '270px'}
      p={6}
      flex="1"
      textAlign="left"
      minH="816px"
    >
      <Flex onClick={() => setFocus(false)}>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            Consignment
          </Text>
          <Text>To search the consignment in the system</Text>
        </Box>
        <Spacer />
        <Box>
          <AddressContextStore.Provider>
            <CreateConsignment fetchData={fetchData} />
          </AddressContextStore.Provider>
        </Box>
      </Flex>
      <SearchFilter
        focus={focus}
        setFocus={setFocus}
        setSearchParams={setSearchParams}
        gotoPage={gotoPage}
      />
      <Box
        onClick={() => setFocus(false)}
        style={{ marginTop: '-20px', paddingTop: '20px' }}
      >
        <ConsignmentTable
          isLoading={isLoading}
          fetchData={fetchData}
          data={data}
          state={state}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          headerGroups={headerGroups}
          page={page}
          pageCount={pageCount}
          totalCount={pageTotal}
          prepareRow={prepareRow}
          selectedFlatRows={selectedFlatRows}
        />
      </Box>
    </Box>
  );
}

export default Consignment;
