import {
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import React from "react";

function ProofOfDeliveryModal(props) {
  const {
    ReceiverContact,
    ReceiverCompany,
    ReceiverName,
    ReceiverNRIC,
    ReceiverSignature,
  } = props.record;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text display="inline">Item received by 3rd party (</Text>
      <Text
        height="100%"
        onClick={onOpen}
        size="lg"
        variant="solid"
        color="#6e26c7"
        fontWeight="bolder"
        as="u"
        cursor="pointer"
      >
        Proof of Delivery
      </Text>
      <Text display="inline">)</Text>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent minW="45em" p={30}>
          <ModalHeader textAlign="center" fontSize="2xl">
            3rd Party Receiver Information
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Heading size="md" color="gray.400" d="inline" ml={6}>
              RECEIVER SIGNATURE
            </Heading>
            <Center mb={10}>
              <Image backgroundColor="gray.200" src={ReceiverSignature} w="460px" h="200px"/>
            </Center>
            <Grid
              h="100px"
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(2, 1fr)"
              gap={5}
              mb={10}
            >
              <GridItem colSpan={1}>
                <Heading size="sm" color="gray.400">
                  RECEIVER NAME
                </Heading>
                <Text fontWeight="bold">{ReceiverName}</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" color="gray.400">
                  RECEIVER CONTACT NO
                </Heading>
                <Text fontWeight="bold">{ReceiverContact}</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" color="gray.400">
                  RECEIVER NRIC
                </Heading>
                <Text fontWeight="bold">{ReceiverNRIC}</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" color="gray.400">
                  RECEIVER COMPANY NAME
                </Heading>
                <Text fontWeight="bold">{ReceiverCompany}</Text>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProofOfDeliveryModal;
