import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import axios from 'axios';

function ScanModal(props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [barcodes, setBarcodes] = useState('');
  const [trackNo, setTrackNo] = useState(null);
  const [barcodeScanned, setBarcodeScanned] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const checkBarcode = (trackingNo) => {
    setErrorMsg(null);
    let activityType = '';
    switch (props.purpose) {
      case '1': {
        activityType = 'Arrived';
        break;
      }
      case '2': {
        activityType = 'Delivery';
        break;
      }
      case '3': {
        activityType = 'Transit';
        break;
      }
      case '4': {
        activityType = 'Delivered';
        break;
      }
      case '5': {
        activityType = 'Received';
        break;
      }
      default: {
        break;
      }
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/consignment/check`, {
        TrackingNoList: [trackingNo],
        ActivityType: activityType,
      })
      .then((response) => {
        if (response.status === 200) {
          if (!_.isEmpty(response.data.data.error)) {
            const errorBarcodesWithMessage = _.toPairs(
              response.data.data.error
            );
            errorBarcodesWithMessage.forEach((barcode) => {
              setErrorMsg(barcode[1]);
              toast({
                title: 'Failed',
                description: barcode[1],
                status: 'error',
                isClosable: true,
                position: 'bottom-left',
              });
            });
          } else {
            if (barcodeScanned.includes(trackingNo)) {
              toast({
                title: 'Failed',
                description: 'Consignment is already scanned',
                status: 'error',
                isClosable: true,
                position: 'bottom-left',
              });
            } else {
              setBarcodeScanned([...barcodeScanned, trackingNo]);
              toast({
                title: 'Success',
                description: 'Consignment is valid',
                status: 'success',
                isClosable: true,
                position: 'bottom-left',
              });
              setTrackNo('');
            }
          }
        } else {
          throw response;
        }
      });
  };

  useEffect(() => {
    const inputArray = barcodes.split('\n');
    if (inputArray[inputArray.length - 1] === '' && inputArray[0] !== '') {
      const currentInput = inputArray[inputArray.length - 2];
      if (barcodeScanned.length > 0 && barcodeScanned.includes(currentInput)) {
        setIsExist(true);
      } else {
        if (!!barcodes) checkBarcode(currentInput.replace('\n', ''));
        setIsExist(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodes]);

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setBarcodes(inputValue);
  };

  let onOpenCustom = () => {
    setBarcodes('');
    setTrackNo(null);
    setBarcodeScanned([]);
    setIsExist(false);
    onOpen();
  };

  let onCloseCustom = () => {
    const filteredBarcodes = barcodeScanned.map((barcode) => {
      return {
        value: barcode,
        status: 'Checked',
      };
    });
    props.setBarcodes(filteredBarcodes);
    setErrorMsg(null);
    onClose();
  };

  const renderTable = () => (
    <Box overflowX={{ base: 'scroll', md: 'visible' }}>
      <Table variant="simple" style={{ minWidth: '400' }}>
        <Thead>
          <Tr>
            <Th p={2}>NO.</Th>
            <Th p={2}>TRACKING NO.</Th>
            <Th p={2}>STATUS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {barcodeScanned.map((item, index) => (
            <Tr key={`barcode-scan${index}`} p={2}>
              <Td p={2}>{index + 1}</Td>
              <Td p={2}>{item}</Td>
              <Td p={2} minW={{ base: '75px', md: '50px' }}>
                <Text
                  d="inline"
                  border="1px solid green"
                  borderRadius="20px"
                  color="green.400"
                  py={1}
                  px={3}
                  fontWeight="bold"
                  textAlign="center"
                >
                  CHECKED
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );

  return (
    <>
      <Button
        onClick={onOpenCustom}
        backgroundColor="#6e26c7"
        borderRadius={10}
        colorScheme="purple"
        size="md"
        disabled={props.disabled}
      >
        <Text mr={3}>SCAN PARCEL</Text>
        <FontAwesomeIcon icon={faBarcode} />
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setErrorMsg(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody fontFamily="Arial">
            <Center>
              <Image
                src={`${window.location.origin}/scan.gif`}
                height="300px"
              />
            </Center>
            {isExist && (
              <Center mr={3} color="red">
                Barcode already scanned
              </Center>
            )}
            <Center fontSize="xl">Align consignment tracking</Center>
            <Center fontSize="xl">barcode within frame to scan</Center>

            <FormControl mt={3}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <InputGroup width={'70%'}>
                  <Input
                    autoFocus={true}
                    foc
                    value={trackNo}
                    marginBottom={19}
                    onChangeCapture={(e) => {
                      if (errorMsg) {
                        setErrorMsg(null);
                      }
                      setTrackNo(e?.target?.value.toUpperCase());
                    }}
                    max={8}
                    id="prontoInput"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (trackNo) {
                          checkBarcode(trackNo);
                        } else {
                          setErrorMsg('Please input tracking no');
                        }
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button
                      h="2.5rem"
                      size="xl"
                      backgroundColor="#6e26c7"
                      colorScheme="purple"
                      padding={2}
                      marginRight={5}
                      onClick={() => {
                        if (trackNo) {
                          checkBarcode(trackNo);
                        } else {
                          setErrorMsg('Please input tracking no');
                        }
                      }}
                      autoFocus={false}
                      disabled={trackNo?.length < 1 ? true : false}
                    >
                      ENTER
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </div>
              <Flex justifyContent={'center'} mt={'-0.7rem'}>
                <span
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    display: errorMsg ? 'block' : 'none',
                  }}
                >
                  {errorMsg}
                </span>
              </Flex>
            </FormControl>
            {barcodeScanned.length > 0 && renderTable()}
            <Center>
              <Button
                onClick={onCloseCustom}
                borderRadius={10}
                colorScheme="blue"
                my={4}
                px={8}
                size="md"
                autoFocus={false}
                disabled={barcodeScanned.length === 0 && !trackNo}
              >
                COMPLETE
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ScanModal;
