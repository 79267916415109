import { Box, Text, Flex, Spacer } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useTable, useSortBy } from "react-table";
import SearchFilter from "./SearchFilter";
import UserRoleTable from "./UserRoleTable";

function UserRole(props) {
  document.title = "Tomei Logistic - User Role";

  const [users, setUsers] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  async function getUsers() {
    const params = new URLSearchParams(searchFilters);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users`, {
        params,
      })
      .then((response) => {
        setUsers(
          response.data.data.map((user) => {
            return {
              id: user.id,
              staff_id: user.staff_id,
              full_name: user.full_name,
              email: user.email,
              departmentAndBuilding: `${user.department} / ${user.building}`,
              position: user.job_title,
            };
          })
        );
      });
  }

  const DATA_PER_PAGE = 10;
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Staff Id",
        accessor: "staff_id",
      },
      {
        Header: "Name",
        accessor: "full_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Department / Building",
        accessor: "departmentAndBuilding",
      },
      {
        Header: "Position",
        accessor: "position",
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
      },
    ],
    []
  );

  const data = useMemo(() => users, [users]);

  const { state, gotoPage, headerGroups, page, pageCount, prepareRow } =
    useTable(
      {
        columns,
        data,
        autoResetPage: true,
        initialState: {
          hiddenColumns: ["id"],
          pageSize: DATA_PER_PAGE,
          pageIndex: currentPage,
        },
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName];
            const rowTwoColumn = row2.values[columnName];
            if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
                ? 1
                : -1;
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          },
        },
      },
      useSortBy,
      usePagination
    );

  if (pageCount - 1 < currentPage && pageCount !== 0) {
    setCurrentPage(0);
  }

  const handleChange = (event, value) => {
    gotoPage(value - 1);
  };

  useEffect(() => {
    getUsers();
  }, [searchFilters]);

  return (
    <Box
      ml={props.isMenuClosed ? "20px" : "270px"}
      p={6}
      flex="1"
      textAlign="left"
      minH="816px"
    >
      <Flex>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb={2}>
            User Role
          </Text>
          <Text>
            To search for a user and assign what access rights each user has
          </Text>
        </Box>
      </Flex>
      <SearchFilter getUsers={getUsers} setSearchFilters={setSearchFilters} />
      <UserRoleTable
        getUsers={getUsers}
        data={data}
        dataPerPage={DATA_PER_PAGE}
        state={state}
        headerGroups={headerGroups}
        page={page}
        pageCount={pageCount}
        prepareRow={prepareRow}
        handleChange={handleChange}
      />
    </Box>
  );
}

export default UserRole;
