import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spacer,
  Text,
  Textarea,
  Grid,
  GridItem,
  Heading,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import Barcode from "react-barcode";
import { getBorder } from "./Helper";
import moment from "moment";

function MobileViewConsignment(props) {
  const { trackingNo } = useParams();
  const toast = useToast();
  const [activityHistory, setActivityHistory] = useState([]);
  const [details, setDetails] = useState({
    ConsignmentID: "",
    TrackingNo: "",
    CreatedDate: "",
    Status: "",
    StatusDescription: "",
    SenderName: "",
    SenderContact: "",
    SenderAddressCode: "",
    SenderDepartmentCode: "",
    SenderDepartmentOrOutlet: "",
    SenderStreet: "",
    SenderCity: "",
    SenderState: "",
    SenderPostcode: "",
    SenderCountry: "",
    ReceiverName: "",
    ReceiverContact: "",
    ReceiverAddressCode: "",
    ReceiverDepartmentCode: "",
    ReceiverDepartmentOrOutlet: "",
    ReceiverStreet: "",
    ReceiverCity: "",
    ReceiverState: "",
    ReceiverPostcode: "",
    ReceiverCountry: "",
    ReceiverNRIC: "",
    ReceiverCompany: "",
    ReceiverSignature: "",
    PerformedByFullName: "",
    DepartmentRefNo: "",
    ProntoTransferNo: "",
    ParcelCount: 1,
    Notes: "",
    IsCancelled: false,
    SenderID: "",
    PerformByStaffID: "",
    ActivityDatetime: "",
  });
  details.StatusDescription = details.StatusDescription.toLocaleLowerCase();

  let getStatusMessage = (status) => {
    const result = activityHistory.filter(
      (activity) => activity.ActivityType.toLowerCase() === status
    )[0];
    if (result) {
      if (status === "created") {
        return `Consignment created by ${result.PerformedByFullName} and pending to print shipping label`;
      } else if (status === "cancelled") {
        return `Consignment cancelled on a ${moment(
          result.ActivityDatetime
        ).format("YYYY-MM-DD hh:mm:ss A")} by ${result.PerformedByFullName}`;
      } else if (status === "printed") {
        return `Consignment shipping label printed by ${result.PerformedByFullName} and pending for delivery`;
      } else if (status === "arrived") {
        return `The item is currently being sorted by the logistic team at the sorting center`;
      } else if (status === "delivery") {
        return "The item has been picked up and out for delivery";
      } else if (status === "transit") {
        return "The item has been collected from sender and will transfer to Sorting Center";
      } else if (status === "delivered") {
        return `The item has been delivered and received by ${result.PerformedByFullName} from ${details.ReceiverDepartmentOrOutlet} / ${details.ReceiverAddressCode}.`;
      } else if (status === "received") {
        return `The item has been delivered by ${result.PerformedByFullName} and received by ${result.ReceiverName} from ${result.ReceiverCompany}.`;
      }
    }
  };

  useEffect(() => {
    async function getConsignment() {
      const params = new URLSearchParams([["TrackingNo", trackingNo]]);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/consignment/find-one`, {
          params,
        })
        .then(async (response) => {
          setDetails(response.data.data);
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/consignment/history/find-one`,
              {
                TrackingNo: trackingNo,
              }
            )
            .then((response) => {
              setActivityHistory(response.data.data);
            });
        })
        .catch((error) => {
          toast({
            title: "Failed",
            description: `An error occurred while getting the consingment detail.`,
            duration: null,
            isClosable: true,
            position: "bottom-left",
            status: "error",
          });
        });
    }

    getConsignment();
  }, [toast, trackingNo]);

  return (
    <Box margin="8px">
      <Flex direction="column">
        <Box>
          <Heading size="md">TRACKING NUMBER</Heading>
          <Barcode value={details.TrackingNo} width={1.5} />
        </Box>
        <Box mt="16px">
          <Heading size="md" mb={2}>
            STATUS
          </Heading>
          <Text
            px="16px"
            display="inline-block"
            borderColor={getBorder(details.StatusDescription)}
            borderRadius="25px"
            borderStyle="solid"
            borderWidth="3px"
            color={getBorder(details.StatusDescription)}
            fontWeight="bold"
            textAlign="center"
            textTransform="capitalize"
          >
            {details.StatusDescription}
          </Text>
        </Box>
        <Box mt="24px">
          <Heading size="md" mb={2}>
            CREATED DATE
          </Heading>
          <Text>
            {new Date(details.CreatedDate).toLocaleDateString("en-GB")}
          </Text>
        </Box>
        <Box mt="24px" pl={5} background="lightgrey" textAlign="center">
          <Text>{getStatusMessage(details.Status.toLowerCase())}</Text>
        </Box>
      </Flex>

      <Heading mt="24px" pl={0} pb={3} size="md">
        SENDER DETAILS
      </Heading>
      <Grid
        minH="fit-content"
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap={1}
        borderRadius="13px"
        border="2px solid lightgrey"
        p="8px"
        mb={5}
      >
        <GridItem colSpan={1}>
          <Heading size="md">NAME</Heading>
          <Text fontSize="xl">{details.SenderName}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CONTACT / EXT NO. </Heading>
          <Text fontSize="xl">{details.SenderContact}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CODE / DEPARTMENT / OUTLET</Heading>
          <Text fontSize="xl">
            {`${details.SenderAddressCode ?? "-"} / ${
              details.SenderDepartmentCode ?? "-"
            } / ${details.SenderDepartmentOrOutlet ?? "-"}`}
          </Text>
        </GridItem>
        <GridItem rowSpan={2} colSpan={2}>
          <Heading size="md">ADDRESS</Heading>
          <Text fontSize="xl">{details.SenderStreet}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CITY</Heading>
          <Text fontSize="xl">{details.SenderCity}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">STATE / COUNTRY</Heading>
          <Text fontSize="xl">{`${details.SenderState} / ${details.SenderCountry}`}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">POSTCODE</Heading>
          <Text>{details.SenderPostcode}</Text>
        </GridItem>
      </Grid>
      <Heading pl={0} pb={3} size="md">
        RECEIVER DETAILS
      </Heading>
      <Grid
        minH="fit-content"
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap={1}
        borderRadius="13px"
        border="2px solid lightgrey"
        p="8px"
        mb={5}
      >
        <GridItem colSpan={1}>
          <Heading size="md">NAME</Heading>
          <Text fontSize="xl">{details.ReceiverName}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CONTACT / EXT NO. </Heading>
          <Text fontSize="xl">{details.ReceiverContact}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CODE / DEPARTMENT / OUTLET</Heading>
          <Text fontSize="xl">
            {`${details.ReceiverAddressCode ?? "-"} / ${
              details.ReceiverDepartmentCode ?? "-"
            } / ${details.ReceiverDepartmentOrOutlet ?? "-"}`}
          </Text>
        </GridItem>
        <GridItem rowSpan={2} colSpan={2}>
          <Heading size="md">ADDRESS</Heading>
          <Text fontSize="xl">{details.ReceiverStreet}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">CITY</Heading>
          <Text fontSize="xl">{details.ReceiverCity}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">STATE / COUNTRY</Heading>
          <Text fontSize="xl">{`${details.ReceiverState} / ${details.ReceiverCountry}`}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Heading size="md">POSTCODE</Heading>
          <Text>{details.ReceiverPostcode}</Text>
        </GridItem>
      </Grid>
      <Formik
        initialValues={{
          Notes: details.Notes,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {}}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <Field name="Notes">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.Notes && form.touched.Notes}
                  d="inline-block"
                  mb="20px"
                >
                  <FormLabel htmlFor="Notes">
                    <Flex>
                      <Box>
                        <Text display="inline" fontSize="2xl" fontWeight="bold">
                          NOTES
                        </Text>
                      </Box>
                      <Spacer />
                    </Flex>
                    <Spacer />
                  </FormLabel>

                  <Textarea
                    {...field}
                    borderRadius="13px"
                    disabled
                    border="5px solid black"
                  />
                  <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default MobileViewConsignment;
