import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { VisuallyHidden } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import A8Paper from './A8Paper';
import A6Paper from './A6Paper';
import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const PageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

function PrintMultipleModal(props) {
  const { printData, type, fetchData } = props;
  const [details, setDetails] = useState([]);
  const [documents, setDocuments] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    let data = printData
      .map((e) => {
        return e.original;
      })
      .filter(
        (e) =>
          e.status === 'consignment created' ||
          e.status === 'shipping label printed'
      );
    let promises = [];
    data.forEach(async (e) => {
      const params = new URLSearchParams([['TrackingNo', e.trackingNo]]);
      promises.push(
        axios.get(
          `${process.env.REACT_APP_API_URL}/api/shipping-labels/find-one`,
          {
            params,
          }
        )
      );
    });

    let detailsData = [];
    Promise.all(promises).then(function (results) {
      results.forEach((response) => {
        detailsData.push(response.data);
      });
      let documents = renderPrint(detailsData);
      setDetails(detailsData);
      setDocuments(documents);
    });
  }, [printData]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderPrint = (consignment) => {
    let documents = [];
    consignment.forEach((detail, index) => {
      let template = (
        <div key={index}>
          {type === 'A8' && <A8Paper details={detail.data} />}
          {type === 'A6' && <A6Paper details={detail.data} />}
          <PageBreakWrapper>&nbsp;</PageBreakWrapper>
        </div>
      );
      documents.push(template);
    });
    return documents;
  };

  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor={'blue.500'}
        color="white"
        mt={5}
        ml={2}
        px={5}
        size="sm"
        borderRadius="14px"
      >
        <FontAwesomeIcon icon={faPrint} />
        <Text ml={2}>PRINT MULTIPLE {type}</Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={5} minW="500px">
          <ModalCloseButton />
          <ModalBody id="printer">
            {documents.length > 0 && <div>{documents}</div>}
            <VisuallyHidden>
              {type === 'A8' && (
                <Box w="75mm" height="40mm" ref={componentRef} mt={0} ml={1.5}>
                  {documents.length > 0 && <div>{documents}</div>}
                </Box>
              )}

              {type === 'A6' && (
                <Box
                  w="100mm"
                  height="146mm"
                  ref={componentRef}
                  mt={0}
                  ml={1.5}
                >
                  {documents.length > 0 && <div>{documents}</div>}
                </Box>
              )}
            </VisuallyHidden>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              onAfterPrint={async () => {
                let lists = printData
                  .map((e) => {
                    return e.original;
                  })
                  .filter((e) => e.status == 'consignment created')
                  .map((e) => e.trackingNo);

                if (lists.length > 0) {
                  await axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/api/consignment/update/printed`,
                      {
                        TrackingNoList: lists,
                      }
                    )
                    .then((response) => {
                      fetchData();
                    })
                    .catch((response) => {});
                }
              }}
              trigger={() => (
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPrint} />
                  <Text fontSize="lg" style={{ display: 'inline' }} ml={3}>
                    PRINT
                  </Text>
                </Button>
              )}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrintMultipleModal;
