// import { Box, Text, Flex, Spacer } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import ProofOfDeliveryModal from "./ProofOfDeliveryModal";
import moment from "moment";
import UserStore from "../Store/UserStore";
import { checkHasRoles } from "../utils";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrintActivityHistoryModal from "./PrintActivityHistoryModal";

function ConsignmentActivityHistory(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activityHistory, setActivityHistory] = useState([]);
  const userRoles = UserStore.useStoreState((state) => state.roles);

  useEffect(() => {
    async function getConsignment() {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/consignment/history/find-one`,
          {
            TrackingNo: props.trackingNo,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setActivityHistory(response.data.data);
          }
        });
    }
    if (isOpen) {
      getConsignment();
    }
  }, [isOpen]);

  function renderValues() {
    if (activityHistory.length > 0) {
      return activityHistory.map((record, index) => {
        const {
          ActivityDatetime,
          ActivityDescription,
          PerformedByBuildingCode,
          PerformedByBuildingName,
          PerformedByContact,
          PerformedByDeptCode,
          PerformedByDeptName,
          PerformedByFullName,
          PerformedByStaffID,
          PerformedByPreferredName,
        } = record;
        return (
          <Tr>
            <Td p={1.5}>
              {moment
                .utc(ActivityDatetime)
                .local()
                .format("DD/MM/YYYY hh:mm:ss A")}
            </Td>
            <Td p={1.5}>
              {ActivityDescription.toLowerCase() ===
              "item received by 3rd party" ? (
                <ProofOfDeliveryModal record={record} />
              ) : ActivityDescription.toLowerCase() === "out for delivery" ? (
                <>
                  <Text d="inline">{ActivityDescription} (Phone </Text>
                  <Text fontWeight="bold" d="inline">
                    {PerformedByContact}
                  </Text>
                  <Text d="inline">)</Text>
                </>
              ) : (
                <Text textTransform="capitalize">{ActivityDescription}</Text>
              )}
            </Td>
            <Td p={1.5}>
              <Tooltip
                label={`${PerformedByFullName} (${PerformedByStaffID}) ${PerformedByContact}`}
                fontSize="lg"
              >
                <Text as="u" color="#6e26c7" fontWeight="bold">
                  {PerformedByPreferredName}
                </Text>
              </Tooltip>
            </Td>
            <Td p={1.5}>
              {PerformedByDeptCode ? (
                <>
                  <Tooltip label={PerformedByDeptName} fontSize="lg">
                    <Text d="inline">{PerformedByDeptCode}</Text>
                  </Tooltip>
                </>
              ) : null}
              {" / "}
              {PerformedByBuildingCode ? (
                <>
                  <Tooltip label={PerformedByBuildingName} fontSize="lg">
                    <Text d="inline">{PerformedByBuildingCode}</Text>
                  </Tooltip>
                </>
              ) : null}
            </Td>
          </Tr>
        );
      });
    }
    return null;
  }
  return (
    <>
      <Button
        disabled={!checkHasRoles(userRoles, ["activity history"])}
        onClick={onOpen}
        color="#6e26c7"
        background="clear"
        mt={2}
      >
        <Text mr={3} fontSize="xl">
          Activity History
        </Text>
        <ArrowRightIcon />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="1000px" p={5}>
          <ModalHeader textAlign="center">ACTIVITY HISTORY</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th fontSize="lg" fontWeight="bold" p={1.5}>
                    Date
                  </Th>
                  <Th fontSize="lg" fontWeight="bold" p={1.5}>
                    Activity
                  </Th>
                  <Th fontSize="lg" fontWeight="bold" p={1.5}>
                    Performed By
                  </Th>
                  <Th fontSize="lg" fontWeight="bold" p={1.5}>
                    Department Code / Building Code
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">{renderValues()}</Tbody>
            </Table>
          </ModalBody>

          <Flex px={6} my={3} pr={12} justifyContent={"space-between"} mt={8}>
            <Button
              size="md"
              background="clear"
              color="#6e26c7"
              onClick={onClose}
            >
              <ArrowLeftIcon />
              <Text ml={2}>Back to View</Text>
            </Button>

            <PrintActivityHistoryModal
              trackingNo={props.trackingNo}
              details={props.details}
              activityHistory={activityHistory}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConsignmentActivityHistory;
