import { Box, Image, Text, Center, Grid, GridItem } from '@chakra-ui/react';
import { VisuallyHidden } from '@chakra-ui/react';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';

function A8Paper(props) {
  const {
    ReceiverAddressCode,
    ReceiverCity,
    ReceiverContact,
    ReceiverCountry,
    ReceiverDepartmentOrOutlet,
    ReceiverName,
    ReceiverPostcode,
    ReceiverState,
    ReceiverStreet,
    TrackingNo,
    DepartmentRefNo,
    ProntoTransferNo,
    ParcelCount,
  } = props.details;

  let referenceNo = '-';
  if (ProntoTransferNo && ProntoTransferNo.length > 0) {
    referenceNo = ProntoTransferNo;
  } else if (DepartmentRefNo && DepartmentRefNo.length > 0) {
    referenceNo = DepartmentRefNo;
  }

  return (
    <>
      <VisuallyHidden>
        <Box
          border="1px solid black"
          w="75mm"
          height="47mm"
          ref={props.componentRef}
          // mt={1.5}
          ml={1.5}
        >
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              {ParcelCount > 0 && (
                <Text
                  float="left"
                  textAlign="right"
                  py={1}
                  px={2}
                  fontSize="xs"
                  fontWeight="bold"
                  align="right"
                  border="1px solid black"
                  borderRadius="50%"
                  m={0}
                  d="inline"
                >
                  {ParcelCount}
                </Text>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <Center mb={1.5} mt={0.5}>
                <Image
                  src={`${window.location.origin}/logo-black.png`}
                  w="70%"
                  h="70%"
                  alt="Tomei"
                />
              </Center>
            </GridItem>
            <GridItem colSpan={1} textAlign="center">
              {ReceiverAddressCode && (
                <Text
                  float="right"
                  textAlign="right"
                  py={0.5}
                  px={2}
                  fontSize="xs"
                  fontWeight="bold"
                  align="right"
                  border="1px solid black"
                  borderRadius="8px"
                  m={0}
                  d="inline"
                >
                  {ReceiverAddressCode}
                </Text>
              )}
            </GridItem>
          </Grid>
          <Center>
            <Barcode
              value={TrackingNo}
              margin={1}
              width={1.2}
              height={16}
              fontSize={8}
              display="block"
            />
          </Center>
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(8, 1fr)"
            borderTop="1px solid black"
            mt={2}
          >
            <GridItem
              borderRight="1px solid black"
              colSpan={5}
              fontSize="9px"
              ml={2}
              minH="50px"
            >
              <Text fontSize="8px">Ship to:</Text>
              <Box>
                <Text display="inline" fontWeight="bold">
                  {ReceiverName}
                </Text>
                <Text display="inline"> ({ReceiverContact})</Text>
              </Box>
              {ReceiverDepartmentOrOutlet && (
                <Text>{ReceiverDepartmentOrOutlet}</Text>
              )}
              <Text>{`${ReceiverStreet}, ${ReceiverPostcode}, ${ReceiverCity}, ${ReceiverState}, ${ReceiverCountry}`}</Text>
            </GridItem>
            <GridItem colSpan={3} py={1} fontSize="xs">
              <Center>
                <Text>Ref No.:</Text>
              </Center>
              <Center>
                <Text>{referenceNo}</Text>
              </Center>
              <Center>
                <QRCode value={`${props.details.TrackingNo}`} size={63} />
              </Center>
            </GridItem>
          </Grid>
        </Box>
      </VisuallyHidden>
      <Box
        border="1px solid black"
        w="80mm"
        height="52mm"
        mt={0}
        ml="auto"
        mr="auto"
      >
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            {ParcelCount > 0 && (
              <Text
                float="left"
                textAlign="right"
                py={1}
                px={2}
                fontSize="xs"
                fontWeight="bold"
                align="right"
                border="1px solid black"
                borderRadius="50%"
                m={0}
                d="inline"
              >
                {ParcelCount}
              </Text>
            )}
          </GridItem>
          <GridItem colSpan={1}>
            <Center mb={1.5} mt={0.5}>
              <Image
                src={`${window.location.origin}/logo-black.png`}
                w="70%"
                h="70%"
                alt="Tomei"
              />
            </Center>
          </GridItem>
          <GridItem colSpan={1} textAlign="center">
            {ReceiverAddressCode && (
              <Text
                float="right"
                textAlign="right"
                py={0.5}
                px={2}
                fontSize="xs"
                fontWeight="bold"
                align="right"
                border="1px solid black"
                borderRadius="8px"
                m={0}
                d="inline"
              >
                {ReceiverAddressCode}
              </Text>
            )}
          </GridItem>
        </Grid>
        <Center>
          <Barcode
            value={props.details.TrackingNo}
            margin={1}
            width={1.5}
            height={20}
            fontSize={10}
            display="block"
          />
        </Center>
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(9, 1fr)"
          borderTop="1px solid black"
          mt={2}
          fontSize="10.5px"
        >
          <GridItem borderRight="1px solid black" colSpan={6} ml={2} h="123px">
            <Text fontSize="10px">Ship to:</Text>
            <Box>
              <Text display="inline" fontWeight="bold">
                {ReceiverName}
              </Text>
              <Text display="inline"> ({ReceiverContact})</Text>
            </Box>
            {ReceiverDepartmentOrOutlet && (
              <Text>{ReceiverDepartmentOrOutlet}</Text>
            )}
            <Text>{`${ReceiverStreet}, ${ReceiverPostcode}, ${ReceiverCity}, ${ReceiverState}, ${ReceiverCountry}`}</Text>
          </GridItem>
          <GridItem colSpan={3} py={1} fontSize="xs">
            <Center>
              <Text>Ref No.:</Text>
            </Center>
            <Center>
              <Text>{referenceNo}</Text>
            </Center>
            <Center>
              <QRCode value={`${props.details.TrackingNo}`} size={73} />
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default A8Paper;
