import { action, createContextStore, thunk, debug } from 'easy-peasy';
import moment from 'moment';

const ConsignmentListStore = createContextStore({
  consignmentList: {
    data: [],
  },
  searchFilters: {
    trackingNo: '',
    createdDateRange: [
      moment().subtract(14, 'd').format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
    ],
    department: '',
    outlet: '',
    createdStatus: '',
    historyDateRange: [
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
    ],
    historyStatus: '',
  },
  searchParams: {
    ActivityDateEnd: moment(new Date()).format('YYYY-MM-DD'),
    ActivityDateStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    CreatedDateEnd: moment(new Date()).format('YYYY-MM-DD'),
    CreatedDateStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  },
  saveConsignmentList: action((state, payload) => {
    state.consignmentList = payload;
  }),
  saveSearchFilter: action((state, payload) => {
    state.searchFilters = payload;
  }),
  setSearchParams: action((state, payload) => {
    state.searchParams = payload;
  }),
});

export default ConsignmentListStore;
