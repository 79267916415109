import { Box, Select, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getOutlets } from '../AddressBook/Helper/RetreiveDataHelper';
import AddressContextStore from '../Store/AddressContextStore';
import UserStore from '../Store/UserStore';

function OutletDropdown(props) {
  const userBuilding = UserStore.useStoreState((state) => state.building);
  const [outlets, setOutlets] = useState([]);
  const [outletOptions, setOutletOptions] = useState([]);
  const {
    setSelectedDepartment,
    selectedOutlet,
    setSelectedOutlet,
    loadId,
    isPronto,
    isLoading,
    setIsLoading,
  } = props;

  useEffect(async () => {
    async function fetchData() {
      if (outlets.length === 0) {
        setIsLoading(true);
        const { renderedValues, mappedResponse } = await getOutlets(false, [
          ['status', 'active'],
        ]);
        setOutletOptions(renderedValues);
        setOutlets(mappedResponse);
        setIsLoading(false);
      }

      if (
        isPronto &&
        loadId === 2 &&
        selectedOutlet !== 'HQ' &&
        selectedOutlet !== ''
      ) {
        const outlet = retrieveSelectedOutlet(selectedOutlet);
        fillAddress({ ...outlet, loadId, autofilled: true });
      }
    }
    await fetchData();
  }, [outlets.length]);

  function retrieveSelectedOutlet(code) {
    return outlets.find((outlet) => outlet.addressCode === code);
  }
  const fillAddress = AddressContextStore.useStoreActions(
    (actions) => actions.fillAddress
  );

  useEffect(async () => {
    if (!isPronto) {
      setSelectedOutlet(userBuilding.code === 'HQ' ? '' : userBuilding.code);
    }

    if (userBuilding.code !== 'HQ') {
      const outlet = await retrieveSelectedOutlet(userBuilding.code);
      fillAddress({ ...outlet, loadId });
    }

    if (
      isPronto &&
      loadId === 2 &&
      selectedOutlet !== 'HQ' &&
      selectedOutlet !== ''
    ) {
      const outlet = await retrieveSelectedOutlet(selectedOutlet);
      fillAddress({ ...outlet, loadId });
    }
  }, [isLoading]);

  return isLoading ? (
    <Box fontSize="lg" flex="1" textAlign="center">
      <Spinner size="lg" />
    </Box>
  ) : (
    <Select
      onChange={(e) => {
        if (e.target.value.length > 0 && e.target.value !== 'all') {
          setSelectedOutlet(e.target.value);
          const outlet = retrieveSelectedOutlet(e.target.value);
          fillAddress({ ...outlet, loadId, autofilled: true });
        } else {
          setSelectedOutlet('');
          fillAddress({
            address: '',
            city: '',
            state: '',
            postcode: '',
            country: 'Malaysia',
            autofilled: false,
          });
        }
        setSelectedDepartment('');
      }}
      value={selectedOutlet}
    >
      <option value="" disabled hidden>
        Outlet/Warehouse
      </option>
      <option value="all">All</option>
      <optgroup label="Outlet">
        {outletOptions.filter((item) => item.props?.buildingtype === 'Outlet')}
      </optgroup>
      <optgroup label="Warehouse">
        {outletOptions.filter(
          (item) => item.props?.buildingtype === 'Warehouse'
        )}
      </optgroup>
    </Select>
  );
}

export default OutletDropdown;
