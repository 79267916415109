import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  Select,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import React from 'react';

const typeOptions = [
  'All',
  'HQ',
  'Branch',
  'Outlet',
  'Customer',
  'Mall',
  'Others',
];

const SearchFilter = ({ onSubmit }) => {
  return (
    <Accordion
      allowToggle
      bg="#fafafa"
      p={4}
      m={5}
      borderRadius="25px"
      color="grey"
    >
      <AccordionItem border="none">
        <AccordionButton p={0}>
          <Box flex="1" textAlign="left">
            SEARCH FILTERS
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Formik
            initialValues={{
              type: 'All',
              status: '1',
            }}
            onSubmit={async (values, actions) => {
              await onSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns="repeat(3, 1fr)"
                  gap={3}
                  maxW="fit-content"
                >
                  <Box>
                    <Field name="type">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.type && form.touched.type}
                        >
                          <Select {...field} placeholder="Type">
                            {typeOptions.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.type}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="status">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.status && form.touched.status}
                        >
                          <Select {...field} placeholder="Status">
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.status}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Button
                      colorScheme="gray"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      <Search2Icon />
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default SearchFilter;
